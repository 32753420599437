import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import Icon from 'components/lowLevel/Icon';
import authService from '../../../../services/Auth';
import handleLoginError from '../../handleLoginError';
import handleLoginSuccess from '../../handleLoginSuccess';
import { useFormatMessage } from '../../../../locale';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID?.toString();
if (!clientId) console.error('Не удалось считать GOOGLE_CLIENT_ID из .env');

interface SignInWithGoogleButtonProps {
  setErrorText: Dispatch<SetStateAction<string | undefined>>;
}

const SignInWithGoogleButton = ({
  setErrorText,
}: SignInWithGoogleButtonProps) => {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const handleSuccessLogin = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (response.code) {
      setErrorText(formatMessage('errorLoginGoogle'));
      console.log(response, 'Ошибка при авторизации');
    }

    if ('tokenId' in response) {
      authService
        .loginWithGoogle(response.tokenId)
        .then(handleLoginSuccess(dispatch))
        .catch(handleLoginError({ formatMessage, setErrorText }));
    } else {
      console.log('Что-то с Google', response);
    }
  };

  const handleFailureLogin = (e: any) => {
    if (e.error === 'popup_closed_by_user') {
      setErrorText(formatMessage('cancelLoginGoogle'));
    }
  };

  if (!clientId) return null;

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText={formatMessage('loginWithGoogle')}
      onSuccess={handleSuccessLogin}
      onFailure={handleFailureLogin}
      cookiePolicy="single_host_origin"
      render={({ onClick }) => (
        <Button
          onClick={onClick}
          size="large"
          type="button"
          variant="outlined"
          fullWidth
          startIcon={<Icon definition="local:Google" />}
        >
          {formatMessage('loginWithGoogle')}
        </Button>
      )}
    />
  );
};

export default SignInWithGoogleButton;
