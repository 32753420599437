import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      padding: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 0),
      },
    },
    chip: {
      padding: theme.spacing(1, 1, 1, 0),
      marginRight: theme.spacing(1),
    },
  })
);
