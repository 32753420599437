import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginBottom: -1,
  },
});

let Record: any = (props: any) => {
  const classes = useStyles();

  return (
    <SvgIcon
      {...props}
      classes={{
        root: classes.root,
      }}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="3.5" />
      <circle cx="6" cy="6" r="5.5" fill="none" />
    </SvgIcon>
  );
};

Record.muiName = 'SvgIcon';

Record = React.memo(Record);
Record.displayName = 'Record';

export default Record;
