import { createStyles, makeStyles } from '@material-ui/core';
import background from './assets/bg.png';

const HEADER_HEIGHT = '86px';
const CONTENT_CONTAINER_HEIGHT = `calc(100vh - ${HEADER_HEIGHT})`;

export default makeStyles((theme) =>
  createStyles({
    root: {
      background: `url(${background}) 50% 100% no-repeat`,
      backgroundSize: 'cover',
    },
    appBar: {
      background: 'transparent',
    },
    logo: {
      width: 240,
      height: 80,
    },
    main: {
      display: 'flex',
      padding: theme.spacing(6, 0),
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: CONTENT_CONTAINER_HEIGHT,

      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
      },
    },
  })
);
