import { useEffect, useState } from 'react';
import { Alert, RequestConfig } from '../../services/Main/types.Component';
import mainService from '../../services/Main';
import { useSelector } from '../../store';
import { SuccessOrAlertResponse } from '../../services/Main/types';
import { useFormatMessage } from '../../locale';
import { FormatMessage } from '../../locale/hooks';

export type checkEmptyStateFn<WidgetState> = (payload: WidgetState) => boolean;

const createUnknownErrorAlert = (formatMessage: FormatMessage): Alert => ({
  title: formatMessage('unableToRenderWidget'),
  body: [{ text: formatMessage('unknownError') }],
});

const createEmptyWidgetStateAlert = (formatMessage: FormatMessage): Alert => ({
  color: 'info',
  severity: 'info',
  title: formatMessage('unableToRenderWidget'),
  body: [{ text: formatMessage('noData') }],
});

function useWidgetDataQuery<WidgetState>(
  requestConfig: RequestConfig,
  checkEmptyState?: checkEmptyStateFn<WidgetState>
) {
  const formatMessage = useFormatMessage();
  const globalFilter = useSelector((s) => s.globalFilter);

  const [widgetState, setWidgetState] = useState<WidgetState>();
  const [alert, setAlert] = useState<Alert>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFistTimeFetched, setIsFistTimeFetched] = useState<boolean>(false);

  useEffect(() => {
    if (!isFetching) setIsFetching(true);

    mainService
      .makeRequest<SuccessOrAlertResponse<WidgetState>>(requestConfig, {
        globalFilter,
      })
      .then(({ isSuccess, payload }) => {
        if (isSuccess) {
          if (checkEmptyState?.(payload as WidgetState)) {
            setAlert(createEmptyWidgetStateAlert(formatMessage));
          } else {
            setWidgetState(payload as WidgetState);
            setAlert(undefined);
          }
        } else {
          setAlert(
            'body' in payload ? payload : createUnknownErrorAlert(formatMessage)
          );
        }
      })
      .finally(() => {
        setIsFetching(false);
        if (!isFistTimeFetched) setIsFistTimeFetched(true);
      });
    // eslint-disable-next-line
  }, [globalFilter]);

  return { widgetState, alert, isFetching, isFistTimeFetched };
}

export default useWidgetDataQuery;
