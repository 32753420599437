import React from 'react';
import { styled } from '@material-ui/core/styles';
import { TableCell, Checkbox } from '@material-ui/core';
import { TableSelection as TableSelectionBase } from '@devexpress/dx-react-grid';
import clsx from 'clsx';

const PREFIX = 'TableSelectCell';
export const classes = {
  cell: `${PREFIX}-cell`,
  checkbox: `${PREFIX}-checkbox`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    overflow: 'visible',
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
    textAlign: 'center',
  },
  [`& .${classes.checkbox}`]: {
    marginTop: '-1px',
    marginBottom: '-1px',
    padding: theme.spacing(1),
  },
}));

const TableSelectCell = ({
  style,
  selected,
  onToggle,
  className,
  row,
  tableRow,
  tableColumn,
  forwardedRef,
  ...restProps
}: TableSelectionBase.CellProps & any) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onToggle();
  };

  return (
    <StyledTableCell
      padding="checkbox"
      style={style}
      ref={forwardedRef}
      className={clsx(classes.cell, className)}
      {...restProps}
    >
      <Checkbox
        className={classes.checkbox}
        color="primary"
        checked={selected}
        onClick={handleClick}
      />
    </StyledTableCell>
  );
};

export default TableSelectCell;
