import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { SingleComponentPage } from 'services/Main/types.Page';
import ComponentLoader from '../../creational/ComponentLoader';
import useStyles from '../pageViews.styles';

const Single = ({ component }: SingleComponentPage) => {
  const classes = useStyles();

  return (
    <Box className={classes.contentContainer} component={Paper}>
      <ComponentLoader component={component} />
    </Box>
  );
};

export default Single;
