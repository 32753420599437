import React from 'react';
import CurrentUserTimelineElementComponent from './CurrentUserTimelineElement';
import OtherUserTimelineElementComponent from './OtherUserTimelineElement';
import SystemTimelineElementComponent from './SystemTimelineElement';
import {
  CurrentUserTimelineElement,
  OtherUserTimelineElement,
  SystemTimelineElement,
  TimelineElement as TimelineElementProps,
} from '../../../../../services/Main/types.Component';
import { UnexpectedDataError } from '../../../../../utils/errors/UnexpectedDataError';
import TimelineRowContainer from '../TimelineRowContainer';

function TimelineElement(props: TimelineElementProps) {
  const { type } = props;

  const { content, align } = (() => {
    switch (type) {
      case 'currentUser':
        return {
          content: (
            <CurrentUserTimelineElementComponent
              {...(props as CurrentUserTimelineElement)}
            />
          ),
          align: 'right',
        };
      case 'otherUser':
        return {
          content: (
            <OtherUserTimelineElementComponent
              {...(props as OtherUserTimelineElement)}
            />
          ),
          align: 'left',
        };
      case 'system':
        return {
          content: (
            <SystemTimelineElementComponent
              {...(props as SystemTimelineElement)}
            />
          ),
          align: 'left',
        };
      default:
        throw new UnexpectedDataError(
          'Не удалось определить тип TimelineElement'
        );
    }
  })();

  return (
    <TimelineRowContainer align={align} timelineElement={props}>
      {content}
    </TimelineRowContainer>
  );
}

export default TimelineElement;
