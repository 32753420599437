import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    timelineContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 400,
    },
    header: {
      marginBottom: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
  })
);
