import React, { MouseEventHandler, useState } from 'react';
import { useController } from 'react-hook-form';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import useStyles from './FilesButton.styles';
import Icon from '../../../../../lowLevel/Icon/Icon';
import DropZoneFileUpload from '../../../../../lowLevel/FileUpload/DropZoneFileUpload';
import { useFormatMessage } from '../../../../../../locale';

interface Props {
  name: string;
}

function FilesButton({ name }: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const {
    field: { value, onChange },
  } = useController({
    name,
    defaultValue: [],
  });

  const [isOpen, setOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const handleClickOpen: MouseEventHandler<HTMLDivElement | HTMLButtonElement> =
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setOpen(true);
    };

  const handleClose = () => {
    if (isPending) return;
    setOpen(false);
  };

  const handleChangePending = (pending: boolean) => {
    setIsPending(pending);
  };

  return (
    <>
      <IconButton className={classes.button} onClick={handleClickOpen}>
        <Badge
          badgeContent={value.length > 9 ? '9+' : value.length}
          color="primary"
          classes={{
            anchorOriginTopRightRectangle:
              classes.anchorOriginTopRightRectangle,
          }}
        >
          <Icon definition="mui:AttachFile" />
        </Badge>
      </IconButton>
      <Dialog fullWidth open={isOpen} onClose={handleClose}>
        <DialogTitle>{formatMessage('uploadFiles')}</DialogTitle>
        <DialogContent>
          <DropZoneFileUpload
            name={name}
            value={value}
            handleChangePending={handleChangePending}
            label={formatMessage('uploadFiles')}
            type="file"
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isPending} onClick={handleClose} color="primary">
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FilesButton;
