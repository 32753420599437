import React, { useCallback } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './SnackbarCloseButton.styles';

const SnackbarCloseButton = (snackbarKey: SnackbarKey) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleCloseSnackBar = useCallback(() => {
    closeSnackbar(snackbarKey);
  }, [closeSnackbar, snackbarKey]);

  return (
    <IconButton
      className={classes.button}
      color="inherit"
      size="small"
      onClick={handleCloseSnackBar}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default SnackbarCloseButton;
