import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import dictionaries from './dictionaries';
import { useSelector } from '../store';
import { FullLocale } from '../services/Main/types';
import { UnexpectedDataError } from '../utils/errors/UnexpectedDataError';

const LocaleProvider = ({ children }: PropsWithChildren<{}>) => {
  const locale = useSelector((state) => state.user?.locale) || 'ru_RU';

  return (
    <IntlProvider
      locale={getLocaleCode(locale)}
      messages={dictionaries[locale]}
    >
      {children}
    </IntlProvider>
  );
};

function getLocaleCode(fullLocale: FullLocale) {
  switch (fullLocale) {
    case 'ru_RU':
      return 'ru';
    case 'en_US':
      return 'en';
    default:
      throw new UnexpectedDataError('Прилетела неожиданная локализация');
  }
}

export default LocaleProvider;
