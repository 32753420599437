import { useSelector } from 'store';
import { useMediaQuery, useTheme } from '@material-ui/core';

export const useIsCompactMenuView = (): boolean => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isCompactMenuView = useSelector(
    (state) => state.meta.isCompactMenuView
  );

  // На мобилках всегда выводим полное меню.
  if (isXs) return false;

  return isCompactMenuView;
};
