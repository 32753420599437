import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  alertsContainer: { maxHeight: 120, overflowY: 'auto' },
  alert: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
}));
