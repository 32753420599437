import { useContext } from 'react';
import { TableContext } from './TableContext';

export default function useTableContext() {
  const tableContext = useContext(TableContext);

  if (!tableContext) {
    throw new Error('TableContext was not initialized!');
  }

  return tableContext;
}
