import * as signalR from '@microsoft/signalr';
import { SocketConfig } from '../Main/types.Component';
import authService from '../Auth';
import { SocketServiceBase } from './SocketService.base';

class SocketServiceSignalR extends SocketServiceBase {
  connection: signalR.HubConnection;

  constructor(config: SocketConfig) {
    super();

    const { uri, path, query } = config;
    const token = `Bearer ${authService.token}`;
    const url = new URL(path ? `${uri}${path}` : uri);
    const clientTimezoneOffset =
      new Date().getTimezoneOffset() as unknown as string;

    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        url.searchParams.set(key, value);
      });
    }

    url.searchParams.set('x-timezone-offset', clientTimezoneOffset);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url.toString(), {
        headers: {
          Authorization: token,
          'x-timezone-offset': clientTimezoneOffset,
        },
        accessTokenFactory() {
          return token;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([
        100, 500, 1000, 2000, 3000, 5000, 7500, 100000, 125000,
      ])
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.start();
  }

  invoke = <R>(method: string, ...args: any[]) => {
    return this.connection.invoke<R>(method, ...args);
  };

  close = () => {
    this.connection.stop();
  };
}

export default SocketServiceSignalR;
