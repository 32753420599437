import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value,
}) => {
  if (!value) return null;

  return (
    <Tooltip title={value} enterDelay={1000} arrow>
      <Typography variant="body2" noWrap component="span">
        {value}
      </Typography>
    </Tooltip>
  );
};

interface CutTextWithTooltipTypeProviderProps {
  for: string[];
}

const CutTextWithTooltipTypeProvider = (
  props: CutTextWithTooltipTypeProviderProps
) => <DataTypeProvider formatterComponent={Formatter} {...props} />;

export default CutTextWithTooltipTypeProvider;
