import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { DialogWithComponent } from 'services/Main/types.Component';

export type DialogWithComponentState = DialogWithComponent | null;

const open: CaseReducer<
  DialogWithComponentState,
  PayloadAction<DialogWithComponent>
> = (state, action) => action.payload;

const close: CaseReducer<DialogWithComponentState> = () => null;

export const { reducer, actions } = createSlice({
  name: 'dialogWithComponent',
  initialState: null as DialogWithComponentState,
  reducers: {
    open,
    close,
  },
});

export default reducer;
