import React, { MouseEvent, useState } from 'react';
import { IconButton, Menu } from '@material-ui/core';
import Icon from '../Icon/Icon';
import {
  FormAction,
  TableAction,
} from '../../../services/Main/types.Component';
import useStyles from './ExtraActions.styles';

interface ExtraActionsProps<T> {
  actions: T[];
  renderMenuItem: (actionProps: T, onClickMenuItem: () => void) => JSX.Element;
}

const ExtraActions = <T extends TableAction | FormAction>(
  props: ExtraActionsProps<T>
) => {
  const classes = useStyles();
  const { actions, renderMenuItem } = props;
  const container = React.useRef(null);

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const isMenuOpen = Boolean(dropdownAnchorEl);

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleIconClick} className={classes.extraIconButton}>
        <Icon definition="mui:MoreHoriz" />
      </IconButton>
      <Menu
        anchorEl={dropdownAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        container={container.current}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {actions.map((actionProps) =>
          renderMenuItem(actionProps, handleMenuClose)
        )}
      </Menu>
      <div ref={container} />
    </>
  );
};

export default ExtraActions;
