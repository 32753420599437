import { useSelector } from '../../../../store';

/**
 * Хук, который возвращает функцию форматирования чисел в соответствии с текущей
 * локалью пользователя.
 */
function useChartTickFormatter() {
  const localeFromStore = useSelector((state) => state.user?.locale) || 'ru_RU';

  // 'ru_RU' => 'ru-RU'
  const locale = localeFromStore.replace('_', '-');

  return (value: number | string): string => {
    try {
      return new Intl.NumberFormat(locale).format(Number(value)).toString();
    } catch (error) {
      console.error('Не удалось отформатировать число:', {
        error,
        value,
        locale,
      });

      return value.toString();
    }
  };
}

export default useChartTickFormatter;
