import React, { useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import { useFormatMessage } from '../../../../../../../locale';

const EntryPickerTableFooter = () => {
  const formatMessage = useFormatMessage();
  const { rows, onCancel, onEntryChoose, selectedRowsId } = useContext(
    EntryPickerTableContext
  );

  const handleEntryChoose = () => {
    onEntryChoose(selectedRowsId);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Grid container justify="flex-end" spacing={1}>
      <Grid item>
        <Button variant="outlined" color="primary" onClick={handleCancel}>
          {formatMessage('cancel')}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEntryChoose}
          disabled={rows.length === 0}
        >
          {formatMessage('select')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EntryPickerTableFooter;
