import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2, 3),
    },
    actionsContainer: {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dialogContent: {
      flex: '1 1 auto',
      overflow: 'auto',
      height: '30vh',
    },
  })
);
