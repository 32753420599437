import { RefObject, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../theme';
import { MIN_TEXT_WIDGET_WIDTH } from './GroupOfTextWidget.styles';

const useMarkupErrorDetector = (
  textWidgetContainerRef: RefObject<HTMLDivElement>
) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handler = () => {
      if (isSmDown) return;

      const containerWidth =
        textWidgetContainerRef.current?.getBoundingClientRect()?.width;

      if (containerWidth && containerWidth <= MIN_TEXT_WIDGET_WIDTH) {
        console.log(
          `%cНепредвиденная ситуация:%c Ширина контейнера (${containerWidth}px) текстовых виджетов - меньше чем минимальная ширина текстового виджета (${MIN_TEXT_WIDGET_WIDTH}px)`,
          'color: red;',
          ''
        );
      }
    };

    handler();
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [isSmDown, textWidgetContainerRef]);
};

export default useMarkupErrorDetector;
