import { DonutChartDatum } from '../../../../services/Main/types.Component';
import { ChartsTooltipData } from '../../../lowLevel/ChartsTooltip/types';

export const getDonutTooltipData = (
  data: DonutChartDatum[]
): ChartsTooltipData => {
  return data.reduce<ChartsTooltipData>(
    (acc, { href, name, fill, value, printValue }) => {
      acc.payload.push({
        href,
        name,
        color: fill,
        printValue: printValue || value.toString(),
      });

      acc.total += value;

      return acc;
    },
    { payload: [], total: 0 }
  );
};
