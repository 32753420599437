import { LocalizationDictionary } from './types';

const enDictionary: LocalizationDictionary = {
  localeName: 'English',
  language: 'Language',
  addLine: 'Add line',
  close: 'Close',
  search: 'Search',
  errorOccurredWhileRequestingData:
    'An error occurred while requesting data. Try again or contact administrator.',
  uploadFiles: 'Upload files',
  clickToUploadFiles: 'Click to upload files',
  enterYourComment: 'Enter your comment',
  send: 'Send',
  enterTheCorrectDateFormat: 'Enter the correct date format, example {date}', // Interpolation ${formatDate(new Date())
  dateCannotBeLessThan: 'Then date cannot be less than {date}',
  dateCannotBeGreaterThan: 'Then date cannot be greater than {date}',
  noData: 'There is no data',
  goBackToTheMainPage: 'Back to main page',
  errorWhileUploadingFiles:
    'Error while uploading files. Try again or contact administrator.',
  fileTooLargeError:
    'Too large file. Try uploading a smaller file or contact administrator.',
  cancel: 'Cancel',
  secondsLeft: '{remaining} sec. left', // Interpolation
  minutesLeft: '{remaining} min. left', // Interpolation
  hoursLeft: '{remaining} h. left', // Interpolation
  daysLeft: '{remaining} d. left', // Interpolation
  QRCodeDescription:
    'Point the camera at the image with the QR code so that the QR code falls into the center of the field, limited by the red corners.',
  errorOccurredWhileTryingToScan:
    'An error occurred while trying to scan. Try again or contact your administrator.',
  followTheLink: 'Follow the link?',
  loading: 'Loading...',
  actions: 'Actions',
  filterList: 'Filter List',
  from: 'From',
  to: 'To',
  total: 'Total',
  selected: 'Selected',
  freeComment: 'Free comment',
  visibility: 'Visibility',
  noAccess: 'Upps... No access',
  forbiddenDescription:
    'We are sorry, but you do not have access to this page or resource. Please return to the main page.',
  forbiddenUserDescription:
    'We are sorry, but the account was disabled by the administrator.',
  notFound: 'Page not found...',
  notFoundDescription:
    'You are here because the requested page does not exist or has been moved to another address.',
  removedPageTitle: 'This page is gone.',
  removedPageDescription: 'Requested page was removed.',
  serviceUnavailable: 'The service is unavailable...',
  serviceUnavailableDescription:
    'Sorry... Unfortunately, the service is temporarily unavailable. We are already working on troubleshooting.',
  errorLoginGoogle:
    'An unexpected error occurred while trying to log-in with Google.',
  cancelLoginGoogle: 'You canceled your attempt to sign-in with Google.',
  loginWithGoogle: 'Log-in with Google',
  incorrectUsernameOrPassword: 'Incorrect username or password',
  errorOccurredWhileTryingToLogin:
    'An error occurred while trying to log-in. Try again or contact your administrator',
  loginHeaderMessage: 'Please log-in.',
  requiredField: 'Required field',
  login: 'Login',
  password: 'Password',
  signIn: 'Sign-in',
  logout: 'Logout',
  unexpectedDataErrorBoundary:
    'A fatal error occurred in the operation of the application.',
  unexpectedDataErrorBoundaryDescription:
    'Try reloading the page or contact the system administrator.',
  resetColumnWidthsToDefault: 'Reset column widths to default',
  resetColumnOrderToDefault: 'Reset column order to default',
  resetColumnVisibilityToDefault: 'Reset column visibility to default',
  reset: 'Reset',
  resetFilters: 'Reset filters',
  dropFilesHere: 'Drop files here...',
  dragAndDropFile: 'Drag and drop files here or click to select files',
  clear: 'Clear',
  today: 'Today',
  yesterday: 'Yesterday',
  noOptions: 'No options',
  userBlockedByAdministrator: 'User blocked by administrator.',
  open: 'Open',
  browserNotSupportFileViewing:
    'Your browser does not support previewing files',
  collapseAll: 'Collapse all',
  expandAll: 'Expand all',
  expandMenuTooltip: 'Expand menu',
  collapseMenuTooltip: 'Collapse menu',
  scanQrCode: 'read QR-code',
  profileButton: 'profile',
  showPassword: 'Show password',
  hidePassword: 'Hide password',
  set: 'Set',
  currentPassword: 'Current password',
  newPassword: 'New password',
  repeatPassword: 'Repeat password',
  settingAPassword: 'Setting a password',
  changePassword: 'Change password',
  restoringAccess: 'Restoring access',
  forgotPassword: 'Forgot password?',
  forgotPasswordText:
    'Enter your login and email address under which you are registered in the system. Information about password recovery will be sent to it.',
  emailSentSuccessfully: 'Email sent successfully!',
  emailSentSuccessfullyText:
    'An email with a link to reset your password has been sent on the:',
  enterYouNewPasswordTitle: 'Enter your new password',
  enterYouNewPasswordText: 'Enter a new login password',
  passwordsDoNotMatch: 'Passwords do not match',
  minimumCharacters: 'Minimum 10 characters',
  oneLowerEnglishLetter: 'At least one lowercase English letter',
  oneCapitalEnglishLetter: 'At least one capital Latin letter',
  oneDigit: 'At least one digit',
  oneSpecialSymbol: 'At least one of the special characters: !@#$%^&*()_+-=',
  containOnlyAllowedCharacters: 'Password must contain only allowed characters',
  showClosed: 'Show closed',
  hideClosed: 'Hide closed',
  toLoginForm: 'To login form',
  history: 'History',
  select: 'Select',
  defaultNoOptionsText: 'Enter at least 3 characters...',
  noOptionsWereFoundForQuery:
    'No suitable options were found for the entered query',
  firstTenRecordsFoundAreDisplayed:
    'The first 10 records found are displayed. Specify the criteria or open',
  allEntries: 'All entries',
  columnVisibilityControl: 'Column visibility control',
  allColumnsAreHidden: 'All columns are hidden',
  rowsPerPage: 'Rows per page',
  confirmAction: 'Confirm action',
  confirm: 'Confirm',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  swap: 'Swap',
  collapseSidebar: 'Collapse panel',
  expandSidebar: 'Expand panel',
  unableToRenderWidget: 'Unable to render widget',
  unknownError: 'Unknown error',
  preview: 'Preview',
  download: 'Download',
  messageForEmployees:
    'The message is visible only for employees of your organization',
  messageVisibleOnlyEmployees:
    'The message will only be visible for employees of your organization',
  messageVisibleEveryone: 'The message will be visible to everyone',
  quote: 'Quote',
  organization: 'Organization',
  department: 'Department',
  position: 'Position',
  phone: 'Phone',
  email: 'Email',
};

export default enDictionary;
