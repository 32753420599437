import {
  DirtyFormValues,
  FieldGroup,
  FormValues,
} from 'services/Main/types.Component';
import {
  ArrayOf,
  ArrayOfDefaultRow,
  ComboBoxOption,
  DateRangeValue,
  Field,
  OptionsType,
  RadioTreeSelectOption,
  SelectOption,
} from 'services/Main/types.Field';
import { SelectOptions } from './FormBuilderContext';

export const getInitialValues = (
  fieldGroups: FieldGroup[],
  ignoreDefaultValue?: boolean
) => {
  let fields: Field[] = [];

  fieldGroups.forEach((group) => {
    fields = [...fields, ...group.fields];
  });

  return fields.reduce<DirtyFormValues>((acc, field) => {
    let defaultValue = ignoreDefaultValue ? undefined : getDefaultValue(field);

    if ('disableClearable' in field && field.disableClearable) {
      defaultValue = getDefaultValue(field);
    }

    acc[field.name] = convertFieldValueFromServerToClient(field, defaultValue);
    return acc;
  }, {});
};

const getDefaultValue = (field: Field) => {
  if (field.type === 'arrayOf') {
    return field.defaultRows;
  }

  return field.defaultValue;
};

export const convertFieldValueFromServerToClient = (
  field: Field,
  value: unknown
) => {
  const {
    type,
    selectType,
    pickerType,
    multiple,
    optionsType,
    rowDefinition,
    disableClearable,
    options,
  } = field as any;

  if (type === 'select' && selectType && selectType === 'multi') {
    return (value as SelectOption[]) || [];
  }

  if (
    type === 'datePicker' &&
    (pickerType === 'date' ||
      pickerType === 'dateTime' ||
      pickerType === 'year')
  ) {
    if (pickerType === 'year') {
      const isValidValue =
        typeof value === 'string' || typeof value === 'number';

      if (disableClearable && !(isValidValue || field.defaultValue)) {
        return new Date().getFullYear().toString();
      }

      // '2023' || 2023 => '2023' || 2023
      // other any => null
      return isValidValue ? value : null;
    }

    return typeof value === 'string' ? value : null;
  }

  if (
    type === 'datePicker' &&
    (pickerType === 'dateRange' || pickerType === 'dateTimeRange')
  ) {
    return (value as DateRangeValue) || { from: null, to: null };
  }

  if (type === 'select') {
    return (value as string) || null;
  }

  if (type === 'comboBox') {
    if (multiple || optionsType === 'checkboxTree') {
      return (value as SelectOption[]) || [];
    }

    // Логика для disableClearable в FlatComboBoxSingle.
    // Из этого условия всегда возвращается какое-то значение.
    // Это либо defaultValue, который прилетел в пропсах, либо первый option.
    if (!multiple && optionsType === 'flat' && disableClearable === true) {
      return value ? (value as ComboBoxOption) : (options[0] as ComboBoxOption);
    }

    // Логика для disableClearable в RadioTreeComboBox.
    // Из этого условия всегда возвращается какое-то значение.
    // Это либо defaultValue, который прилетел в пропсах, либо первый option с
    // selectable: true.
    if (optionsType === OptionsType.radioTree && disableClearable === true) {
      if (value) return value;

      const firstSelectableOptions = (options as RadioTreeSelectOption[]).find(
        ({ selectable }) => selectable
      );

      if (!firstSelectableOptions) {
        throw new Error(
          `Ошибка в RadioTreeComboBox ${field.name}: передано свойство disableClearable: true, но не передано свойство defaultValue, кроме того, в опциях не найдено ни одного значения с свойством selectable: true.`
        );
      }

      return firstSelectableOptions as SelectOption;
    }

    return (value as SelectOption) ?? null;
  }

  if (type === 'entryPicker') {
    if (multiple) {
      return (value as SelectOption[]) || [];
    }

    if (disableClearable === true && !(value || field.defaultValue)) {
      throw new Error(
        `В EntryPicker ${field.name} пришел disableClearable: true, но не пришел defaultValue`
      );
    }

    return (value as SelectOption) ?? null;
  }

  if (type === 'formattedNumber') {
    return (value as string) ?? null;
  }

  if (type === 'radio') {
    return (
      (value as SelectOption) ||
      (Array.isArray((field as any).options) &&
        (field as any).options.length > 0 &&
        (field as any).options[0].value) ||
      null
    );
  }

  if (type === 'arrayOf') {
    // Если не пришел defaultRows, значит arrayOf пустой.
    if (!value || !Array.isArray(value)) return [] as DirtyFormValues[];

    // Пробегаемся и собираем коллекцию, где
    // ключ - имя поля (name)
    // значение - пропсы поля
    const arrayOfFieldByName = new Map<string, Field>(
      rowDefinition.map((f: Field) => [f.name, f])
    );

    /**
     * Пробегаемся по defaultRows и собираем значения (defaultValue)
     *
     * const from = [
     *   [
     *     {
     *       name: 'shop',
     *       defaultValue: null,
     *     },
     *     {
     *       name: 'room',
     *       defaultValue: null,
     *     },
     *     {
     *       name: 'equipment',
     *       defaultValue: null,
     *     },
     *   ],
     *   ...,
     * ];
     *
     * const to = [
     *   {
     *     shop: null,
     *     room: null,
     *     equipment: null,
     *   },
     *   ...,
     * ];
     */
    return (value as ArrayOfDefaultRow[][]).map((row: ArrayOfDefaultRow[]) => {
      return row.reduce((acc, { name, defaultValue }): DirtyFormValues => {
        const childrenFieldProps = arrayOfFieldByName.get(name);

        if (!childrenFieldProps) {
          console.error(`Не удалось определить пропсы для поля ${name}`);
          return acc;
        }

        return {
          ...acc,
          [name]: convertFieldValueFromServerToClient(
            childrenFieldProps,
            defaultValue
          ),
        };
      }, {} as DirtyFormValues);
    });
  }

  if (type === 'checkbox') {
    return (value as any) || false;
  }

  if (type === 'rating') {
    if (typeof value !== 'number') {
      console.warn(
        `Для поля ${field.name} прилетело дефолтное значение не число, поэтому преобразовали в 0.`
      );

      return 0;
    }

    if (value < 0) {
      console.warn(
        `Для поля ${field.name} прилетело дефолтное значение меньше 0, поэтому преобразовали в 0.`
      );

      return 0;
    }

    if (value > 5) {
      console.warn(
        `Для поля ${field.name} прилетело дефолтное значение больше 5, поэтому преобразовали в 5.`
      );

      return 5;
    }

    if (value % 0.5 !== 0) {
      console.warn(
        `Для поля ${field.name} прилетело дефолтное значение не кратное, поэтому округлили до ближайшего целочисленного.`
      );

      return Math.round(value);
    }

    return value;
  }

  return (value as string) || '';
};

export const mapValuesToPlain = (
  formValues: DirtyFormValues,
  fields: Field[]
): FormValues => {
  // Собираем объект со всеми полями формы и их стандартными значениями
  const emptyFormValues = getInitialValues([{ fields }], true);
  const preparedFormValues = { ...emptyFormValues, ...formValues };

  return Object.entries(preparedFormValues).reduce(
    dirtyValueReducer(fields),
    {}
  );
};

function dirtyValueReducer(fields: Field[], arrayOfFieldName?: string) {
  return (acc: any, [fieldName, dirtyValue]: any) => {
    if (!fields || fields.length === 0)
      return { ...acc, [fieldName]: dirtyValue };

    const field = arrayOfFieldName
      ? ((
          (fields.find((f) => f.name === arrayOfFieldName) as ArrayOf)
            ?.rowDefinition as Field[]
        ).find((f) => f.name === fieldName) as Field)
      : (fields.find((f) => f.name === fieldName) as Field);

    const isSelectOption =
      field && field.type === 'select' && field.selectType === 'single';

    // SelectOption[]
    const isArrayOfSelectOption =
      field && field.type === 'select' && field.selectType === 'multi';

    // ArrayOf
    const isArray = field && field.type === 'arrayOf';

    const isDateSomeRangePicker =
      field &&
      field.type === 'datePicker' &&
      field.pickerType.includes('Range');

    if (isSelectOption) {
      return {
        ...acc,
        [fieldName]: selectOptionFormatter(dirtyValue),
      };
    }

    if (isArrayOfSelectOption) {
      return {
        ...acc,
        [fieldName]: arrayOfSelectOptionFormatter(dirtyValue),
      };
    }

    if (isArray) {
      return {
        ...acc,
        [fieldName]: Array.isArray(dirtyValue)
          ? (dirtyValue as any).map((val: any) =>
              Object.entries(val).reduce(
                dirtyValueReducer(fields, fieldName),
                {}
              )
            )
          : dirtyValue,
      };
    }

    if (field && field.type === 'file') {
      return {
        ...acc,
        [fieldName]: dirtyValue === '' ? null : dirtyValue,
      };
    }

    if (field && field.type === 'formattedNumber') {
      return {
        ...acc,
        [fieldName]: dirtyValue === undefined ? null : dirtyValue,
      };
    }

    if (isDateSomeRangePicker) {
      return {
        ...acc,
        [fieldName]: dateSomeRangeFormatter(dirtyValue),
      };
    }

    return {
      ...acc,
      [fieldName]: dirtyValue as any,
    };
  };
}

function selectOptionFormatter(dirtyValue: SelectOption) {
  if (!dirtyValue) return null;

  return dirtyValue;
}

function arrayOfSelectOptionFormatter(dirtyValue: SelectOption[]) {
  if (!dirtyValue) return null;

  return dirtyValue;
}

function dateSomeRangeFormatter(dirtyValue: DateRangeValue) {
  const result = { ...dirtyValue };

  if (!result.from) result.from = null;
  if (!result.to) result.to = null;

  return result;
}

/**
 * fields: [
 *   { name: 'foo', type: 'comboBox', options: [...] },
 *   { name: 'bar', type: 'text' },
 *   { name: 'baz', type: 'entryPicker' },
 *   { name: 'buz', type: 'arrayOf', rowDefinition: [
 *     { name: 'hehe', type: 'comboBox', options: [...] },
 *   ] }
 * ]
 *
 * `${name}[${index}].${rowName}`
 *
 * output: {
 *   foo: [...],
 *   "buz.hehe": [...],
 *   "buz.haha": [...],
 * }
 */
export function initializeSelectOptions(fields: Field[]) {
  return fields.reduce((acc, field) => {
    if (field.type === 'arrayOf') {
      const optionsFromRowDefinition = field.rowDefinition.reduce(
        (subAcc, rowField) => {
          if (!('options' in rowField) || !rowField.options) return subAcc;

          return {
            ...subAcc,
            [`${field.name}.${rowField.name}`]: rowField.options,
          };
        },
        {} as SelectOptions
      );

      /**
       *
       * Пробегаемся по defaultRows и собираем значения (defaultValue)
       *
       * const from = [
       *   [
       *     {
       *       name: 'shop',
       *       defaultValue: null,
       *     },
       *     {
       *       name: 'room',
       *       defaultValue: null,
       *     },
       *     {
       *       name: 'equipment',
       *       defaultValue: null,
       *     },
       *   ],
       *  [
       *     {
       *       name: 'shop',
       *       defaultValue: {...},
       *     },
       *     {
       *       name: 'room',
       *       options: [...]
       *     },
       *     {
       *       name: 'equipment',
       *       options: [...]
       *     },
       *   ],
       *   ...,
       * ];
       *
       * const to = {
       *   arrayOf[1].shop: [...],
       *   arrayOf[1].equipment: [... ]
       * };
       */

      const optionsFromDefaultRows: SelectOptions = {};

      (field?.defaultRows || []).forEach((row, rowIndex) => {
        row.forEach((f) => {
          if (f.options) {
            optionsFromDefaultRows[`${field.name}[${rowIndex}].${f.name}`] =
              f.options;
          }
        });
      });

      return {
        ...acc,
        ...optionsFromRowDefinition,
        ...optionsFromDefaultRows,
      };
    }

    return groupFieldOptionsByNameReducer(acc, field);
  }, {});
}

const groupFieldOptionsByNameReducer = (
  acc: Record<string, ComboBoxOption[]>,
  field: Field
) => {
  if ('options' in field && field.options) {
    return {
      ...acc,
      [field.name]: field.options,
    };
  }

  return acc;
};
