import React from 'react';
import HomePage from 'pages/home';
import HidePreLoader from 'components/lowLevel/HidePreLoader';
import NotFound from 'pages/errors/NotFound';
import { presetPageName } from 'services/Main/types.Page';

interface PresetPageLoaderProps {
  presetName: presetPageName;
}

const PresetPageLoader = ({ presetName }: PresetPageLoaderProps) => {
  switch (presetName) {
    case 'home':
      return (
        <HidePreLoader>
          <HomePage />
        </HidePreLoader>
      );
    default:
      return (
        <HidePreLoader>
          <NotFound />
        </HidePreLoader>
      );
  }
};

export default PresetPageLoader;
