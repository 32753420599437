import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import useStyles from './Bubble.styles';

interface Props {
  side: 'left' | 'right';
  className?: string;
}

function Bubble({ side, children, className }: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.bubble, className, {
        [classes.sideLeft]: side === 'left',
        [classes.sideRight]: side === 'right',
      })}
    >
      {children}
    </div>
  );
}

export default Bubble;
