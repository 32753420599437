import { ParsableDate } from 'services/Main/types.common';
import isValidDate from 'utils/isValidDate';
import {
  parseISO,
  endOfDay,
  startOfDay,
  isAfter,
  isEqual,
  isBefore,
} from 'date-fns';

/**
 * Вспомогательная функция для возврата даты из разных возможных
 * (пришедших из бека) форматов дат.
 *
 * Может работать с ISO строками и UNIX timestamp.
 *
 * @param initialDate
 * @param fallbackDate
 */
export function getDate(
  initialDate?: ParsableDate,
  fallbackDate: Date | undefined | null = null
) {
  if (typeof initialDate === 'string' && initialDate !== '') {
    return isValidDate(parseISO(initialDate))
      ? parseISO(initialDate)
      : fallbackDate;
  }

  if (typeof initialDate === 'number') {
    return new Date(initialDate * 1000);
  }

  return fallbackDate;
}
/**
 * Функция getComparisonMaxDate используется для получения максимальной даты,
 * которую можно использовать для сравнения с другими датами. Функция принимает
 * два параметра: date типа Date и strictCompareDates типа boolean.
 *
 * - Если параметр strictCompareDates установлен в true, то функция просто
 * возвращает переданную дату без изменений.
 * - Если параметр strictCompareDates установлен в false или не передан, то
 * функция преобразует переданную дату в конец дня с помощью функции endOfDay
 * из библиотеки date-fns.
 *
 * Например, если передано значение new Date('2022-06-01T10:00:00'), а параметр
 * strictCompareDates установлен в false, то функция вернет значение
 * new Date('2022-06-01T23:59:59.999'), которое соответствует концу дня
 * указанной даты.
 */
export const getComparisonMaxDate = (
  date: Date,
  strictCompareDates: boolean = false
) => {
  if (strictCompareDates) {
    return date;
  }
  return endOfDay(date);
};

/**
 * Функция getComparisonMinDate используется для получения минимальной даты,
 * которую можно использовать для сравнения с другими датами. Функция принимает
 * два параметра: date типа Date и strictCompareDates типа boolean.
 *
 * - Если параметр strictCompareDates установлен в true, то функция просто
 * возвращает переданную дату без изменений.
 * - Если параметр strictCompareDates установлен в false или не передан, то
 * функция преобразует переданную дату в начало дня с помощью функции startOfDay
 * из библиотеки date-fns.
 *
 * Например, если передано значение new Date('2023-06-14T12:31:56'), а параметр
 * strictCompareDates установлен в false, то функция вернет значение
 * new Date('2023-06-14T00:00:00'), которое соответствует началу
 * дня указанной даты.
 */
export const getComparisonMinDate = (
  date: Date,
  strictCompareDates: boolean = false
) => {
  if (strictCompareDates) {
    return date;
  }
  return startOfDay(date);
};

/**
 * Функция `isAfterOrEqual` принимает два параметра: `date` и `dateToCompare`,
 * оба типа `Date`. Она использует функции `isAfter` и `isEqual` из библиотеки
 * `date-fns` для сравнения дат.
 * - Если первая дата больше или равна второй дате, то функция возвращает `true`
 * - В противном случае, функция возвращает `false`.
 */
export const isAfterOrEqual = (date: Date, dateToCompare: Date) => {
  return isAfter(date, dateToCompare) || isEqual(date, dateToCompare);
};

/**
 * Функция `isBeforeOrEqual` принимает два параметра: `date` и `dateToCompare`,
 * оба типа `Date`. Она использует функции `isBefore` и `isEqual` из библиотеки
 * `date-fns` для сравнения дат.
 * - Если первая дата меньше или равна второй дате, то функция возвращает `true`
 * - В противном случае, функция возвращает `false`.
 */
export const isBeforeOrEqual = (date: Date, dateToCompare: Date) => {
  return isBefore(date, dateToCompare) || isEqual(date, dateToCompare);
};
