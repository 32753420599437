import React from 'react';
import { SidebarProps } from '../../../../services/Main/types.Page';
import WidgetFilterSidebar from './WidgetFilterSidebar';
import SidebarWrapper from './SidebarWrapper';

interface Props {
  sidebar: SidebarProps;
}

const SidebarLoader = ({ sidebar }: Props) => {
  const { type } = sidebar;

  const sidebarComponent = (() => {
    switch (type) {
      case 'filter':
        return <WidgetFilterSidebar {...sidebar} />;
      default:
        throw new Error(
          `Не удалось определить тип компонента в sidebar страницы. Прилетел: ${type}`
        );
    }
  })();

  return <SidebarWrapper>{sidebarComponent}</SidebarWrapper>;
};

export default SidebarLoader;
