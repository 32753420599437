import axios from 'axios';
import { LoggerRequest } from './Main/types';
import store from '../store';
import logService from './Log';
import { metaActions } from '../store/meta';
import { snackbarsActions } from '../store/snackbars';

export const baseURL: string = (() => {
  if (process.env.NODE_ENV === 'development') {
    if (!process.env.REACT_APP_REMOTE_API_URL) {
      throw new Error('REACT_APP_REMOTE_API_URL is not defined');
    }

    return process.env.REACT_APP_REMOTE_API_URL;
  }

  return window.location.origin;
})();

export const apiClient = axios.create({
  baseURL,
  headers: {
    'x-timezone-offset': new Date().getTimezoneOffset(),
  },
});

// ещё один перехватчик есть в Auth-сервисе
apiClient.interceptors.response.use(
  (config) => config,
  async (error: any) => {
    const { config } = error;

    // e.g. Canceled requests.
    if (!error.response) return Promise.reject(error);

    // TODO локализовать. Можно пропустить словари через Redux.
    if (error.response.status === 504) {
      store.dispatch(
        snackbarsActions.enqueueSnackbar({
          message:
            'Превышен лимит ожидания ответа сервера. Обновите страницу или обратитесь к администратору.',
        })
      );
    }

    if (error.response.status === 403) {
      // если вывалилась ошибка на root-запросе
      if (store.getState().meta.isAppLoading) {
        store.dispatch(metaActions.hideLoadingScreen());
      }

      if (
        error.response.data.isSuccess === false &&
        error.response.data.reason === 'userBlocked'
      ) {
        store.dispatch(metaActions.setShowForbiddenUserPage(true));

        return Promise.reject(error);
      }

      store.dispatch(metaActions.setShowForbiddenPage(true));
    }

    if (
      error.response.status !== 401 &&
      error.response.status !== 200 &&
      !config.url?.includes('refresh-token')
    ) {
      const { user } = store.getState();

      const data: LoggerRequest = {
        source: 'front',
        type: 'httpError',
        message: error.stack,
        user: user
          ? {
              name: user.name,
              login: user.login,
              id: user.id,
              locale: user.locale,
            }
          : undefined,
        additionalInfo: {
          browserInfo: {
            appVersion: window.navigator.appVersion,
            language: window.navigator.language,
            languages: window.navigator.languages,
            userAgent: window.navigator.userAgent,
            vendor: window.navigator.vendor,
          },
          general: {
            requestURL: config.url,
            requestMethod: config.method,
            statusCode: error.response.status,
          },
          requestHeaders: config.headers,
          responseHeaders: error.response.headers,
          requestBody: config.data,
          responseBody: error.response.data,
          other: {
            error: { ...error, response: { ...error.response } },
            config,
          },
        },
      };

      logService.write(data);
    }

    return Promise.reject(error);
  }
);

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
