import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdditionalPayloadToSendWithForm {
  [key: string]: unknown;
}

interface AdditionalPayloadToSendWithFormStore {
  [key: string]: AdditionalPayloadToSendWithForm;
}

type CreateAdditionalPayloadToSendWithFormPayload = {
  id: string;
  /**
   * Храним дополнительные данные для отправки с формой.
   * Не стоит использовать для хранения состояния.
   */
  additionalPayloadToSendWithForm: AdditionalPayloadToSendWithForm;
};

export interface MetaState {
  isAppLoading: boolean;
  isSideBarOpen: boolean;
  isCompactMenuView: boolean;
  additionalPayloadToSendWithFormStore: AdditionalPayloadToSendWithFormStore;
  showForbiddenPage: boolean;
  showForbiddenUserPage: boolean;
  isSomeActionExecuting: boolean; // исполняется ли сейчас экшн (Table или Form)
}

const initialState: MetaState = {
  isAppLoading: true,
  isSideBarOpen: true,
  isCompactMenuView: false,
  additionalPayloadToSendWithFormStore: {},
  showForbiddenPage: false,
  showForbiddenUserPage: false,
  isSomeActionExecuting: false,
};

export const { reducer, actions } = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    showLoadingScreen: (state) => {
      state.isAppLoading = true;
    },
    hideLoadingScreen: (state) => {
      state.isAppLoading = false;
    },
    setIsSideBarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSideBarOpen = payload;
    },
    setIsCompactMenuView: (state, { payload }: PayloadAction<boolean>) => {
      state.isCompactMenuView = payload;
    },
    createAdditionalPayloadToSendWithForm: (
      state,
      { payload }: PayloadAction<CreateAdditionalPayloadToSendWithFormPayload>
    ) => {
      state.additionalPayloadToSendWithFormStore[payload.id] =
        payload.additionalPayloadToSendWithForm;
    },
    deleteAdditionalPayloadToSendWithForm: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      delete state.additionalPayloadToSendWithFormStore[payload];
    },
    setShowForbiddenPage: (state, { payload }: PayloadAction<boolean>) => {
      state.showForbiddenPage = payload;
    },
    setShowForbiddenUserPage: (state, { payload }: PayloadAction<boolean>) => {
      state.showForbiddenUserPage = payload;
    },
    setIsSomeActionExecuting: (state, { payload }: PayloadAction<boolean>) => {
      state.isSomeActionExecuting = payload;
    },
  },
});

export default reducer;
