import {
  ConfirmDialog,
  PreSubmitResponse,
  RequestConfig,
} from '../services/Main/types.Component';
import mainService from '../services/Main';

interface Props {
  showConfirmDialog: (confirmDialogConfig: ConfirmDialog) => Promise<unknown>;
  preSubmitRequestConfig: RequestConfig;
  params?: object;
}

export async function makePreSubmitRequest({
  preSubmitRequestConfig,
  showConfirmDialog,
  params,
}: Props) {
  return mainService
    .makeActionRequest<PreSubmitResponse>(preSubmitRequestConfig, params)
    .then(({ payload }) => {
      // Мы не реализовывали обработку snackbar, alert,
      // preventSuccessResponseReactionReasons. Если нужно
      // реализовать, то можно посмотреть в FormAction.tsx.
      if (payload?.confirmDialog) {
        return showConfirmDialog(payload.confirmDialog);
      }

      return Promise.resolve();
    });
}
