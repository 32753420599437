import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Rule: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M16.54 11.0001L13 7.46005L14.41 6.05005L16.53 8.17005L20.77 3.93005L22.18 5.34005L16.54 11.0001ZM11 7.00005H2V9.00005H11V7.00005ZM21 13.4101L19.59 12.0001L17 14.5901L14.41 12.0001L13 13.4101L15.59 16.0001L13 18.5901L14.41 20.0001L17 17.4101L19.59 20.0001L21 18.5901L18.41 16.0001L21 13.4101ZM11 15.0001H2V17.0001H11V15.0001Z" />
  </SvgIcon>
);
Rule.muiName = 'SvgIcon';

Rule = React.memo(Rule);
Rule.displayName = 'Rule';

export default Rule;
