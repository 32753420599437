import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { metaActions } from 'store/meta';
import { useCallback } from 'react';

type Returns = [boolean, (newState: boolean) => void];

export const useIsSideBarOpen = (): Returns => {
  const isSideBarOpen = useSelector((state) => state.meta.isSideBarOpen);
  const dispatch = useDispatch();
  const setIsSideBarOpen = useCallback(
    (newState: boolean) => {
      dispatch(metaActions.setIsSideBarOpen(newState));
    },
    [dispatch]
  );

  return [isSideBarOpen, setIsSideBarOpen];
};
