import React from 'react';
import { IconButton } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import Icon from '../../../../../lowLevel/Icon/Icon';
import useStyles from './SendCommentButton.styles';

function SendCommentButton() {
  const classes = useStyles();

  const { watch, formState } = useFormContext();

  const comment = watch('comment');

  return (
    <IconButton
      className={classes.button}
      type="submit"
      disabled={comment.length === 0 || formState.isSubmitting}
    >
      <Icon definition="mui:SendRounded" />
    </IconButton>
  );
}

export default SendCommentButton;
