import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    draggableItemContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'grabbing',
    },
    draggableItem: {
      // Отключаем все эффекты для элемента, который перетаскиваем
      pointerEvents: 'none',
      '& .MuiChip-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  })
);
