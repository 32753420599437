import React from 'react';
import {
  ComboBoxOption,
  FlatComboBoxMultiple as FlatComboBoxMultipleProps,
  FlatComboBoxSingle as FlatComboBoxSingleProps,
} from '../../../../../services/Main/types.Field';
import { FlatComboBoxProps } from '../../types';
import FlatComboBoxSingle from './FlatComboBox.single';
import FlatComboBoxMultiple from './FlatComboBox.multiple';

const FlatComboBox = (props: FlatComboBoxProps) => {
  const { multiple } = props;

  if (multiple) {
    return (
      <FlatComboBoxMultiple
        {...(props as FlatComboBoxProps<
          ComboBoxOption[],
          FlatComboBoxMultipleProps
        >)}
      />
    );
  }

  return (
    <FlatComboBoxSingle
      {...(props as FlatComboBoxProps<ComboBoxOption, FlatComboBoxSingleProps>)}
    />
  );
};

export default FlatComboBox;
