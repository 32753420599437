import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) =>
  createStyles({
    bubble: {
      backgroundColor: fade(
        theme.palette.primary.light,
        theme.palette.action.hoverOpacity
      ),
    },
  })
);
