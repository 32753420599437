import { IconDefinition, ParsableDate } from './types.common';
import { mimeType } from './types.mimeTypes';
import { GridItem } from './types.GridItem';
import {
  RequestConfig,
  DirtyFormValue,
  SortingObject,
} from './types.Component';

export type Field =
  | ArrayOf
  | ComboBox
  | Checkbox
  | DatePicker
  | FileUpload
  | FormattedNumber
  | RadioGroup
  | Select
  | Text
  | EntryPicker
  | Rating;

// Тип для моделирования массивов полей.
//
// Бывают ситуации, когда мы хотим предоставить
// пользователю возможность заполнить массив каких-то полей
// (список работ, оценка работы в смете).
//
// Структура полей может представлять из себя что-то подобное:
// [
//   { description: 'Сделал то-то', price: 200, quantity: 2 },
//   { description: 'Ещё то-то', price: 140, quantity: 1 },
// ]
//
// Объект для описания такой структуры может выглядеть так:
// {
//   type: 'arrayOf', // указали тип поля
//   // придумали name для идентификации этой структуры в контексте всей формы
//   name: 'workList',
//   row: [ // см. апи по моделированию типов полей (тип Field)
//     { type: 'text' name: 'description' },
//     { type: 'text', inputType: 'number', name: 'price' },
//     { type: 'text', inputType: 'number', name: 'quantity' },
//   ]
// }
//
// Или может быть просто массив каких-то полей, вида:
// [ 'Не убрали за собой', 'Забыли смазать дверцу' ]
//
// Тогда объект описания может выглядеть так:
// {
//   type: 'arrayOf', // указали тип поля
//   // придумали name для идентификации этой структуры в контексте всей формы
//   name: 'feedback',
//   // указали, что row представляет из себя одно текстовое поле
//   row: { type: 'text' },
// }
//
// ⬇️ Пример использования ⬇️
//
// Хотим получить следующие данные:
// const replacedParts = [
//   { item: 'Хлебопечка', quantity: 1, measure: 'шт.', price: 200 },
//   { item: 'Вал', quantity: 10, measure: 'шт.', price: 20 },
//   { item: 'Вал', quantity: 10, measure: 'шт.', price: 20 },
// ];
//
// Для этого с бека должно прийти это:
// const field: ArrayOf = {
//   type: 'arrayOf',
//   name: 'replacedParts',
//   label: 'Заменённые запчасти',
//   rowDefinition: [
//     {
//       type: 'select',
//       name: 'spec',
//       options: [
//         {
//           value: '5f74c62d-5f50-49c1-8b88-ed688da6dc01',
//           label: 'Производитель',
//         },
//         {
//           value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//           label: 'Серийный номер',
//         },
//         {
//           value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//           label: 'Страна/происхождение',
//         },
//       ],
//       label: 'Спецификация',
//     },
//     { type: 'text', name: 'foo', label: 'Значение' },
//   ],
//   defaultValue: [
//     {
//       spec: {
//         value: '5f74c62d-5f50-49c1-8b88-ed688da6dc01',
//         label: 'Производитель',
//       },
//       foo: 'Россия',
//     },
//     {
//       spec: {
//         value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//         label: 'Серийный номер',
//       },
//       foo: '63379',
//     },
//     {
//       spec: {
//         value: '75e22fc6-d534-417d-9794-405a6877f0ee',
//         label: 'Страна/происхождение',
//       },
//       foo: 'sdf,dsf ods,fo',
//     },
//   ],
// };

export type ArrayOf = TableStyleArrayOf | FormStyleArrayOf;

export interface ArrayOfDefaultRow {
  // Имя поля
  name: string;
  // Значение поля
  defaultValue: DirtyFormValue;
  // Опшины поля
  options?: ComboBoxOption[] | TreeSelectOption[];
}

export interface TableStyleArrayOf extends BaseArrayOf<'table'> {
  // Включение или отключение Drag and Drop. По-умолчанию false.
  enableDragAndDrop?: boolean;
  // Максимальная высота, после достижения которой будет отображаться
  // вертикальная прокрутка.
  // Одна строка, в среднем (она динамичная) занимает 60 пикселей.
  // Разумно делать не менее 400 пикселей.
  // Если не передано, то вертикальной прокрутки не будет вообще.
  maxHeight?: number;
}

export interface FormStyleArrayOf extends BaseArrayOf<'form'> {}

export interface BaseArrayOf<VS> extends BaseField<'arrayOf'> {
  viewStyle?: VS; // стиль ArrayOf. По-умолчанию, 'form'.
  rowDefinition: Field[];
  defaultRows?: ArrayOfDefaultRow[][];
  onlyUnique?: string[];
  disallowRowDeletion?: boolean; // запретить удаление строк
  disallowRowAddition?: boolean; // запретить добавление строк
  showAutoNumeration?: boolean; // показать авто-нумерацию
  addRowButtonText?: string; // текст кнопки "добавить строку"
  showEmptyRowByDefault?: boolean; // показать пустую строку (нельзя удалить). По-умолчанию `true`.
  // Направление сортировки дефолтных значений по-умолчанию.
  // Если передано, то defaultValue отсортируются на фронте. На беке сортировать
  // не нужно.
  defaultSorting?: SortingObject;
}

export type DatePicker =
  | SimpleDatePicker
  | DateTimePicker
  | DateRangePicker
  | DateTimeRangePicker
  | YearPicker;

export interface SimpleDatePicker extends BaseDatePicker<'date'> {
  defaultValue?: ParsableDate;
}

export interface DateTimePicker extends BaseDatePicker<'dateTime'> {
  defaultValue?: ParsableDate;
}

export interface DateRangePicker extends BaseDatePicker<'dateRange'> {
  defaultValue?: DateRangeValue;
}

export interface DateTimeRangePicker extends BaseDatePicker<'dateTimeRange'> {
  defaultValue?: DateRangeValue;
}

export interface YearPicker extends BaseDatePicker<'year'> {
  defaultValue?: ParsableDate;
  // Отключить возможность очищать поле по нажатию на кнопку Очистить, т.е.
  // значение всегда будет установлено. В качестве первично установленного
  // значения будет установлен текущий год, либо defaultValue.
  disableClearable?: boolean;
}

interface BaseDatePicker<T> extends BaseField<'datePicker'> {
  pickerType: T; // by defaults 'date'
  defaultValue?: ParsableDate | DateRangeValue;
  initialFocusedDate?: ParsableDate;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
}

export type DateRangeValue = {
  from?: string | null;
  to?: string | null;
};

export interface Checkbox extends BaseField<'checkbox'> {
  defaultValue?: boolean;
  labelPlacement?: 'top' | 'end';
  highlightColor?: HighlightColor;
}

export interface RadioGroup extends BaseField<'radio'> {
  options: RadioOption[];
}

export interface FileUpload extends BaseField<'file'> {
  viewConfig?: FileUploadViewConfig;
  defaultValue?: File[];
  accept?: mimeType[];
}

interface FileUploadViewConfig {
  viewStyle?: 'textFieldWithClip' | 'dropZone'; // тип представление
}

export interface File {
  // Уникальный идентификатор файла
  id: string | number;
  // Размер в байтах
  size: number;
  name: string;
  mimeType: string;
  // Ссылка на файл
  url: string;
}

/**
 * Поле для выбора значения справочника
 */
export type EntryPicker = EntryPickerSingle | EntryPickerMulti;

export interface EntryPickerSingle extends BaseEntryPicker<ComboBoxOption> {
  multiple: false;
  disableClearable?: boolean;
}

export interface EntryPickerMulti extends BaseEntryPicker<ComboBoxOption[]> {
  multiple: true;
}

interface BaseEntryPicker<D> extends BaseField<'entryPicker'> {
  multiple: boolean;
  defaultValue?: D;
  searchRequestConfig: RequestConfig;
  gridRequestConfig: RequestConfig;
  // Передавать ли состояние формы при like-поиске или работе с табличной частью
  // поля. В body будет дополнительно прилетать два свойства:
  //  1. currentFieldName - имя текущего EntryPicker.
  //  2. formState - текущее состояние всей формы.
  includeFormStateWithRequests?: boolean;
}

/**
 * \\ Поле для выбора значения справочника
 */

/**
 * Поле для выбора из списка значений.
 */
export type ComboBox =
  | FlatComboBoxMultiple
  | FlatComboBoxSingle
  | CheckboxTreeComboBox
  | RadioTreeComboBox
  | AsyncFlatComboBoxMultiple
  | AsyncFlatComboBoxSingle
  | AsyncCheckboxTreeComboBox
  | AsyncRadioTreeComboBox;
export enum OptionsType {
  checkboxTree = 'checkboxTree',
  radioTree = 'radioTree',
  flat = 'flat',
}

/**
 * Древовидный список checkbox-значений.
 * В стейте может храниться несколько значений.
 *
 * Options загружаются асинхронно.
 *
 * В options должны передаваться CheckboxTreeOption.
 *
 * На бек придёт массив CheckboxTreeOption.
 */
export interface AsyncCheckboxTreeComboBox
  extends BaseComboBox<OptionsType.checkboxTree> {
  defaultValue?: CheckboxTreeSelectOption[];
  multiline?: boolean;
  searchOptionsRequestConfig: RequestConfig;
  optionsLoader: 'async';
}

/**
 * Древовидный список checkbox-значений.
 * В стейте может храниться несколько значений.
 *
 * В options должны передаваться CheckboxTreeOption.
 *
 * На бек придёт массив CheckboxTreeOption.
 */
export interface CheckboxTreeComboBox
  extends BaseComboBox<OptionsType.checkboxTree> {
  defaultValue?: CheckboxTreeSelectOption[];
  multiline?: boolean;
  options: CheckboxTreeSelectOption[];
  optionsLoader?: 'sync';
}

/**
 * Древовидный список radio-значений.
 * В стейте может быть только одно значение.
 *
 * Options подгружаются асинхронно.
 *
 * В options должны передаваться RadioTreeSelectOption.
 * На основе selectable в RadioTreeSelectOption будет выводиться радиокнопка
 * для выбора значения.
 *
 *
 * На бек придёт одно значение, типа RadioTreeSelectOption.
 */
export interface AsyncRadioTreeComboBox
  extends BaseComboBox<OptionsType.radioTree> {
  defaultValue?: RadioTreeSelectOption;
  // Отключить возможность очищать поле по нажатию на крестик, т.е. значение
  // всегда будет установлено. В качестве первично установленного значения будет
  // установлен первый в списке Option, либо defaultValue. Но если устанавливаете
  // defaultValue вручную, хорошо было бы поднять его в начало списка (общая
  // практика).
  disableClearable?: boolean;
  searchOptionsRequestConfig: RequestConfig;
  optionsLoader: 'async';
}

/**
 * Древовидный список radio-значений.
 * В стейте может быть только одно значение.
 *
 * В options должны передаваться RadioTreeSelectOption.
 * На основе selectable в RadioTreeSelectOption будет выводиться радиокнопка
 * для выбора значения.
 *
 *
 * На бек придёт одно значение, типа RadioTreeSelectOption.
 */
export interface RadioTreeComboBox extends BaseComboBox<OptionsType.radioTree> {
  defaultValue?: RadioTreeSelectOption;
  // Отключить возможность очищать поле по нажатию на крестик, т.е. значение
  // всегда будет установлено. В качестве первично установленного значения будет
  // установлен первый в списке Option, либо defaultValue. Но если устанавливаете
  // defaultValue вручную, хорошо было бы поднять его в начало списка (общая
  // практика).
  disableClearable?: boolean;
  options: RadioTreeSelectOption[];
  optionsLoader?: 'sync';
}

/**
 * Аналог MultipleSelect.
 * Плоский список значений с асинхронным получением options.
 * В стейте может быть несколько значений.
 *
 * На бек придёт массив из значений, типа ComboBoxOption.
 */
export interface AsyncFlatComboBoxMultiple
  extends BaseComboBox<OptionsType.flat> {
  multiple: true;
  multiline?: boolean;
  defaultValue?: ComboBoxOption[];
  searchOptionsRequestConfig: RequestConfig;
  optionsLoader: 'async';
}

/**
 * Аналог MultipleSelect.
 * Плоский список значений.
 * В стейте может быть несколько значений.
 *
 * На бек придёт массив из значений, типа ComboBoxOption.
 */
export interface FlatComboBoxMultiple extends BaseComboBox<OptionsType.flat> {
  multiple: true;
  multiline?: boolean;
  defaultValue?: ComboBoxOption[];
  options: ComboBoxOption[];
  optionsLoader?: 'sync';
}

/**
 * Аналог SingleSelect.
 * Плоский список значений с асинхронным получением options.
 * В стейте хранится только один вариант.
 *
 * На бек придёт одно значение, типа ComboBoxOption.
 */
export interface AsyncFlatComboBoxSingle
  extends BaseComboBox<OptionsType.flat> {
  multiple?: false;
  // Отключить возможность очищать поле по нажатию на крестик, т.е. значение
  // всегда будет установлено. В качестве первично установленного значения будет
  // установлен первый в списке Option, либо defaultValue. Но если устанавливаете
  // defaultValue вручную, хорошо было бы поднять его в начало списка (общая
  // практика).
  disableClearable?: boolean;
  defaultValue?: ComboBoxOption;
  searchOptionsRequestConfig: RequestConfig;
  optionsLoader: 'async';
}

/**
 * Аналог SingleSelect.
 * Плоский список значений.
 * В стейте хранится только один вариант.
 *
 * На бек придёт одно значение, типа ComboBoxOption.
 */
export interface FlatComboBoxSingle extends BaseComboBox<OptionsType.flat> {
  multiple?: false;
  // Отключить возможность очищать поле по нажатию на крестик, т.е. значение
  // всегда будет установлено. В качестве первично установленного значения будет
  // установлен первый в списке Option, либо defaultValue. Но если устанавливаете
  // defaultValue вручную, хорошо было бы поднять его в начало списка (общая
  // практика).
  disableClearable?: boolean;
  defaultValue?: ComboBoxOption;
  options: ComboBoxOption[]; // массив вариантов. Имеется непрямая связь с полем `optionsType`.
  optionsLoader?: 'sync';
}

/**
 * Базовый интерфейс для всех типов ComboBox.
 */
interface BaseComboBox<T> extends BaseField<'comboBox'> {
  // 'flat' | 'checkboxTree' | 'radioTree'
  optionsType: T;
  // Жёлтые поля
  warningText?: string;
  // Вариант представления выбранного значения. Если не задано, будет 'text'
  chipTagViewStyle?: 'text' | 'chip';
}

export type Select =
  | MultiSelect
  | SingleSelect
  | AsyncMultiSelect
  | AsyncSelect;

export interface AsyncMultiSelect extends BaseSelect<'multi'> {
  defaultValue?: SelectOption[];
  searchOptionsRequestConfig: RequestConfig;
  optionsLoader: 'async';
}

export interface AsyncSelect extends BaseSelect<'single'> {
  defaultValue?: SelectOption;
  searchOptionsRequestConfig: RequestConfig;
  optionsLoader: 'async';
}

export interface MultiSelect extends BaseSelect<'multi'> {
  defaultValue?: SelectOption[];
  options: SelectOption[];
  optionsLoader?: 'sync';
}

export interface SingleSelect extends BaseSelect<'single'> {
  defaultValue?: SelectOption;
  options: SelectOption[];
  optionsLoader?: 'sync';
}

interface BaseSelect<T> extends BaseField<'select'> {
  defaultValue?: SelectOption | SelectOption[];
  selectType?: T; // by default 'single',
  highlightColor?: HighlightColor;
}

export type HighlightColor = 'error' | 'warning' | 'info' | 'success';

export type RadioTreeSelectOption = {
  selectable: boolean; // можно ли выбирать этот Option
} & TreeSelectOption;

export type CheckboxTreeSelectOption = {
  isSelectableBranch?: boolean;
} & TreeSelectOption;

export type TreeSelectOption = {
  parentValue: string | null; // значение родительского поля
} & ComboBoxOption;

export type ComboBoxOption = {
  value: string; // значение поля
  label: string; // отображаемое значение
  href?: string; // ссылка
};

export type RadioOption = SelectOption & {
  icon?: IconDefinition | null;
};

export type SelectOption = {
  value: string;
  label: string;
  href?: string;
  disabled?: boolean; // by default 'false' - DEPRECATED.
};

export type FormattedNumber = PriceFormat | FlexibleNumberFormat;

// Нужен для отображения инпута для работы с ценами.
// Автоматически делит число по разрядам и подставляет
// знак валюты в конце.
export interface PriceFormat extends BaseFormattedNumber<'price'> {
  currencySign?: string;
}

// Нужен для ручного кофигурирования правил
// форматирования числового ввода.
//
// Документация по назначению свойств маски https://github.com/s-yadav/react-number-format
// Можно применять только те свойства, которые перечислены ниже.
export interface FlexibleNumberFormat
  extends BaseFormattedNumber<'flexibleNumber'> {
  decimalScale?: number | string;
  decimalSeparator?: string;
  thousandSeparator?: string;
  allowNegative?: boolean;
}

export interface BaseFormattedNumber<T> extends BaseField<'formattedNumber'> {
  formattedNumberType: T; // 'price' | 'flexibleNumber'
  min?: number | string;
  max?: number | string;
}

export interface Text extends BaseField<'text'> {
  inputType?: 'text' | 'numeric' | 'password';
  multiline?: boolean;
  rowsMax?: number; // Максимальное количество строк
  autoFocus?: boolean;
}

// <editor-fold desc="RatingField">
export interface Rating extends BaseField<'rating'> {
  // Диапазон от 0 до 5. Шаг 0.5, например: 0, 0.5, 1, 1.5...
  defaultValue?: RatingFieldValue;
}

export type RatingFieldValue =
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 4.5
  | 5;
// </editor-fold>

interface BaseField<T> extends GridItem {
  type: T;
  name: string;
  label?: string | Element | JSX.Element;
  helperText?: string;
  defaultValue?: unknown;
  disabled?: boolean;
  validationConfig?: ValidationConfig;
  renderCondition?: FieldRenderCondition;
  /**
   * Управления функционалом браузерного автозаполнения.
   *
   * "on" - включает автозаполнение текста;
   * "off" - отключает автозаполнение.
   *
   * Документация: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
   *
   * По-умолчанию: "off"
   */
  autoComplete?: string;
}

// Какие бывают условия:
//
// На какое-то другое поле установлено определённое значение.
// На какое-то другое поле НЕ установлено определённое значение.
// На какое-то другое поле установлено ЛЮБОЕ значение.
// На какое-то другое поле НЕ установлено НИКАКОЕ значение.
export interface FieldRenderCondition {
  dependOn: string; // name поля, за значением которого следим.
  condition: 'equals' | 'except' | 'empty' | 'notEmpty' | 'some';
  value?: unknown;
}

export const ALL_VALIDATION_TYPES = [
  'string',
  'number',
  'array',
  'mixed',
  'date',
] as const;
type validationTypeTuple = typeof ALL_VALIDATION_TYPES;
export type ValidationType = validationTypeTuple[number];

export interface ValidationConfig {
  validationType: ValidationType;
  validations: Validation[];
}

export interface Validation {
  type: string;
  params: ValidationParams;
}

export type ValidationParams =
  | WithErrorText
  | WithArgAndErrorText
  | WithSubSchemaAndErrorText
  | WithWhenValidationRule
  | WithEveryValidationRule;

type WithErrorText = [string];
type WithArgAndErrorText = [unknown, string];
type WithSubSchemaAndErrorText = [Pick<Field, 'name' | 'validationConfig'>[]];
export type WithWhenValidationRule = [string, WhenValidationRule];
export type WithEveryValidationRule = [FieldWithValue[], EveryValidationRule];

export type EveryValidationRule = {
  then: Validation[];
  otherwise?: Validation[];
};

interface FieldWithValue {
  name: string;
  is: unknown;
}

interface WhenValidationRule {
  is: unknown; // значение "главного" поля
  then: Validation[]; // если "главное" поле === is, то выполнить эти валидации
  otherwise?: Validation[]; // иначе провести эти валидации
}
