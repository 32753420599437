import React, { FunctionComponent, MouseEvent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import LinkComponent from '../../../../lowLevel/Link';
import { Link } from '../../../../../services/Main/types.Component';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value,
}) => {
  if (!value) return null;

  const { href, label } = value as Link;

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return <LinkComponent to={href} label={label} onClick={handleClick} />;
};

interface IconTypeProviderProps {
  for: string[];
}

const LinkTypeProvider = (props: IconTypeProviderProps) => (
  <DataTypeProvider formatterComponent={Formatter} {...props} />
);

export default LinkTypeProvider;
