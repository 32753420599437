import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { UseFormMethods, useForm, FormProvider } from 'react-hook-form';
import {
  DirtyFormValues,
  FieldGroup,
  FormValues,
} from 'services/Main/types.Component';
import { Field } from 'services/Main/types.Field';
import FieldGroupComponent from '../FieldGroup';
import FieldComponent from '../Field';
import {
  getInitialValues,
  mapValuesToPlain,
  initializeSelectOptions,
} from './helpers';
import useYupSchema from './useYupSchema';
import FormBuilderContext, {
  FormSubmitHandlers,
  SelectOptions,
} from './FormBuilderContext';
import GridBuilder from '../GridBuilder';
import { yupResolver } from './yupResolver';

export interface InversePropsBag
  extends Pick<UseFormMethods<DirtyFormValues>, 'reset'> {
  defaultValues: DirtyFormValues;
}

export interface FormBuilderProps {
  fieldGroups: FieldGroup[];
  disableBorder?: boolean;
  globalDisabled?: boolean;
  header?: (props: InversePropsBag) => ReactElement;
  footer?: (props: InversePropsBag) => ReactElement;
  padding?: string;
  values?: DirtyFormValues;
  onChange?: (values: FormValues, dirtyValues: DirtyFormValues) => void;
}

const FormBuilder = ({
  fieldGroups,
  disableBorder,
  globalDisabled,
  header,
  footer,
  padding,
  values,
  onChange,
}: FormBuilderProps) => {
  const defaultValues = getInitialValues(fieldGroups);
  const schema = useYupSchema(fieldGroups);
  const formMethods = useForm<DirtyFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
    // если поле исчезает из DOM-дерева, то оно автоматически unregister из формы
    shouldUnregister: true,
  });

  const fields: Field[] = useMemo(
    () =>
      fieldGroups.reduce(
        (acc, group) => [...acc, ...group.fields],
        [] as Field[]
      ),
    [fieldGroups]
  );

  const fieldsByName = useMemo(
    () =>
      fields.reduce((acc, field) => {
        if (field.type === 'arrayOf') {
          return {
            ...acc,
            [field.name]: field,
            ...Object.fromEntries(
              field.rowDefinition.map((f) => [`${field.name}.${f.name}`, f])
            ),
          };
        }

        return { ...acc, [field.name]: field };
      }, {}),
    [fields]
  );

  const { control, watch, handleSubmit, reset, getValues, setValue } =
    formMethods;
  const [onSubmitHandlers, setOnSubmitHandlers] =
    useState<FormSubmitHandlers>(null);
  const [firedSubmitAction, setFiredSubmitAction] = useState<string | null>(
    null
  );
  const [selectOptions, setSelectOptions] = useState<SelectOptions>(
    initializeSelectOptions(fields)
  );

  const handleRemoveRow = (arrayOfFieldName: string, index: number) => {
    // Пробегаемся и удаляем все опшины для данной строки со смешением на единицу
    const newSelectOptions = Object.entries(selectOptions).reduce(
      (acc, [fieldName, fieldOptions]) => {
        // Проверка на arrayOf:
        // arrayOf[0].shop -> true
        // shop -> false
        // arrayOf.shop -> false
        const arrayOfDetectRegex = /\[\d+]/g;
        const arrayOfRowIndexDetectRegex = /\d+/g;

        if (arrayOfDetectRegex.test(fieldName)) {
          // Удаляем старые опшины для строки *index
          if (fieldName.includes(`${arrayOfFieldName}[${index}]`)) {
            return acc;
          }

          // Пытаемся найти индекс строки
          const rowIndexMatch = fieldName.match(arrayOfRowIndexDetectRegex);

          // Если нашли
          if (Array.isArray(rowIndexMatch) && rowIndexMatch[0]) {
            const rowIndex = +rowIndexMatch[0];

            // Если индекс строки поля больше, чем индекс строки, которую удалили
            // сдвигаем на единицу
            if (rowIndex > index) {
              const newFieldName = fieldName.replace(
                arrayOfRowIndexDetectRegex,
                `${rowIndex - 1}`
              );
              return {
                ...acc,
                [newFieldName]: fieldOptions,
              };
            }
          }
        }

        return {
          ...acc,
          [fieldName]: fieldOptions,
        };
      },
      {} as SelectOptions
    );

    setSelectOptions(newSelectOptions);
  };

  // Controlled FormBuilder
  useEffect(() => {
    if (values) {
      Object.keys(getValues()).forEach((fieldName) => {
        setValue(
          fieldName,
          values[fieldName] ? values[fieldName] : defaultValues[fieldName]
        );
      });
    }
    // eslint-disable-next-line
  }, [values, setValue, getValues]);

  useEffect(() => {
    if (onChange) {
      const dirtyValues = watch();

      onChange(mapValuesToPlain(dirtyValues, fields), dirtyValues);
    }
  });

  return (
    <FormBuilderContext.Provider
      value={{
        onSubmitHandlers,
        setOnSubmitHandlers,
        firedSubmitAction,
        setFiredSubmitAction,
        fields,
        fieldsByName,
        setSelectOptions,
        selectOptions,
      }}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={(e) => {
            // Останавливает всплытие, чтобы предотвратить триггер
            // валидации родительской формы, в случае вложенности.
            e.preventDefault();
            e.stopPropagation();

            handleSubmit((dirtyValues) => {
              onSubmitHandlers &&
                firedSubmitAction &&
                onSubmitHandlers[firedSubmitAction] &&
                onSubmitHandlers[firedSubmitAction](dirtyValues);
            })(e);
          }}
        >
          {header && header({ reset, defaultValues })}
          {fieldGroups.map(
            (
              {
                label,
                header: formGroupHeader,
                fields: groupFields,
                accordion,
              },
              groupIndex
            ) => {
              const hidden = groupFields.every((f) => f.hidden);

              return (
                <FieldGroupComponent
                  key={`group_${label}_${groupIndex}`}
                  label={label}
                  header={formGroupHeader}
                  padding={padding}
                  disableBorder={disableBorder}
                  accordion={accordion}
                  hidden={hidden}
                >
                  <GridBuilder<Field>
                    markup={groupFields}
                    renderGridItem={(fieldProps) => (
                      <FieldComponent
                        control={control}
                        globalDisabled={globalDisabled}
                        {...fieldProps}
                        // Перезаписываем оригинальное значение поля на пропущенное
                        // через getInitialValues.
                        defaultValue={defaultValues[fieldProps.name]}
                        onRemoveRow={handleRemoveRow}
                      />
                    )}
                  />
                </FieldGroupComponent>
              );
            }
          )}
          {footer && footer({ reset, defaultValues })}
        </form>
      </FormProvider>
    </FormBuilderContext.Provider>
  );
};

export default FormBuilder;
