import React from 'react';
import { FlexibleNumberFormat } from 'services/Main/types.Field';
import { InputProps } from '@material-ui/core/Input';
import InputComponent from '../InputComponent';
import BaseTextField from '../BaseTextField';

interface OtherProps extends Omit<InputProps, 'autoComplete'> {
  InputProps?: Partial<Omit<InputProps, 'autoComplete'>>;
}

const FlexibleNumber = ({
  max,
  min,
  decimalScale,
  decimalSeparator,
  thousandSeparator,
  allowNegative,
  autoComplete,
  ...rest
}: FlexibleNumberFormat & OtherProps) => {
  return (
    <BaseTextField
      {...rest}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          autoComplete: autoComplete || 'off',
          max,
          min,
          decimalScale,
          decimalSeparator,
          thousandSeparator,
          allowNegative,
        },
      }}
    />
  );
};
export default FlexibleNumber;
