import React from 'react';
import { Skeleton } from '@material-ui/lab';
import faker from 'faker';

const TextWidgetSkeleton = (props: any) => {
  return (
    <div
      style={{
        ...props.style,
        width: '100%',
        height: '100%',
        background: '#F5F6F8',
        border: '1px solid #EAEAEA',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: 24,
      }}
      className={props.className}
    >
      <Skeleton
        variant="rect"
        width={`${faker.datatype.number({ min: 30, max: 80 })}%`}
        height={12}
        style={{ marginBottom: 6 }}
        animation={false}
      />
      <Skeleton variant="circle" width={36} height={36} animation={false} />
    </div>
  );
};

export default TextWidgetSkeleton;
