import { useContext, useEffect, useState, useMemo } from 'react';
import { FetchTablePropsResponsePayload } from '../../../../../../services/EntryPicker/EntryPicker.interface';
import { ComponentContext } from '../../../../../creational/ComponentLoader';
import { useSelector } from '../../../../../../store';

export default (
  name: string,
  columns: FetchTablePropsResponsePayload['columns'],
  enabled?: FetchTablePropsResponsePayload['enableColumnReordering']
) => {
  const componentContext = useContext(ComponentContext);
  const user = useSelector((state) => state.user);
  const localStorageKey = `${user?.id}_formId_${componentContext?.id}_entryPickerTableColumnOrderState_${name}`;
  const localStorageJSONState =
    enabled && localStorage.getItem(localStorageKey);
  const localStorageState: string[] | false =
    localStorageJSONState && JSON.parse(localStorageJSONState);

  const defaultColumnOrder = getColumnNames(columns);

  const initialColumnOrder = useMemo(() => {
    if (
      localStorageState &&
      Array.isArray(localStorageState) &&
      localStorageState.length > 0
    ) {
      return localStorageState;
    }

    return defaultColumnOrder;
    // eslint-disable-next-line
  }, [defaultColumnOrder, localStorageState, columns]);

  const [columnOrder, setColumnOrder] = useState<string[]>(initialColumnOrder);

  useEffect(() => {
    enabled &&
      localStorage.setItem(localStorageKey, JSON.stringify(columnOrder));
  }, [columnOrder, enabled, localStorageKey]);

  useEffect(() => {
    setColumnOrder(initialColumnOrder);
    // eslint-disable-next-line
  }, [columns]);

  const resetColumnOrderToDefault = () => {
    setColumnOrder(defaultColumnOrder);
  };

  return {
    columnOrder,
    setColumnOrder,
    resetColumnOrderToDefault,
  };
};

function getColumnNames(columns: FetchTablePropsResponsePayload['columns']) {
  return columns.map((c) => c.name);
}
