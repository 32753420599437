import React, { MouseEventHandler } from 'react';
import { Tooltip } from '@material-ui/core';
import MuiAvatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { STMCell as STMCellProps } from '../../../../../services/Main/types.Component';
import useStyles from './STMChip.styles.v2';
import { printParagraph } from './helpers/printParagraph';
import Icon from '../../../../lowLevel/Icon/Icon';

interface Props {
  value: STMCellProps;
  className?: string;
  onClick?: MouseEventHandler;
}

const STMChipV2 = ({ value, className, onClick }: Props) => {
  const classes = useStyles();

  const { label, avatar, icon, tooltip, color } = value;

  return (
    <span style={{ color }}>
      <Tooltip
        className={classes.tooltipRoot}
        title={<>{tooltip.content.map(printParagraph)}</>}
        enterDelay={250}
        arrow
      >
        <span
          className={clsx(className, classes.chipContainer)}
          onClick={onClick}
        >
          {avatar && (
            <MuiAvatar
              className={classes.avatar}
              style={{ backgroundColor: color }}
              src={avatar.photoSrc}
              alt={`${avatar.firstName} ${avatar.lastName}`}
            >
              {`${avatar.firstName[0]}${avatar.lastName[0]}`}
            </MuiAvatar>
          )}
          <span className={classes.label}>{label}</span>
          {icon && <Icon definition={icon} className={classes.icon} />}
        </span>
      </Tooltip>
    </span>
  );
};

export default STMChipV2;
