import React from 'react';
import { Paragraph } from '../../../../../../services/Main/types.Component';

export function printParagraph({ title, text }: Paragraph) {
  return (
    <div key={`${title}_${text}`}>
      {title ? <b>{`${title}: `}</b> : ''}
      {text}
    </div>
  );
}
