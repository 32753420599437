import { Resolver, FieldValues, transformToNestObject } from 'react-hook-form';
import { ObjectSchema, ValidationError, Lazy } from 'yup';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { ResolverResult } from 'react-hook-form/dist/types/resolvers';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

const parseErrorSchema = (
  error: ValidationError,
  validateAllFieldCriteria: boolean
) => {
  if (!Array.isArray(error.inner) || !error.inner.length) {
    return {
      [error.path]: { message: error.message, type: error.type },
    };
  }

  return error.inner.reduce(
    (previous: Record<string, any>, { path, message, type }) => {
      const previousTypes = (previous[path] && previous[path].types) || {};
      const key = path || type;

      return {
        ...previous,
        ...(key
          ? {
              [key]: {
                ...(previous[key] || {
                  message,
                  type,
                }),
                ...(validateAllFieldCriteria
                  ? {
                      types: {
                        ...previousTypes,
                        [type]: previousTypes[type]
                          ? [...[].concat(previousTypes[type]), message]
                          : message,
                      },
                    }
                  : {}),
              },
            }
          : {}),
      };
    },
    {}
  );
};

type ValidateOptions<T extends ObjectSchema> = Parameters<T['validate']>[1];

export const yupResolver = <FV extends FieldValues>(
  schema: ObjectSchema | Lazy,
  options: ValidateOptions<ObjectSchema> = {
    abortEarly: false,
  }
): Resolver<FV> => {
  const fieldNamesFromSchema = Object.keys(schema.describe().fields);

  return async (formValues, context, validateAllFieldCriteria = true) => {
    return Object.keys(formValues).reduce(
      (acc, fieldName) => {
        // Если поле размонтировано (см. shouldUnregister у useForm rhf), то не валидируем его.
        const shouldValidate = fieldNamesFromSchema.includes(fieldName);

        // Если размонтировано или нет схемы для проверки, то пропускаем
        // валидацию и возвращаем значение
        if (!shouldValidate) {
          return {
            values: {
              ...acc.values,
              [fieldName]: formValues[fieldName],
            },
            errors: acc.errors,
          };
        }

        try {
          // Синхронно валидируем поле, если ошибка, то переходим к блоку catch,
          // иначе возвращаем значение
          schema.validateSyncAt(fieldName, formValues, {
            ...options,
            context,
          });

          return {
            values: {
              ...acc.values,
              [fieldName]: formValues[fieldName],
            },
            errors: acc.errors,
          };
        } catch (e) {
          if (!e.inner) {
            throw e;
          }

          const parsedErrors = parseErrorSchema(e, validateAllFieldCriteria);

          return {
            values: acc.values,
            errors: {
              ...acc.errors,
              ...transformToNestObject(parsedErrors),
            },
          };
        }
      },
      { values: {}, errors: {} } as {
        values: UnpackNestedValue<FV>;
        errors: FieldErrors<FV>;
      }
    ) as ResolverResult<FV>;
  };
};
