import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';
import { IconWithPropsDefinition } from '../../../services/Main/types.common';

export default makeStyles((theme: Theme) =>
  createStyles<string, IconWithPropsDefinition['props']>({
    svg: (props) => {
      const { width, height, stroke, fill, fontSizeInPx } = props;

      return {
        width,
        height,
        stroke,
        fill,
        fontSize: fontSizeInPx
          ? theme.typography.pxToRem(fontSizeInPx)
          : 'inherit',
      };
    },
  })
);
