import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import objectHash from 'object-hash';
import { usePage } from 'utils/hooks/usePage';
import Single from 'components/pageViews/Single';
import Tabs from 'components/pageViews/Tabs';
import NotFoundPage from 'pages/errors/NotFound';
import ForbiddenPage from 'pages/errors/Forbidden';
import PresetPageLoader from 'components/creational/PresetPageLoader';
import {
  FromPresetPage,
  Page,
  SingleComponentPage,
  TabsViewPage,
  PageId,
  WidgetsPage,
  GroupedWidgetsPage,
} from 'services/Main/types.Page';
import { useHideLoadingScreen } from 'utils/hooks/useHideLoadingScreen';
import PageJSONViewer from 'components/lowLevel/PageJSONViewer';
import DialogWithComponent from '../../lowLevel/DialogWithComponent';
import Widgets from '../../pageViews/Widgets';
import RemovedPage from '../../../pages/errors/Removed';
import Breadcrumbs from '../../lowLevel/Breadcrumbs';
import GroupedWidgets from '../../pageViews/GroupedWidgets';
import PageLayout from '../../lowLevel/PageLayout';
import useGlobalFilterCleaner from './useGlobalFilterCleaner';

interface PageLoaderProps {
  id: PageId;
  path: string;
  componentUrlPrefix?: string;
  method?: 'GET' | 'POST';
}

export interface PageContext {
  setPage: Dispatch<SetStateAction<Page | null>>;
  reload: () => void;
  pageLoaderProps: PageLoaderProps;
}

export const PageContext = createContext<PageContext | undefined>(undefined);

/**
 * После подключения этого компонента происходит запрос на сервер
 * для получения JSON-структуры с информацией о данных страницы.
 *
 * В зависимости от типа страницы, подключится нужный вид представления.
 *
 * TODO сделать валидацию данных с сервера, для отлова ошибок.
 */
const PageLoader = (pageLoaderProps: PageLoaderProps) => {
  const { id, path, componentUrlPrefix, method } = pageLoaderProps;

  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const params = useParams();

  const [page, setPage] = usePage(
    id,
    path,
    params,
    triggerUpdate,
    componentUrlPrefix,
    method
  );

  // Метод, который будет доступен в контексте.
  // Нужен для обновления данных страницы по запросу.
  const reload = () => {
    setTriggerUpdate((prevState) => !prevState);
  };

  useHideLoadingScreen(page, page ? !!page.type : false);

  useGlobalFilterCleaner();

  if (!page) return <LinearProgress />;

  const { type, ...props } = page;

  const pageComponent = (() => {
    switch (type) {
      case 'singleComponent':
        return <Single {...(props as SingleComponentPage)} />;
      case 'tabsView':
        return <Tabs key={objectHash(page)} {...(props as TabsViewPage)} />;
      case 'preset':
        return <PresetPageLoader {...(props as FromPresetPage)} />;
      case 'grid':
        return <Widgets {...(props as WidgetsPage)} />;
      case 'gridWithGroups':
        return <GroupedWidgets {...(props as GroupedWidgetsPage)} />;
      case 'forbiddenPage':
        return <ForbiddenPage />;
      case 'removedPage':
        return <RemovedPage />;
      case 'notFound':
      default:
        return <NotFoundPage />;
    }
  })();

  return (
    <PageContext.Provider value={{ reload, setPage, pageLoaderProps }}>
      <PageJSONViewer page={page} />
      <DialogWithComponent />
      <PageLayout sidebar={'sidebar' in page ? page?.sidebar : undefined}>
        {'breadcrumbs' in page && page?.breadcrumbs && (
          <Breadcrumbs breadcrumbs={page.breadcrumbs} />
        )}
        {pageComponent}
      </PageLayout>
    </PageContext.Provider>
  );
};

export default PageLoader;
