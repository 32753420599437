import { DirtyFormValues, FilterChip } from 'services/Main/types.Component';
import { Field, SelectOption, DateRangeValue } from 'services/Main/types.Field';
import { formatDateFromISOstring } from 'utils/formatDate';
import { FormatMessage } from '../../../locale/hooks';

function createGetDateRangePrintValue(formatMessage: FormatMessage) {
  const fromMessage = formatMessage('from');
  const toMessage = formatMessage('to');

  return ({ from, to }: DateRangeValue, formatAs?: 'date' | 'dateTime') => {
    if (!from && !to) return '';

    if (from && !to)
      return `${fromMessage} ${formatDateFromISOstring(from, formatAs)}`;
    if (!from && to)
      return `${toMessage} ${formatDateFromISOstring(to, formatAs)}`;

    return `${fromMessage} ${formatDateFromISOstring(
      from as string,
      formatAs
    )} ${toMessage.toLowerCase()} ${formatDateFromISOstring(
      to as string,
      formatAs
    )}`;
  };
}

export default (formatMessage: FormatMessage) => {
  const getDateRangePrintValue = createGetDateRangePrintValue(formatMessage);

  return (fields?: Field[], dirtyValues?: DirtyFormValues): FilterChip[] => {
    if (!fields || !dirtyValues) return [];

    const chips: FilterChip[] = [];

    fields.forEach((field) => {
      if (
        !dirtyValues[field.name] ||
        // TODO: Сейчас просто не добавляю чипсы полей с disableClearable: true в стейт
        ('disableClearable' in field && field.disableClearable)
      )
        return;

      if (field.type === 'datePicker') {
        if (field.pickerType === 'date') {
          chips.push({
            fieldName: field.name,
            dirtyValue: dirtyValues[field.name],
            printValue: `${field.label}: ${formatDateFromISOstring(
              dirtyValues[field.name] as string,
              'date'
            )}`,
          });
          return;
        }
        if (field.pickerType === 'dateTime') {
          chips.push({
            fieldName: field.name,
            dirtyValue: dirtyValues[field.name],
            printValue: `${field.label}: ${formatDateFromISOstring(
              dirtyValues[field.name] as string,
              'dateTime'
            )}`,
          });
          return;
        }
        if (field.pickerType === 'dateRange') {
          if (
            !(dirtyValues[field.name] as any).from &&
            !(dirtyValues[field.name] as any).to
          )
            return;

          chips.push({
            fieldName: field.name,
            dirtyValue: dirtyValues[field.name],
            printValue: `${field.label}: ${getDateRangePrintValue(
              dirtyValues[field.name] as DateRangeValue,
              'date'
            )}`,
          });
          return;
        }
        if (field.pickerType === 'dateTimeRange') {
          if (
            !(dirtyValues[field.name] as any).from &&
            !(dirtyValues[field.name] as any).to
          )
            return;

          chips.push({
            fieldName: field.name,
            dirtyValue: dirtyValues[field.name],
            printValue: `${field.label}: ${getDateRangePrintValue(
              dirtyValues[field.name] as DateRangeValue,
              'dateTime'
            )}`,
          });
          return;
        }
      }

      if (field.type === 'select') {
        if (field.selectType === 'single') {
          chips.push({
            fieldName: field.name,
            dirtyValue: dirtyValues[field.name],
            printValue: `${field.label}: ${
              (dirtyValues[field.name] as SelectOption).label
            }`,
          });
          return;
        }
        if (field.selectType === 'multi') {
          if ((dirtyValues[field.name] as SelectOption[]).length === 0) return;

          (dirtyValues[field.name] as SelectOption[]).forEach(
            (option, index) => {
              chips.push({
                fieldName: field.name,
                dirtyValue: (dirtyValues[field.name] as SelectOption[])[index],
                printValue: `${field.label}: ${option.label}`,
              });
            }
          );
          return;
        }
      }

      if (field.type === 'comboBox') {
        (dirtyValues[field.name] as SelectOption[]).forEach((option, index) => {
          chips.push({
            fieldName: field.name,
            dirtyValue: (dirtyValues[field.name] as SelectOption[])[index],
            printValue: `${field.label}: ${option.label}`,
          });
        });
        return;
      }

      chips.push({
        fieldName: field.name,
        dirtyValue: dirtyValues[field.name],
        printValue: `${field.label}: ${dirtyValues[field.name]}`,
      });
    });

    return chips;
  };
};
