import { useIntl } from 'react-intl';
import { LocalizationDictionary } from './dictionaries/types';

export type FormatMessage = (
  key: keyof LocalizationDictionary,
  values?: Record<string, string | number | boolean | null | undefined | Date>
) => string;

export function useFormatMessage(): FormatMessage {
  const { formatMessage } = useIntl();

  return (key, values) => formatMessage({ id: key }, values);
}
