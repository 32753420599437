import LogBase from './Log.base';

let LogService: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  LogService = require('./Log.local').default;
} else {
  LogService = require('./Log.remote').default;
}

const logService = new LogService() as LogBase;

export default logService;
