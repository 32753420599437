import React from 'react';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useStyles from './TimelineScrollToBottom.styles';
import useShouldDisplay from './useShouldDisplay';
import useTimelineContext from '../../hooks/useTimelineContext';

const TimelineScrollToBottom = () => {
  const classes = useStyles();
  const { timelineScrollManager } = useTimelineContext();
  const { shouldDisplay, hasUnreadMessage } = useShouldDisplay();

  const handleClick = () => {
    timelineScrollManager?.current?.smoothScrollToBottom();
  };

  return (
    <Fade in={shouldDisplay}>
      <div className={classes.timelineScrollToBottomContainer}>
        <Fab
          className={classes.timelineScrollToBottomButton}
          color="primary"
          onClick={handleClick}
        >
          {hasUnreadMessage && <div className="hasUnreadMessage" />}
          <KeyboardArrowDownIcon />
        </Fab>
      </div>
    </Fade>
  );
};

export default TimelineScrollToBottom;
