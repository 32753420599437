import React, { useContext, useState } from 'react';
import MuiAutocomplete, {
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { useFormatMessage } from '../../../../../locale';
import {
  AsyncFlatComboBoxSingle,
  ComboBoxOption,
  FlatComboBoxSingle as FlatComboBoxSingleProps,
  SelectOption,
} from '../../../../../services/Main/types.Field';
import getLocalizedProperties from '../../helpers/getLocalizedProperties';
import createRenderInput from '../../helpers/createRenderInput';
import renderTags from '../../helpers/renderTags';
import { FlatComboBoxProps } from '../../types';
import { handleFilterOptions, renderOption } from './flatHelpers';
import useStyles from './FlatComboBox.styles';
import FormBuilderContext from '../../../FormBuilder/FormBuilderContext';
import { ControllerProps } from '../../../../../utils/typeUtils';
import mainService from '../../../../../services/Main';

type Props = (FlatComboBoxSingleProps | AsyncFlatComboBoxSingle) &
  ControllerProps<ComboBoxOption> & { options: ComboBoxOption[] };

const FlatComboBoxSingle = (props: Props) => {
  const classes = useStyles();
  const {
    name,
    disabled,
    options,
    value: initialValue,
    onChange,
    disableClearable,
    optionsLoader,
    chipTagViewStyle,
  } = props;
  const formatMessage = useFormatMessage();

  const [isOptionsFetched, setIsOptionsFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setSelectOptions } = useContext(FormBuilderContext);

  const value = initialValue ? [initialValue] : [];

  const handleChange = (
    event: any,
    changedValue: ComboBoxOption[],
    reason: AutocompleteChangeReason
  ) => {
    if (
      disabled ||
      // Отключаем возможность снять выбранный опшин при disableClearable
      (disableClearable && reason === 'remove-option')
    ) {
      return;
    }

    let newOptions = changedValue;

    if (newOptions && newOptions?.length > 1) {
      newOptions = newOptions?.slice(-1);
    }

    onChange(newOptions[0] ?? null);
  };

  const handleOpen = () => {
    if (optionsLoader === 'async') {
      const { searchOptionsRequestConfig } = props as AsyncFlatComboBoxSingle;

      if (isOptionsFetched) return;

      setIsLoading(true);
      mainService
        .makeActionRequest<SelectOption[]>(searchOptionsRequestConfig)
        .then(({ payload: newOptions }) => {
          setIsLoading(false);

          setSelectOptions((prevState) => ({
            ...prevState,
            [name]: newOptions,
          }));
          setIsOptionsFetched(true);
        });
    }
  };

  return (
    <MuiAutocomplete
      {...getLocalizedProperties(formatMessage)}
      classes={{
        root: clsx(classes.root, { disabled }),
        inputRoot: clsx(classes.inputRoot, { disableClearable }),
        input: classes.input,
        loading: 'animate-flicker',
      }}
      multiple
      disabled={disabled}
      disableClearable={disabled || disableClearable}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      filterOptions={handleFilterOptions}
      onChange={handleChange}
      onOpen={handleOpen}
      value={value}
      loading={isLoading}
      renderInput={createRenderInput(props as FlatComboBoxProps)}
      renderTags={renderTags({
        multiline: (props as { multiline?: boolean })?.multiline,
        viewStyle: chipTagViewStyle,
        disableClearable,
        disabled,
      })}
      renderOption={renderOption}
    />
  );
};

export default FlatComboBoxSingle;
