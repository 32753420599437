import React from 'react';
import { SelectOption } from 'services/Main/types.Field';
import useStyles from '../../Select.styles';

interface RenderTagsProps {
  tagValue: SelectOption[];
}

const Tags = ({ tagValue }: RenderTagsProps) => {
  const classes = useStyles()();
  const tags = tagValue.map((v) => v.label).join(', ');

  return <div className={classes.tags}>{tags}</div>;
};
export default Tags;
