import React, { PropsWithChildren, MouseEvent } from 'react';
import MuiLink from '@material-ui/core/Link';
import { Link as RouterLink, NavLink } from 'react-router-dom';

interface LinkProps {
  to: string;
  label?: string;
  target?: '_blank';
  className?: string;
  useNavLink?: boolean;
  tabIndex?: number;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

/**
 * Компонент для комбинирования react-router и material-ui
 */
const Link = ({
  to,
  label,
  target,
  className,
  useNavLink,
  children,
  tabIndex = -1,
  onClick,
}: PropsWithChildren<LinkProps>) => {
  return (
    <MuiLink
      component={useNavLink ? NavLink : RouterLink}
      to={to}
      target={target}
      className={className}
      tabIndex={tabIndex}
      exact={useNavLink}
      onClick={onClick}
    >
      {label}
      {children}
    </MuiLink>
  );
};

export default Link;
