import { useContext } from 'react';
import { TimelineContext } from '../TimelineContext';

export default function useTimelineContext() {
  const timelineContext = useContext(TimelineContext);

  if (!timelineContext) {
    throw new Error('TimelineContext was not initialized!');
  }

  return timelineContext;
}
