import { TextFileTypes } from '../../../../lowLevel/FileUpload/FileViewer/plugins/TextRenderer/TextRenderer';
import { PDFFileTypes } from '../../../../lowLevel/FileUpload/FileViewer/plugins/PDFRenderer/PDFRenderer';
import { ImageFileTypes } from '../../../../lowLevel/FileUpload/FileViewer/plugins/ImageRenderer/ImageRenderer';
import { TiffFileTypes } from '../../../../lowLevel/FileUpload/FileViewer/plugins/TiffRenderer/TiffRenderer';
import { MicrosoftFileTypes } from '../../../../lowLevel/FileUpload/FileViewer/plugins/MicrosoftRenderer/MicrosoftRenderer';

type FileTypes = typeof ImageFileTypes &
  typeof MicrosoftFileTypes &
  typeof PDFFileTypes &
  typeof TextFileTypes &
  typeof TiffFileTypes;

const fileTypes: FileTypes = {
  ...ImageFileTypes,
  ...MicrosoftFileTypes,
  ...PDFFileTypes,
  ...TextFileTypes,
  ...TiffFileTypes,
};

function getFileType(mimeType: string) {
  for (const key in fileTypes) {
    if (fileTypes[key as keyof FileTypes].includes(mimeType)) {
      return key;
    }
  }

  return null;
}

export default getFileType;
