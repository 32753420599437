import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => {
  return createStyles({
    mainMenu: {
      '&.compactView > .hidden-compactView': {
        display: 'none',
      },
    },
  });
});
