import React, { PropsWithChildren } from 'react';
import { TableCell } from '../../../../../../services/Main/types.Component';
import DraggableItem from '../DraggableItem';
import DroppableContainer from '../DroppableContainer';
import LoadingCellIndicator from '../LoadingCellIndicator';

interface DragAndDropCellContainerProps {
  id: string;
  rowId: string;
  columnName: string;
  value: TableCell;
}

const DragAndDropCellContainer = ({
  id,
  value,
  rowId,
  columnName,
  children,
}: PropsWithChildren<DragAndDropCellContainerProps>) => {
  return (
    <>
      <LoadingCellIndicator id={id} />
      <DraggableItem
        id={id}
        rowId={rowId}
        columnName={columnName}
        value={value}
      >
        {children}
      </DraggableItem>
      <DroppableContainer
        id={id}
        rowId={rowId}
        columnName={columnName}
        value={value}
      />
    </>
  );
};

export default DragAndDropCellContainer;
