import React, { useMemo } from 'react';
import { useSelector } from 'store';
import MainMenu from './MainMenu';
import { MenuItem } from '../../../../services/Main/types';

const MainMenuContainer = () => {
  const allMenuItems = useSelector((state) => state.mainMenu);

  // Фильтруем все пункты меню, оставляя только модули и их прямые дочерние
  // элементы (0 и 1 уровни вложенности, без подменю).
  const menuItems = useMemo(
    () =>
      allMenuItems
        ?.filter((item) => item.type === 'module')
        .reduce((acc, moduleMenuItem) => {
          const moduleMenuItems = allMenuItems.filter(
            (item) =>
              item.type !== 'module' && item.parentId === moduleMenuItem.id
          );

          return [...acc, moduleMenuItem, ...moduleMenuItems];
        }, [] as MenuItem[]),
    [allMenuItems]
  );

  if (!menuItems) return null;

  return <MainMenu items={menuItems} />;
};

export default MainMenuContainer;
