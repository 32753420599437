import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from 'theme';
import { HighlightColor } from 'services/Main/types.Field';

export default (highlightColor?: HighlightColor, hasStartAdornment?: boolean) =>
  makeStyles((theme: Theme) => {
    const highlight = highlightColor
      ? theme.palette[highlightColor].main
      : 'rgba(0, 0, 0, 0.54)';

    const secondHighlight = highlightColor
      ? theme.palette[highlightColor].main
      : 'rgb(148, 148, 148)';

    const hoverColor = highlightColor
      ? theme.palette[highlightColor].main
      : 'rgb(30, 30, 30)';

    return createStyles({
      autocomplete: {
        '& .MuiInputBase-root': {
          display: 'inline-flex',
          flexWrap: 'nowrap',
          '& input': {
            display: 'flex',
          },
        },
      },
      tags: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingTop: 6,
        paddingBottom: 6,
      },
      textField: {
        '& .MuiInput-underline:not(.Mui-disabled):before': {
          borderBottomColor: secondHighlight,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottomColor: hoverColor,
        },
        '& .MuiFormLabel-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)':
          {
            color: highlight,
          },
        '& .MuiInput-root': {
          flexWrap: 'nowrap',
        },
      },
      inputRoot: {
        '&[class*="MuiInput-root"]': {
          paddingTop: 5,
          paddingBottom: 6,
          '& $input, $input:first-child': {
            // Дефолтный padding для input[type="text" i]
            padding: '1px 2px',
          },
        },
      },
      /* Styles applied to the input element. */
      input: {},
      autocompleteRoot: {
        '& .MuiIconButton-root:not(.Mui-disabled):hover': {
          backgroundColor: highlightColor
            ? fade(theme.palette[highlightColor].main, 0.1)
            : 'rgba(16, 156, 241, 0.1)',
        },
        '& .MuiAutocomplete-clearIndicatorDirty:not(.Mui-disabled)': {
          color: highlight,
        },
        '& .MuiAutocomplete-popupIndicator:not(.Mui-disabled)': {
          color: highlight,
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input':
          hasStartAdornment
            ? {
                padding: '6px 4px',
              }
            : {},

        '&.warning': {
          '& .MuiFormLabel-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)':
            {
              color: theme.palette.warning.main,
            },
          '& .MuiFormHelperText-root': {
            color: theme.palette.warning.dark,
          },

          '& .MuiInput-underline:not(.Mui-disabled):before': {
            borderBottomColor: theme.palette.warning.main,
          },
        },
      },
      option: {
        '&.disabledAndSelected': {
          color: theme.palette.text.disabled,
        },
      },
      chip: {
        backgroundColor: highlightColor
          ? fade(theme.palette[highlightColor].main, 0.3)
          : '#e0e0e0 ',
      },
      link: { display: 'flex' },
      hrefIcon: {
        alignItems: 'center',
      },
    });
  });
