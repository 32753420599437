import React from 'react';
import { FieldProps } from './types';
import Field from './Field';
import useShouldRender from './useShouldRender';
import { FIELD_CSS_NAME } from '../../../commonConstants';

export default (props: FieldProps) => {
  const shouldRender = useShouldRender(props.renderCondition);

  if (!shouldRender) return null;

  return (
    <div className={FIELD_CSS_NAME}>
      <Field {...props} />
    </div>
  );
};
