import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

const SIZE = 5;

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(SIZE),
      height: theme.spacing(SIZE),
    },
    anchorOriginTopRightRectangle: {
      // Уменьшаем размер индикации на 15%, поскольку дефолтное слишком большое.
      transform: 'scale(0.85) translate(50%, -50%)',
    },
  })
);
