import React from 'react';
import TextField from 'components/lowLevel/TextField';
import SelectComponent from 'components/lowLevel/Select';
import DatePicker, { DatePickerProps } from 'components/lowLevel/DatePicker';
import FormattedNumber from 'components/lowLevel/FormattedNumber';
import FileUpload from 'components/lowLevel/FileUpload';
import RadioGroup from 'components/lowLevel/RadioGroup';
import Checkbox from 'components/lowLevel/Checkbox';
import {
  Text,
  Select,
  FormattedNumber as FormattedNumberProps,
  FileUpload as FileUploadProps,
  RadioGroup as RadioGroupProps,
  Checkbox as CheckboxProps,
  ArrayOf as ArrayOfProps,
  ComboBox as ComboBoxProps,
  EntryPicker as EntryPickerProps,
  Rating as RatingProps,
} from 'services/Main/types.Field';
import ArrayOf from '../ArrayOf';
import getFormControlLabel from '../../../utils/getFormControlLabel';
import { FieldProps } from './types';
import ComboBox from '../ComboBox';
import Controller from './Controller';
import EntryPicker from '../EntryPicker';
import RatingField from '../RatingField';

export default ({
  label: propsLabel,
  name,
  disabled,
  globalDisabled,
  defaultValue,
  control,
  type,
  onRemoveRow,
  onChange,
  ...rest
}: FieldProps) => {
  let controllerProps: any;

  const label = getFormControlLabel(
    propsLabel,
    rest?.validationConfig?.validations
  );

  // Мапим пропы в будущие компоненты форм-контролы
  switch (type) {
    case 'arrayOf':
      return (
        <ArrayOf
          {...(rest as ArrayOfProps)}
          label={label}
          name={name}
          disabled={globalDisabled || disabled}
          defaultValue={defaultValue}
          type={type}
          control={control}
          onRemoveRow={onRemoveRow}
        />
      );
    case 'select':
      controllerProps = {
        as: SelectComponent,
        ...(rest as Select),
      };
      break;
    case 'datePicker':
      controllerProps = {
        as: DatePicker,
        ...(rest as DatePickerProps),
      };
      break;
    case 'formattedNumber':
      controllerProps = {
        as: FormattedNumber,
        ...(rest as FormattedNumberProps),
      };
      break;
    case 'file':
      controllerProps = {
        as: FileUpload,
        ...(rest as FileUploadProps),
      };
      break;
    case 'radio':
      controllerProps = {
        as: RadioGroup,
        ...(rest as RadioGroupProps),
      };
      break;
    case 'checkbox':
      controllerProps = {
        as: Checkbox,
        ...(rest as CheckboxProps),
      };
      break;
    case 'comboBox':
      controllerProps = {
        as: ComboBox,
        ...(rest as ComboBoxProps),
      };
      break;
    case 'entryPicker':
      controllerProps = {
        as: EntryPicker,
        ...(rest as EntryPickerProps),
      };
      break;
    case 'rating':
      controllerProps = {
        as: RatingField,
        ...(rest as RatingProps),
      };
      break;
    case 'text':
    default:
      controllerProps = {
        as: TextField,
        ...(rest as Text),
        // rules: { required: true },
      };
      break;
  }

  return (
    <Controller
      {...controllerProps}
      onChange={onChange}
      label={label}
      disabled={globalDisabled || disabled}
      fullWidth
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};
