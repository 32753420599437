import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alert } from '../../services/Main/types.Component';

export type AlertsFromActionServerResponseState = Record<string, Alert[]>;

export const { reducer, actions } = createSlice({
  name: 'alertsFromActionServerResponse',
  initialState: {} as AlertsFromActionServerResponseState,
  reducers: {
    setForPage: (
      state,
      { payload }: PayloadAction<{ id: string; alerts: Alert[] }>
    ) => {
      state[payload.id] = payload.alerts;
    },
    clear: (state, { payload }: PayloadAction<string>) => {
      delete state[payload];
    },
  },
});

export default reducer;
