import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    headingRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    preHeadingIcon: {
      lineHeight: 1,
      marginRight: theme.spacing(0.5),
    },
    headingMainPart: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      overflow: 'hidden',
    },
    preHeadingText: {
      float: 'left',
      fontSize: theme.typography.pxToRem(12),
      opacity: 0.7,
      fontWeight: 500,
      marginRight: theme.spacing(1),
    },
    subHeadingRoot: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    subHeading: {
      display: 'flex',
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    subHeadingKey: {
      marginRight: theme.spacing(0.5),
      opacity: 0.5,
      fontSize: '0.75rem',
    },
    subHeadingValue: {
      opacity: 0.7,
      fontSize: '0.75rem',
      fontWeight: 500,
    },
  })
);
