import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { IconDefinition } from '../../../services/Main/types.common';
import Icon from '../Icon/Icon';
import useStyles from './IconWithTooltip.styles';

interface IconWithTooltipProps {
  definition: IconDefinition;
  tooltipText: string;
}

const IconWithTooltip = ({ definition, tooltipText }: IconWithTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipText} enterTouchDelay={50} arrow>
      <div className={classes.root}>
        <Icon definition={definition} />
      </div>
    </Tooltip>
  );
};

export default IconWithTooltip;
