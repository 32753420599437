import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    item: {
      display: 'flex',
      textTransform: 'unset',
      minWidth: theme.spacing(3),
      maxWidth: 460,
    },
    label: {
      hyphens: 'auto',
      textAlign: 'left',
      // Визуальная компенсация
      marginTop: 1,
      lineHeight: 1,
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    startIcon: {
      '&.noLabel': {
        marginRight: 0,
        marginLeft: -2,
      },
    },
  })
);
