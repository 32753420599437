import React from 'react';
import useStyles from './OtherUserTimelineElement.styles';
import { OtherUserTimelineElement } from '../../../../../../services/Main/types.Component';
import Bubble from '../../Bubble';
import MessageInfo from '../../MessageInfo';
import MessageBody from '../../MessageBody';
import MessageAttachments from '../../MessageAttachments';
import MessageHeader from '../../MessageHeader';
import MessageContent from '../../MessageContent';
import MessageQuote from '../../MessageQuote';
import getAuthorFullName from '../helpers/getAuthorFullName';
import getAuthorColor from '../helpers/getAuthorColor';
import OtherUserTimelineElementAvatar from './OtherUserTimelineElementAvatar';

export default (props: OtherUserTimelineElement) => {
  const classes = useStyles();

  const {
    author,
    isPrivate,
    isAddedManually,
    createdAt,
    body,
    attachments,
    quote,
  } = props;

  const authorFullName = getAuthorFullName(props);
  const color = getAuthorColor(props);

  return (
    <Bubble side="left" className={classes.bubble}>
      <MessageContent>
        <MessageHeader
          avatar={
            <OtherUserTimelineElementAvatar author={author} color={color} />
          }
          color={color}
          title={authorFullName}
          subheader={
            <MessageInfo
              isPrivate={isPrivate}
              createdAt={createdAt}
              isAddedManually={isAddedManually}
            />
          }
        />
        {quote && <MessageQuote {...quote} />}
        <MessageBody {...body} />
      </MessageContent>
      <MessageAttachments attachments={attachments} />
    </Bubble>
  );
};
