import React, { useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { YearPicker } from 'services/Main/types.Field';
import { YEAR_FORMAT } from 'commonConstants';
import isValidDate from 'utils/isValidDate';
import { formatDate } from 'utils/formatDate';
import {
  getComparisonMaxDate,
  getComparisonMinDate,
  getDate,
  isAfterOrEqual,
  isBeforeOrEqual,
} from '../../helpers';
import { useFormatMessage } from '../../../../../locale';
import useStyles from '../../DatePicker.styles';

type DateProps = YearPicker & {
  value?: any;
  onChange?: any;
};

const emptyInputValue = '____';

/**
 * Доступные кнопкм:
 * - Очистить. поведение - очищает значение поля, закрывает модалку
 * - Отменить. переименовать в 'закрыть', поведение - просто закрывает диалог
 * - Сегодня. Убрать
 * - Ок. Убрать, поскольку включен autoOk
 */
export default ({
  disabled,
  value,
  onChange,
  initialFocusedDate,
  label,
  minDate: minDateFromProps,
  maxDate: maxDateFromProps,
  helperText,
  name,
  autoComplete,
  disableClearable,
}: DateProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | null>(value);
  const { errors } = useFormContext();
  const [internalError, setInternalError] = useState<string | null>(null);
  const error = get(errors, name);

  const minDate = getDate(minDateFromProps, new Date(0));
  const maxDate = getDate(maxDateFromProps);

  const clearable = disableClearable !== true;

  const handleChange = (
    _date: MaterialUiPickersDate,
    newInputValue?: string | null
  ) => {
    setInputValue(newInputValue ?? emptyInputValue);

    if (!newInputValue) {
      return;
    }

    const date = parse(newInputValue, YEAR_FORMAT, new Date());

    if (!isValidDate(date) && value && !disableClearable) {
      onChange(null);
    }

    if (newInputValue.includes('_') || !isValidDate(date)) return;

    if (minDate && !isAfterOrEqual(date, getComparisonMinDate(minDate))) {
      setInternalError(
        formatMessage('dateCannotBeLessThan', {
          date: format(minDate, YEAR_FORMAT),
        })
      );
      return;
    }

    if (maxDate && !isBeforeOrEqual(date, getComparisonMaxDate(maxDate))) {
      setInternalError(
        formatMessage('dateCannotBeGreaterThan', {
          date: format(maxDate, YEAR_FORMAT),
        })
      );
      return;
    }

    setInternalError(null);
    onChange(format(date, YEAR_FORMAT));
  };

  const handleBlur = () => {
    // Если пользователь стер все значения в поле, то после снятия фокуса
    // очищаем inputValue и очищаем ошибки
    if (!inputValue || inputValue === emptyInputValue) {
      setInputValue(null);
      setInternalError(null);
      return;
    }

    // Если после снятия фокуса в inputValue есть '_' (пустые значения) и нет
    // значения в RHF, то устанавливаем ошибку enterTheCorrectDateFormat
    if (inputValue.includes('_') && !value) {
      setInternalError(
        formatMessage('enterTheCorrectDateFormat', {
          date: formatDate(new Date(), 'year'),
        })
      );
    }

    // Актуализируем значения inputValue, если у поля нет ошибок и есть value
    if (!internalError && value) {
      setInputValue(value);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClearButtonClick = () => {
    onChange(null);
    setInputValue(null);
    handleClose();
  };

  return (
    <KeyboardDatePicker
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      autoOk
      fullWidth
      label={label}
      // Если поле можно очистить и в нем есть значение - отображаем кнопку
      clearable={clearable && !!value}
      minDate={minDate}
      maxDate={maxDate ?? undefined}
      name={name}
      value={value && new Date(value, 0, 1)}
      inputValue={value && !inputValue?.includes('_') ? value : inputValue}
      views={['year']}
      KeyboardButtonProps={{
        className: clsx(classes.keyboardButton, { disabled }),
      }}
      // onClear по типизации нет, но он есть. Отвечает за кнопку "Очистить"
      // @ts-ignore
      onClear={handleClearButtonClick}
      onBlur={handleBlur}
      helperText={error?.message || internalError || helperText}
      error={!!error}
      onChange={handleChange}
      initialFocusedDate={getDate(initialFocusedDate, new Date())}
      format={YEAR_FORMAT}
      disabled={disabled}
      inputProps={{
        autoComplete: autoComplete || 'off',
      }}
      clearLabel={formatMessage('clear')}
      cancelLabel={formatMessage('close')}
      // Убрана поскольку включен autoOk
      okLabel={null}
    />
  );
};
