import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import store, { useSelector } from 'store';
import useStyles from './Alerts.styles';
import { Alert as AlertProps } from '../../../services/Main/types.Component';
import Alert from '../Alert';
import { alertsFromActionServerResponseActions } from '../../../store/alertsFromActionServerResponse';
import { ComponentContext } from '../../creational/ComponentLoader';

interface AlertsProps {
  staticAlerts?: AlertProps[];
}

const Alerts = ({ staticAlerts }: AlertsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const componentContext = useContext(ComponentContext);
  const alertsId = `${componentContext.businessComponentId}_${componentContext.id}`;
  const alertsFromActionServerResponse = useSelector(
    (state) => state.alertsFromActionServerResponse[alertsId]
  );

  const allAlerts = [
    ...(alertsFromActionServerResponse || []),
    ...(staticAlerts || []),
  ];

  // При componentWillUnmount очищаем стор с алертами
  useEffect(() => {
    return () => {
      if (
        store.getState().alertsFromActionServerResponse[alertsId]?.length > 0
      ) {
        dispatch(alertsFromActionServerResponseActions.clear(alertsId));
      }
    };
    // eslint-disable-next-line
  }, []);

  if (allAlerts.length === 0) return null;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container className={classes.alertsContainer}>
        {allAlerts.map((alert, index) => (
          <Grid item xs={12} className={classes.alert} key={`alert_${index}`}>
            <Alert {...alert} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Alerts;
