import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: 0,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      whiteSpace: 'normal',
      lineHeight: 1.2,
      fontSize: '0.85rem',
    },
  })
);
