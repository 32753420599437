import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import mainService from 'services/Main';
import {
  SelectOption,
  AsyncMultiSelect as AsyncMultiSelectProps,
} from 'services/Main/types.Field';
import MultiSelectInput from '../components/MultiSelectInput';
import Tags from '../components/Tags';
import useStyles from '../Select.styles';
import { useFormatMessage } from '../../../../locale';

interface OtherProps {
  value?: any;
  onChange?: any;
}

export default ({
  disabled,
  label,
  searchOptionsRequestConfig,
  value,
  helperText,
  name,
  onChange,
  highlightColor,
}: AsyncMultiSelectProps & OtherProps) => {
  const classes = useStyles(highlightColor)();
  const formatMessage = useFormatMessage();
  const { errors } = useFormContext();
  const error = get(errors, name);
  const [isOptionsFetched, setIsOptionsFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const handleOpen = () => {
    if (isOptionsFetched) return;

    setIsLoading(true);
    mainService
      .makeActionRequest<SelectOption[]>(searchOptionsRequestConfig)
      .then(({ payload: newOptions }) => {
        setOptions(newOptions);
        setIsLoading(false);
        setIsOptionsFetched(true);
      });
  };

  const handleChange = (event: any, values: any) => {
    onChange(values);
  };

  return (
    <Autocomplete
      className={classes.autocompleteRoot}
      disableCloseOnSelect
      multiple
      loading={isLoading}
      loadingText={formatMessage('loading')}
      noOptionsText={formatMessage('noOptions')}
      clearText={formatMessage('clear')}
      closeText={formatMessage('close')}
      openText={formatMessage('open')}
      classes={{
        loading: 'animate-flicker',
      }}
      onOpen={handleOpen}
      disabled={disabled}
      value={value || []}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      getOptionDisabled={(o) => o.disabled}
      options={options}
      autoComplete
      includeInputInList
      onChange={handleChange}
      renderInput={(params) => (
        <MultiSelectInput
          params={params}
          value={value}
          label={label}
          name={name}
          helperText={error?.message || helperText}
          error={error}
          highlightColor={highlightColor}
        />
      )}
      renderTags={(tagValue) => <Tags tagValue={tagValue} />}
    />
  );
};
