import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

const SIZE = 4;

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      color: theme.palette.divider,
      borderColor: theme.palette.divider,
      width: theme.spacing(SIZE),
      height: theme.spacing(SIZE),
      lineHeight: theme.spacing(SIZE),
      fontSize: theme.typography.pxToRem(14),
    },
  })
);
