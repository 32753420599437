import React from 'react';
import { LegendProps } from 'recharts';
import useStyles from './ChartsLegend.styles';
import { adaptEventsOfChild } from './helpers';

const ChartsLegend = (props: LegendProps) => {
  const classes = useStyles();

  const { payload } = props;

  if (!payload?.length) return null;

  return (
    <div className={classes.root}>
      {payload.map((entry, i) => {
        return (
          <div
            className={classes.item}
            key={`${entry.value}_${i}`}
            {...adaptEventsOfChild(props, entry, i)}
          >
            <span
              className={classes.badge}
              style={{ background: entry.color }}
            />
            {entry.value}
          </div>
        );
      })}
    </div>
  );
};

export default ChartsLegend;
