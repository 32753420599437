import React, { useContext, useState } from 'react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { useFormatMessage } from '../../../../../locale';
import {
  AsyncFlatComboBoxMultiple,
  ComboBoxOption,
  FlatComboBoxMultiple as FlatComboBoxMultipleProps,
  SelectOption,
} from '../../../../../services/Main/types.Field';
import getLocalizedProperties from '../../helpers/getLocalizedProperties';
import createRenderInput from '../../helpers/createRenderInput';
import renderTags from '../../helpers/renderTags';
import { FlatComboBoxProps } from '../../types';
import { handleFilterOptions, renderOption } from './flatHelpers';
import useStyles from './FlatComboBox.styles';
import mainService from '../../../../../services/Main';
import FormBuilderContext from '../../../FormBuilder/FormBuilderContext';
import { ControllerProps } from '../../../../../utils/typeUtils';
import TreeCheckboxButton from '../TreeComboBox/TreeCheckboxButton';

type Props = (FlatComboBoxMultipleProps | AsyncFlatComboBoxMultiple) &
  ControllerProps<ComboBoxOption[]> & { options: ComboBoxOption[] };

const SELECT_ALL_VALUE = 'select-all';

const FlatComboBoxMultiple = (props: Props) => {
  const classes = useStyles();
  const {
    disabled,
    options,
    value,
    onChange,
    name,
    optionsLoader,
    chipTagViewStyle,
  } = props;
  const formatMessage = useFormatMessage();

  const [isOptionsFetched, setIsOptionsFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setSelectOptions } = useContext(FormBuilderContext);

  const handleChange = (event: any, changedValue: ComboBoxOption[]) => {
    if (disabled) return;

    // Ничего не делаем, если это клик по "выбрать/снять всё", т.к. для этого
    // есть отдельный обработчик handleCheckAllClick.
    if (changedValue.find((option) => option.value === SELECT_ALL_VALUE))
      return;

    onChange(changedValue);
  };

  const isCheckedAll = value.length === options.length;
  const indeterminate = value.length > 0;

  const handleCheckAllClick = () => {
    // Если выбрано всё или что-то выбрано, то очищаем, иначе выбираем всё
    if (isCheckedAll || indeterminate) {
      onChange([]);
    } else {
      onChange(options);
    }
  };

  // Добавляем специальную, служебную опцию в начало списка
  const extendedOptions = isLoading
    ? []
    : [{ value: SELECT_ALL_VALUE, label: '' }, ...options];

  const handleOpen = () => {
    if (optionsLoader === 'async') {
      const { searchOptionsRequestConfig } = props as AsyncFlatComboBoxMultiple;

      if (isOptionsFetched) return;

      setIsLoading(true);
      mainService
        .makeActionRequest<SelectOption[]>(searchOptionsRequestConfig)
        .then(({ payload: newOptions }) => {
          setIsLoading(false);

          setSelectOptions((prevState) => ({
            ...prevState,
            [name]: newOptions,
          }));
          setIsOptionsFetched(true);
        });
    }
  };

  return (
    <MuiAutocomplete
      {...getLocalizedProperties(formatMessage)}
      classes={{
        root: clsx(classes.root, { disabled }),
        inputRoot: classes.inputRoot,
        input: classes.input,
        loading: 'animate-flicker',
        // Нужно для переопределения стилей служебной опции
        option: classes.option,
      }}
      multiple
      onOpen={handleOpen}
      disabled={disabled}
      disableCloseOnSelect
      disableClearable={disabled}
      options={extendedOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      filterOptions={handleFilterOptions}
      onChange={handleChange}
      value={value}
      loading={isLoading}
      renderInput={createRenderInput(props as FlatComboBoxProps)}
      renderTags={renderTags({
        multiline: (props as { multiline?: boolean })?.multiline,
        viewStyle: chipTagViewStyle,
        disabled,
      })}
      renderOption={(option, state) =>
        option.value === SELECT_ALL_VALUE ? (
          <TreeCheckboxButton
            checked={isCheckedAll}
            indeterminate={indeterminate}
            onClick={handleCheckAllClick}
          />
        ) : (
          renderOption(option, state)
        )
      }
    />
  );
};

export default FlatComboBoxMultiple;
