import React from 'react';
import measureSvgTextWidth from '../../../utils/measureSvgTextWidth';

// any, т.к. в recharts any.
function DotWithValueLabel(props: any) {
  // Параметр active - наш. На основе его рисуем изменения точки при hover
  const { cx, cy, fill, value, active } = props;

  // Размер шрифта текста.
  // Для изменения всех размеров достаточно изменить размер шрифта.
  // Остальные вычисления изменять не требуется.
  const fontSize = 10;

  const text = value.toString(); // Преобразуем значение в строку
  const textWidth = measureSvgTextWidth(text, `${fontSize}px`); // Определяем ширину текста

  // Вычисляем размеры
  const horizontalPadding = fontSize * 0.6; // Добавляем отступы по горизонтали
  const verticalPadding = fontSize * 0.4; // Добавляем оступы по вертикали
  const rectWidth = textWidth + horizontalPadding; // Размер прямоугольника
  const rectHeight = fontSize + verticalPadding; // Размер прямоугольника

  // Рассчитываем координаты текста относительно координат прямоугольника
  const textX = cx - textWidth / 2;
  const textY = cy + (fontSize - verticalPadding / 2) / 2;

  return (
    <g>
      <rect
        x={cx - rectWidth / 2}
        y={cy - rectHeight / 2}
        width={rectWidth}
        height={rectHeight}
        fill={fill}
        rx={3}
        ry={3}
        style={
          active
            ? { filter: 'drop-shadow(2px 2px 4px rgba(0,0,0,0.5))' }
            : undefined
        }
      />
      <text fontSize={fontSize} x={textX} y={textY} fill="white">
        {text}
      </text>
    </g>
  );
}

export default DotWithValueLabel;
