import { configureStore, combineReducers } from '@reduxjs/toolkit';

import mainMenu from './mainMenu';
import meta from './meta';
import routes from './routes';
import user from './user';
import dialogWithComponent from './dialogWithComponent';
import version from './version';
import snackbars from './snackbars';
import alertsFromActionServerResponse from './alertsFromActionServerResponse';
import globalFilter from './globalFilter';

const reducer = combineReducers({
  alertsFromActionServerResponse,
  mainMenu,
  meta,
  routes,
  user,
  dialogWithComponent,
  version,
  snackbars,
  globalFilter,
});

const store = configureStore({
  reducer,
  devTools: process.env.REACT_APP_ENV !== 'production',
});

export type RootState = ReturnType<typeof reducer>;

export default store;
