import React, { FC } from 'react';
import { Sector } from 'recharts';

const ActiveShape: FC<any> = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={-5}
        textAnchor="middle"
        fill="rgba(0, 0, 0, 0.87)"
        fontSize="0.7rem"
      >
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy}
        dy={18}
        textAnchor="middle"
        fontSize="1.2rem"
        fill="rgba(0, 0, 0, 0.87)"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      {/* Основа выбранной секции */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {/* Обводка выбранной секции */}
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 3}
        outerRadius={outerRadius + 6}
        fill={fill}
      />
    </g>
  );
};

export default ActiveShape;
