import React, { CSSProperties, MouseEventHandler } from 'react';
import clsx from 'clsx';
import {
  IconDefinition,
  IconWithPropsDefinition,
  IconWithSvgDefinition,
} from 'services/Main/types.common';
import getIcon from './getIcon';
import useStyles from './Icon.styles';
import getSvgIcon from './SvgIcon';
import { getProps } from './helpers';

export interface IconProps {
  definition: IconDefinition;
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLElement>;
}

export default ({ className, definition, style, onClick }: IconProps) => {
  const iconProps = getProps(definition);
  const { color, ...stylesProps } = iconProps.props;

  const classes = useStyles(stylesProps);
  const Icon = iconProps.hasOwnProperty('svg')
    ? getSvgIcon((iconProps as IconWithSvgDefinition).svg)
    : getIcon((iconProps as IconWithPropsDefinition).name);

  return (
    <Icon
      className={clsx(classes.svg, className)}
      color={color}
      style={style}
      onClick={onClick}
    />
  );
};
