import React, { useState } from 'react';
import EntryPickerDialog from './components/EntryPickerDialog';
import EntryPickerComboBox from './components/EntryPickerComboBox';
import { EntryPickerProps } from './types';
import entryPickerService from '../../../services/EntryPicker';

const EntryPicker = (props: EntryPickerProps) => {
  const { searchRequestConfig, onChange, multiple } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleEntryChoose = (chosenValues: (string | number)[]) => {
    entryPickerService
      .fetchOptions(searchRequestConfig, {
        optionValues: chosenValues,
      })
      .then(({ payload }) => {
        if (multiple) {
          onChange(payload.options);
        } else {
          onChange(payload.options[0] ?? null);
        }

        handleDialogClose();
      });
  };

  return (
    <div>
      <EntryPickerComboBox
        entryPickerProps={props}
        openDialog={handleDialogOpen}
      />

      <EntryPickerDialog
        entryPickerProps={props}
        open={dialogOpen}
        onClose={handleDialogClose}
        onEntryChoose={handleEntryChoose}
      />
    </div>
  );
};

export default EntryPicker;
