import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let FileExcel: any = (props: any) => (
  <SvgIcon
    {...props}
    width="30"
    height="40"
    viewBox="0 0 30 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 10.625V0H1.875C0.835938 0 0 0.835938 0 1.875V38.125C0 39.1641 0.835938 40 1.875 40H28.125C29.1641 40 30 39.1641 30 38.125V12.5H19.375C18.3438 12.5 17.5 11.6562 17.5 10.625ZM22.1953 18.9453L17.5 26.25L22.1953 33.5547C22.5938 34.1797 22.1484 35 21.4062 35H18.6797C18.3359 35 18.0156 34.8125 17.8516 34.5078C16.3203 31.6797 15 29.1406 15 29.1406C14.5 30.2969 14.2188 30.7031 12.1406 34.5156C11.9766 34.8203 11.6641 35.0078 11.3203 35.0078H8.59375C7.85156 35.0078 7.40625 34.1875 7.80469 33.5625L12.5156 26.2578L7.80469 18.9531C7.39844 18.3281 7.85156 17.5078 8.59375 17.5078H11.3125C11.6562 17.5078 11.9766 17.6953 12.1406 18C14.1797 21.8125 13.7031 20.625 15 23.3516C15 23.3516 15.4766 22.4375 17.8594 18C18.0234 17.6953 18.3438 17.5078 18.6875 17.5078H21.4062C22.1484 17.5 22.5938 18.3203 22.1953 18.9453ZM30 9.52344V10H20V0H20.4766C20.9766 0 21.4531 0.195312 21.8047 0.546875L29.4531 8.20312C29.8047 8.55469 30 9.03125 30 9.52344Z"
      fill="#C2CFE0"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
FileExcel.muiName = 'SvgIcon';

FileExcel = React.memo(FileExcel);
FileExcel.displayName = 'FileExcel';

export default FileExcel;
