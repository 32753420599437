import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    dialogContentRoot: {
      display: 'flex',
      flexDirection: 'column-reverse', // прибивка скролла к низу

      '& table': {
        borderCollapse: 'collapse',
        marginBottom: theme.spacing(2),
      },

      '& table td, & table th': {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
      },

      '& table tr:nth-child(even)': {
        backgroundColor: '#f8f8f8',
      },
    },
  })
);
