import React, { PropsWithChildren, useMemo } from 'react';
import { useTheme, MenuItem as MuiMenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useStyles from './MenuItem.styles';
import useScrollIntoView from '../../../utils/hooks/useScrollIntoView';
import trimQueryParams from '../../../utils/trimQueryParams';

interface OtherProps {
  to: string;
  pl?: number;
  onClick?: () => void;
}

type MenuItemProps = PropsWithChildren<OtherProps>;

const MenuItem = ({ to, onClick, children, pl }: MenuItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const trimmedTo = useMemo(() => trimQueryParams(to), [to]);

  let isActive = pathname.includes(trimmedTo);

  // exact для домашней страницы
  if (to === '/' && pathname !== '/') {
    isActive = false;
  }

  const scrollIntoViewIfActive = useScrollIntoView<HTMLAnchorElement>(isActive);

  const url = new URL(to, window.location.origin);

  return (
    <MuiMenuItem
      className={clsx(classes.menuItem, {
        active: isActive,
      })}
      ref={scrollIntoViewIfActive}
      // fix repeat styles in head tag
      style={{
        paddingLeft: theme.spacing(pl ? pl + 2 : 2),
      }}
      component={RouterLink}
      onClick={onClick || (() => {})}
      to={{
        pathname: url.pathname,
        search: url.search,
        hash: url.hash,
        state: {
          fromMenu: true,
        },
      }}
      tabIndex={0}
    >
      {children}
    </MuiMenuItem>
  );
};

export default MenuItem;
