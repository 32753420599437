import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { MenuItem } from 'services/Main/types';

export type MainMenuState = MenuItem[];

const set: CaseReducer<MainMenuState, PayloadAction<MenuItem[]>> = (
  state,
  action
) => action.payload;

export const { reducer, actions } = createSlice({
  name: 'mainMenu',
  initialState: [] as MainMenuState,
  reducers: {
    set,
  },
});

export default reducer;
