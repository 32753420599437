import React, { useRef } from 'react';
import { TextWidget } from '../../../services/Main/types.Component';
import TextWidgetComponent from '../../lowLevel/TextWidget';
import useStyles from './GroupOfTextWidget.styles';
import useMarkupErrorDetector from './useMarkupErrorDetector';

interface Props {
  textWidgets: TextWidget[];
}

export default ({ textWidgets }: Props) => {
  const classes = useStyles();
  const textWidgetContainerRef = useRef<HTMLDivElement>(null);

  useMarkupErrorDetector(textWidgetContainerRef);

  return (
    <div className={classes.root} ref={textWidgetContainerRef}>
      {textWidgets.map((textWidgetProps) => (
        <TextWidgetComponent
          key={textWidgetProps.requestConfig.url}
          {...textWidgetProps}
        />
      ))}
    </div>
  );
};
