import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

const SIZE = 5;

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(SIZE),
      height: theme.spacing(SIZE),
      '&.active': {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.activatedOpacity
        ),
      },

      '& .MuiTouchRipple-root': {
        border: '1px solid',
        borderColor: theme.palette.divider,
      },
    },
    tooltip: {
      maxWidth: 140,
      textAlign: 'center',
    },
  })
);
