import { createStyles, makeStyles } from '@material-ui/core/styles';
import background from './assets/bg.png';

export default makeStyles((theme) =>
  createStyles({
    root: {
      background: `url(${background}) 50% 100% no-repeat`,
      backgroundSize: 'cover',
    },
    container: {
      maxWidth: 'lg',
    },
    logoContainer: {
      justifyContent: 'center',
    },
    logo: {
      width: 240,
      height: 80,
    },
    card: {
      background: 'linear-gradient(#ffffff, #ffffff 45%,#f0f0f0 75%, #f0f0f0)',
    },
    marginBottom: {
      marginBottom: theme.spacing(4),
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      color: '#334D6E',
      padding: theme.spacing(2),
      fontWeight: 550,
      textAlign: 'center',

      '& span': {
        display: 'block',
        padding: '0 2px',

        [theme.breakpoints.up('sm')]: {
          display: 'inline-block',
        },
      },
    },
    textHeader: {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 900,
      color: '#90A0B7',
      marginBottom: theme.spacing(2),
    },
    textDescription: {
      fontSize: theme.typography.pxToRem(12),
      color: '#90A0B7',
    },
    loginBlock: {
      order: -1,
      marginBottom: theme.spacing(9),

      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(0),
        order: 0,
      },
    },
    forgotPassword: {
      textAlign: 'right',
    },
    russianText: {
      marginBottom: theme.spacing(8),
    },
    buttonGrid: {
      alignItems: 'center',
    },
    textFieldGrid: {
      justifyContent: 'center',
    },
    alert: {
      marginBottom: theme.spacing(2),
      maxWidth: 460,
    },
  })
);
