import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      padding: theme.spacing(1, 0),
    },
    link: {
      display: 'inline-block',
      '&.active .MuiButtonBase-root': {
        color: theme.additionalPalette.accentBlue,
      },
    },
  })
);
