import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    fileName: {
      display: 'flex',
      flexGrow: 1,
      minWidth: 0,
    },
    docViewer: {
      height: '100%',
      width: '100%',
      '& #image-renderer': {
        backgroundColor: 'transparent',
      },
    },
    toolbar: {
      boxShadow: theme.shadows[4],
    },
  })
);
