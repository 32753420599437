import React, { useContext } from 'react';
import { Table as DevExpressTable } from '@devexpress/dx-react-grid-material-ui';
import { TableContext } from '../../TableContext';

const Cell = ({
  column,
  tableColumn,
  tableRow,
  row,
  value,
  ...rest
}: DevExpressTable.DataCellProps & any) => {
  const tableContext = useContext(TableContext);

  const columnExtension = tableContext?.columnExtensions?.find(
    (extension) => extension.columnName === column.name
  );

  // Задаем цвет фона ячейки через стили, т.к. это гораздо быстрее, чем через
  // useStyle с пробросом параметров, т.к. jss имеет проблемы с
  // производительностью.
  let backgroundColor: string | undefined;

  if (column?.tableColumnViewConfig?.columnBackgroundColor) {
    backgroundColor = `${column.tableColumnViewConfig.columnBackgroundColor}`;
  }

  return (
    <DevExpressTable.Cell
      tableRow={tableRow}
      tableColumn={tableColumn}
      row={row}
      column={column}
      value={value}
      {...rest}
      style={{
        ...rest.style,
        backgroundColor,
        transform: undefined,
        // align выше по приоритету, чем alignAll
        textAlign: columnExtension?.align || columnExtension?.alignAll,
      }}
    />
  );
};

export default Cell;
