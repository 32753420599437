import { DynamicRedirectReaction } from 'services/Main/types.Component';
import history from 'utils/history';
import { getDynamicUrl } from './getDynamicUrl';

/**
 * Функция для реализации динамического редиректа
 */
const dynamicRedirect = (
  { redirectTo }: DynamicRedirectReaction,
  entity: unknown,
  target?: '_blank'
) => {
  const url = getDynamicUrl(redirectTo, entity);

  if (!url) {
    throw new Error('Ошибка при получении url для перехода');
  }

  if (target && target === '_blank') {
    window.open(url);
    return;
  }

  history.push(url);
};

export default dynamicRedirect;
