import { CardContent, styled } from '@material-ui/core';

const MessageContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1.75),

  '&:last-child': {
    paddingBottom: theme.spacing(1.75),
  },
}));

export default MessageContent;
