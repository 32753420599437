import React from 'react';
import {
  BarChart,
  Bar as BarComponent,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';
import { BarChartWidgetProps, HiddenBarKeys } from '../types';
import BarLabel from '../components/BarLabel';
import { BarTooltipCursor } from '../components/BarTooltipCursor';
import ChartsAxisLabel from '../../../lowLevel/ChartsAxisLabel';
import usePreparedDataByBarKey from '../helpers/usePreparedDataByBarKey';
import useChartTickFormatter from '../helpers/useChartTickFormatter';

/**
 * Выглядит так:
 *
 * y
 * |
 * |  []
 * |  [][]
 * |  [][]  []
 * |__[][]__[]__ x
 *
 */
export default ({
  data,
  bars,
  showTooltip,
  xAxisLabel,
  yAxisLabel,
  hiddenBarKeys,
}: BarChartWidgetProps & HiddenBarKeys) => {
  const groupedPreparedDataByBarKey = usePreparedDataByBarKey({ data, bars });

  const showBarLabels = bars.every((b) => typeof b.stackId === 'undefined');

  const tickFormatter = useChartTickFormatter();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          // По-умолчанию тип оси X - category. Для вертикальной ориентации устанавливаем тип на number.
          // Подробнее тут: https://recharts.org/en-US/api/XAxis#type
          type="category"
        >
          {xAxisLabel && (
            <ChartsAxisLabel position="insideBottomRight" offset={-5}>
              {xAxisLabel}
            </ChartsAxisLabel>
          )}
        </XAxis>
        <YAxis padding={{ top: 24 }} tickFormatter={tickFormatter}>
          {yAxisLabel && (
            <ChartsAxisLabel
              position="top"
              style={{
                textAnchor: 'start',
              }}
            >
              {yAxisLabel}
            </ChartsAxisLabel>
          )}
        </YAxis>
        {showTooltip && (
          <Tooltip cursor={<BarTooltipCursor />} content={<div />} />
        )}
        {bars.map((bar) => {
          const barData = groupedPreparedDataByBarKey.get(bar.key);

          return (
            <BarComponent
              key={bar.key}
              hide={hiddenBarKeys.includes(bar.key)}
              dataKey={`${bar.key}.value`}
              stackId={bar.stackId}
              fill={bar.fill}
              name={bar.name}
              // Ограничиваем максимальную ширину для "красоты"
              maxBarSize={60}
              minPointSize={12}
            >
              {/* Включаем LabelList только для графиков без стеков */}
              {showBarLabels && (
                <LabelList content={<BarLabel fill={bar.fill} />} />
              )}
              {Array.isArray(barData) &&
                barData.map(({ value, isLastBarInStack }) => {
                  // Если значение в баре равно 0, то заполнение
                  // становится прозрачным, чтобы скрыть бар. Это помогает
                  // избежать отображения последнего нулевого бара,
                  // учитывая настройку minPointSize.
                  if (value === 0) {
                    return <Cell width={0} />;
                  }

                  // Добавляем скругления для последних в стеке
                  if (isLastBarInStack) {
                    return (
                      <Cell
                        // @ts-ignore: Под капотом библиотека принимает
                        // массив, однако типизация говорит об обратном.
                        // Игнорируем typescript для этого параметра
                        radius={[12, 12, 0, 0]}
                      />
                    );
                  }

                  return <Cell />;
                })}
            </BarComponent>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
