import React, { ReactNode } from 'react';
import { styled } from '@material-ui/core/styles';

const HighlightedWrap = styled('span')({
  fontWeight: 500,
  background: 'rgba(255, 196, 0, 0.3)',
  padding: '0 1px',
});

export default function highlightFoundedText(
  text: string,
  searchText: string
): ReactNode {
  const index = text.toLowerCase().indexOf(searchText.toLowerCase());

  if (index === -1) {
    return text;
  }

  const phraseStart = text.substr(0, index);
  const highlightedPart = text.substr(index, searchText.length);
  const phraseEnd = text.substr(index + searchText.length);

  return (
    <div>
      {phraseStart}
      <HighlightedWrap>{highlightedPart}</HighlightedWrap>
      {phraseEnd}
    </div>
  );
}
