import {
  AsyncShowComponentInModalReaction,
  DialogWithComponent,
} from '../../services/Main/types.Component';
import store from '../../store';
import { dialogWithComponentActions } from '../../store/dialogWithComponent';
import type { DialogWithComponentContextValue } from '../../components/highLevel/Table/components/TableDialogWithComponent/TableDialogWithComponent.context';

export default (
  reaction: AsyncShowComponentInModalReaction,
  payload: DialogWithComponent,
  dialogWithComponentContextValue?: DialogWithComponentContextValue
) => {
  if (!payload || !payload.component) return;

  if (dialogWithComponentContextValue) {
    dialogWithComponentContextValue.setDialogWithComponent(payload);
    return;
  }

  store.dispatch(dialogWithComponentActions.open(payload));
};
