// В 5 версии MUI будет пофикшен баг:
// https://github.com/mui-org/material-ui/issues/13394
//
// @ts-ignore
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { Theme as MUITheme } from '@material-ui/core/styles/createMuiTheme';

const primary = {
  main: '#109cf1',
  contrastText: '#fff',
};

const secondary = {
  main: '#90a0b7',
};

const additionalPalette = {
  accentBlue: '#109cf1',
  accentBlueRgb: '16, 156, 241',
  accentBlueStrong: '#c2cfe0',
  yellow: '#ffb946',
  red: '#f7685b',
  green: '#2ed47a',
  purple: '#885af8',
  text: '#333',
  border: '#e0e0e0',
  title: '#334d6e',
  accentBlueBackground: 'rgba(194, 207, 224, 0.25)',
  lightGray: '#e5e5e5',
};

export interface Theme extends MUITheme {
  additionalPalette: {
    accentBlue: string;
    yellow: string;
    red: string;
    green: string;
    purple: string;
    text: string;
    border: string;
    title: string;
    accentBlueBackground: string;
    lightGray: string;
  };
}

export const drawerWidth = {
  full: 240,
  compact: 60,
};

export const themeObject = {
  palette: {
    primary,
    secondary,
    background: {
      default: '#f5f6f8',
    },
  },
  shape: {
    borderRadius: 0,
  },
  additionalPalette,
  mixins: {
    toolbar: {
      height: 64,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        color: additionalPalette.text,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 25,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: `rgba(${additionalPalette.accentBlueRgb}, 0.1)`,
          color: additionalPalette.text,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.85rem',
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-focusVisible': {
          backgroundColor: `rgba(${additionalPalette.accentBlueRgb}, 0.1)`,
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: additionalPalette.accentBlueStrong,
        },
      },
      button: {
        '&:hover': {
          backgroundColor: `rgba(${additionalPalette.accentBlueRgb}, 0.1)`,
        },
      },
    },
    // Фикс бага, из-за которого label заезжал на поле.
    // Баг появляется из-за того, что у textarea высота
    // устанавливается 0px, хотя должна быть 19px. Если
    // вылезут проблемы из-за этого решения нужно будет
    // писать issue в MaterialUI.
    MuiInputBase: {
      input: {
        minHeight: 19,
        '&.Mui-disabled[type="number"]': {
          '-moz-appearance': 'textfield',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        color: additionalPalette.title,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiTypography: {
      h6: {
        color: additionalPalette.title,
        fontSize: '0.90rem',
      },
    },
    MuiTableCell: {
      body: {
        color: 'inherit',
      },
    },
    MuiAutocomplete: {
      input: {
        textOverflow: 'clip',
      },
    },
  },
};

export default createMuiTheme(themeObject);
