import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default (color: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: color,
        color: '#fff !important',
      },
    })
  );
