import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Tooltip,
  ButtonBase,
  FormLabel,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Add from '@material-ui/icons/Add';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';
import { ErrorMessage } from '@hookform/error-message';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TableArrayOfProps } from './types';
import { useFormatMessage } from '../../../locale';
import Field from '../Field/Field.container';
import useStyles from './ArrayOf.styles';
import SortableItem from './SortableItem';
import useDnd from './hooks/useDnd';
import { getEmptyRow } from './helpers/getEmptyRow';
import { getRowValidationConfigs } from './helpers/getRowValidationConfigs';
import { groupedLabelByFieldName } from './helpers/groupedLabelByFieldName';
import useSorting from './hooks/useSorting';
import useFormErrors from './hooks/useFormErrors';
import { getArrayOfFieldNames } from './helpers/getArrayOfFieldNames';

export default ({
  control,
  name,
  rowDefinition,
  label,
  disallowRowAddition,
  disallowRowDeletion,
  disabled,
  showAutoNumeration = true,
  addRowButtonText,
  showEmptyRowByDefault = true,
  validationConfig,
  enableDragAndDrop,
  maxHeight,
  defaultSorting,
  onRemoveRow,
}: TableArrayOfProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { errors, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { clearErrors } = useFormErrors();

  const isRowAdditionEnabled =
    disallowRowAddition !== true && disabled !== true;
  const isRowDeletionEnabled =
    disallowRowDeletion !== true && disabled !== true;
  const isDragAndDropEnabled = enableDragAndDrop && disabled !== true;

  const rowValidationConfigs = useMemo(
    () => getRowValidationConfigs(validationConfig),
    [validationConfig]
  );

  const labelByFieldName = useMemo(
    () =>
      groupedLabelByFieldName(rowDefinition, rowValidationConfigs, disabled),
    [disabled, rowDefinition, rowValidationConfigs]
  );

  const emptyRow = useMemo(() => getEmptyRow(rowDefinition), [rowDefinition]);

  const { handleSortingChange, currentSortColumn, setCurrentSortColumn } =
    useSorting({
      name,
      rowDefinition,
      fields,
      defaultSorting,
    });

  const { rowIds, sensors, handleDragEnd } = useDnd({
    fields,
    name,
    setCurrentSortColumn,
  });

  useEffect(() => {
    if (fields.length === 0 && showEmptyRowByDefault) {
      append(emptyRow);
    }
  }, [fields, append, emptyRow, showEmptyRowByDefault]);

  const handleAddRow = () => {
    setCurrentSortColumn(null);
    append(emptyRow);
  };

  const handleRemoveRow = (index: number) => () => {
    onRemoveRow && onRemoveRow(name, index);

    Promise.resolve(remove(index)).then(() => {
      if (Object.keys(errors).length > 0) {
        const fieldNames = getArrayOfFieldNames(name, fields);

        clearErrors(...fieldNames);

        trigger(fieldNames).then();
      }
    });
  };

  const handleFieldChange = (fieldName: string) => () => {
    // Если текущий статус сортировки совпадает с именем поля который меняет
    // пользователь, то сбрасываем currentSortColumn.
    if (currentSortColumn && currentSortColumn.columnName === fieldName) {
      setCurrentSortColumn(null);
    }
  };

  return (
    <>
      {label && <FormLabel className={classes.label}>{label}</FormLabel>}
      <Box maxHeight={maxHeight} className={classes.tableContainer}>
        <Grid
          container
          className={clsx(classes.table, {
            [classes.stickyHeader]: maxHeight,
            disabled,
          })}
        >
          <Grid item xs={12} container className={classes.tableHead}>
            <Grid
              item
              xs={12}
              container
              className={classes.tableRow}
              wrap="nowrap"
              alignItems="stretch"
            >
              {showAutoNumeration && (
                <Grid
                  item
                  className={clsx(classes.tableCell, classes.tableServiceCell)}
                >
                  <Typography noWrap variant="subtitle2">
                    №
                  </Typography>
                </Grid>
              )}
              <Grid item xs="auto" container wrap="nowrap" alignItems="stretch">
                {isDragAndDropEnabled && (
                  <Grid
                    item
                    className={clsx(
                      classes.tableCell,
                      classes.tableServiceCell
                    )}
                  >
                    <Typography noWrap variant="subtitle2">
                      DND
                    </Typography>
                  </Grid>
                )}
                {rowDefinition.map(
                  ({ mdCols, name: columnName, label: title, hidden }) => (
                    <Grid
                      key={columnName}
                      item
                      xs={mdCols}
                      className={classes.tableCell}
                      hidden={hidden}
                    >
                      <Tooltip title={title as string}>
                        <ButtonBase
                          className={clsx(classes.sortLabel, {
                            active:
                              currentSortColumn &&
                              currentSortColumn.columnName === columnName,
                          })}
                          onClick={handleSortingChange(columnName)}
                          disableTouchRipple
                        >
                          <span className={classes.sortTitle}>
                            {labelByFieldName[columnName]}
                          </span>
                          <span>
                            <ArrowDownwardIcon
                              className={clsx(classes.sortIcon, {
                                directionAsc:
                                  currentSortColumn &&
                                  currentSortColumn.columnName === columnName &&
                                  currentSortColumn.direction === 'asc',
                                directionDesc:
                                  currentSortColumn &&
                                  currentSortColumn.columnName === columnName &&
                                  currentSortColumn.direction === 'desc',
                              })}
                            />
                          </span>
                        </ButtonBase>
                      </Tooltip>
                    </Grid>
                  )
                )}
              </Grid>
              {isRowDeletionEnabled && (
                <Grid
                  item
                  container
                  className={clsx(classes.tableCell, classes.tableServiceCell)}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} container className={classes.tableBody}>
            {fields.length === 0 && (
              <Grid item xs={12} container justify="center">
                <Grid item className={classes.tableNoData}>
                  <Typography variant="body2">
                    {formatMessage('noData')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {fields.length !== 0 && (
              <DndContext
                sensors={sensors}
                modifiers={[
                  restrictToVerticalAxis,
                  restrictToFirstScrollableAncestor,
                ]}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={rowIds}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map(({ id, ...field }, index) => (
                    <Grid
                      key={`row_${id}`}
                      item
                      xs={12}
                      container
                      className={classes.tableRow}
                      wrap="nowrap"
                      alignItems="stretch"
                    >
                      {showAutoNumeration && (
                        <Grid
                          item
                          container
                          className={clsx(
                            classes.tableCell,
                            classes.tableServiceCell
                          )}
                        >
                          <Grid item style={{ alignSelf: 'center' }}>
                            <Typography noWrap variant="body2">
                              {index + 1}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <SortableItem
                        id={`${id}`}
                        isDragAndDropEnabled={isDragAndDropEnabled}
                      >
                        {rowDefinition.map(
                          ({ name: columnName, mdCols, ...row }) => (
                            <Grid
                              key={`${name}[${index}].${columnName}`}
                              item
                              xs={mdCols}
                              className={classes.tableCell}
                              zeroMinWidth
                              hidden={row.hidden}
                            >
                              <Field
                                {...row}
                                label={undefined}
                                onChange={handleFieldChange(columnName)}
                                disabled={disabled || row.disabled}
                                name={`${name}[${index}].${columnName}`}
                                control={control}
                                defaultValue={field[columnName]}
                              />
                            </Grid>
                          )
                        )}
                      </SortableItem>
                      {isRowDeletionEnabled && (
                        <Grid
                          item
                          container
                          className={clsx(
                            classes.tableCell,
                            classes.tableServiceCell
                          )}
                        >
                          <Grid item style={{ alignSelf: 'center' }}>
                            <IconButton
                              size="small"
                              disabled={
                                showEmptyRowByDefault && fields.length <= 1
                              }
                              onClick={handleRemoveRow(index)}
                              className={classes.removeButton}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </SortableContext>
              </DndContext>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box width="100%" mb={2}>
        <ErrorMessage
          name={name}
          render={({ message }) => (
            <FormHelperText error>{message}</FormHelperText>
          )}
        />
      </Box>
      {isRowAdditionEnabled && (
        <Button
          variant="text"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddRow}
        >
          {addRowButtonText || formatMessage('addLine')}
        </Button>
      )}
    </>
  );
};
