import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Checkbox as CheckboxProps } from 'services/Main/types.Field';
import { getLabel } from './helpers';
import useStyles from './Checkbox.styles';

interface OtherProps {
  value?: boolean | null;
  onChange: (value: boolean) => void;
}

const Checkbox = ({
  disabled,
  onChange,
  value = false,
  label = '',
  labelPlacement = 'top',
  highlightColor,
}: CheckboxProps & OtherProps) => {
  const classes = useStyles(highlightColor)();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [checked, setChecked] = React.useState(!!value);

  const handleChange = (event: any, eventValue: boolean) => {
    setChecked(eventValue);
    onChange(eventValue);
  };

  return (
    <FormControl disabled={disabled}>
      <FormControlLabel
        className={classes.formControlLabel}
        value="top"
        control={
          <MuiCheckbox
            color={highlightColor === undefined ? 'primary' : undefined}
            className={classes.checkbox}
            checked={checked}
            onChange={handleChange}
          />
        }
        label={getLabel(label, classes, isXs)}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  );
};

export default Checkbox;
