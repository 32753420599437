import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import { Router } from 'react-router-dom';
import RouterComponent from 'router';
import history from 'utils/history';
import SnackbarProvider from 'components/lowLevel/SnackbarProvider';
import DialogAlert from 'components/lowLevel/DialogAlert';

const App = () => {
  return (
    <Router history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <SnackbarProvider>
          <DialogAlert>
            <RouterComponent />
          </DialogAlert>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </Router>
  );
};

export default App;
