import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ErrorMessage } from '@hookform/error-message';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Add from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { ArrayOf } from 'services/Main/types.Field';
import Field from '../Field';
import useStyles from './ArrayOf.styles';
import { useFormatMessage } from '../../../locale';
import { getEmptyRow } from './helpers/getEmptyRow';
import { getRowValidationConfigs } from './helpers/getRowValidationConfigs';
import { groupedLabelByFieldName } from './helpers/groupedLabelByFieldName';
import { getArrayOfFieldNames } from './helpers/getArrayOfFieldNames';
import useFormErrors from './hooks/useFormErrors';
import useSorting from './hooks/useSorting';

interface AdditionalProps {
  control: any;
  onRemoveRow?: (arrayOfFieldName: string, index: number) => void;
}

export default ({
  control,
  name,
  rowDefinition,
  disallowRowAddition,
  disallowRowDeletion,
  disabled,
  showAutoNumeration,
  addRowButtonText,
  showEmptyRowByDefault = true,
  validationConfig,
  onRemoveRow,
  defaultSorting,
}: ArrayOf & AdditionalProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { errors, trigger } = useFormContext();
  const { clearErrors } = useFormErrors();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const isRowAdditionEnabled =
    disallowRowAddition !== true && disabled !== true;
  const isRowDeletionEnabled =
    disallowRowDeletion !== true && disabled !== true;

  const rowValidationConfigs = useMemo(
    () => getRowValidationConfigs(validationConfig),
    [validationConfig]
  );

  const labelByFieldName = useMemo(
    () =>
      groupedLabelByFieldName(rowDefinition, rowValidationConfigs, disabled),
    [disabled, rowDefinition, rowValidationConfigs]
  );

  const emptyRow = useMemo(() => getEmptyRow(rowDefinition), [rowDefinition]);

  useSorting({
    name,
    rowDefinition,
    fields,
    defaultSorting,
  });

  useEffect(() => {
    if (fields.length === 0 && showEmptyRowByDefault) {
      append(emptyRow);
    }
  }, [fields, append, emptyRow, showEmptyRowByDefault]);

  const handleAddRow = () => {
    append(emptyRow);
  };

  const handleRemoveRow = (index: number) => () => {
    onRemoveRow && onRemoveRow(name, index);

    Promise.resolve(remove(index)).then(() => {
      if (Object.keys(errors).length > 0) {
        const fieldNames = getArrayOfFieldNames(name, fields);

        clearErrors(...fieldNames);

        trigger(fieldNames).then();
      }
    });
  };

  return (
    <>
      {fields.map(({ id, ...field }, index) => (
        <Box mb={3} className={classes.rowWrapper} key={id}>
          {showAutoNumeration && (
            <TextField
              className={classes.index}
              label="№"
              value={index + 1}
              disabled
            />
          )}
          <Grid container spacing={1} alignItems="flex-end">
            <Grid
              md={isRowDeletionEnabled ? 11 : 12}
              item
              container
              spacing={1}
            >
              {rowDefinition.map(
                ({
                  name: rowName,
                  mdCols,
                  label: rowLabel,
                  hidden,
                  ...row
                }) => (
                  <Grid
                    xs={12}
                    key={`${name}[${index}].${rowName}`}
                    item
                    md={mdCols}
                    hidden={hidden}
                  >
                    <Field
                      {...row}
                      hidden={hidden}
                      label={labelByFieldName[rowName]}
                      disabled={disabled || row.disabled}
                      name={`${name}[${index}].${rowName}`}
                      control={control}
                      defaultValue={field[rowName]}
                    />
                  </Grid>
                )
              )}
            </Grid>
            {isRowDeletionEnabled && (
              <Grid md={1} item>
                <Button
                  variant="text"
                  size="small"
                  disabled={showEmptyRowByDefault && fields.length <= 1}
                  color="primary"
                  onClick={handleRemoveRow(index)}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText error>{message}</FormHelperText>
        )}
      />
      {isRowAdditionEnabled && (
        <>
          <br />
          <Button
            variant="text"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddRow}
          >
            {addRowButtonText || formatMessage('addLine')}
          </Button>
        </>
      )}
    </>
  );
};
