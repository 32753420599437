import React from 'react';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import { RenderTagsProps } from '../../helpers/renderTags';
import TextTags from './TextTags';
import ChipTags from './ChipTags';

interface TagsProps extends RenderTagsProps {
  tags: ComboBoxOption[];
  getTagProps: AutocompleteGetTagProps;
}

const Tags = ({
  tags,
  multiline,
  getTagProps,
  disabled,
  disableClearable,
  viewStyle,
}: TagsProps) => {
  switch (viewStyle) {
    case 'chip':
      return (
        <ChipTags
          tags={tags}
          multiline={multiline}
          getTagProps={getTagProps}
          disabled={disabled}
          disableClearable={disableClearable}
        />
      );
    case 'text':
    case undefined:
      return <TextTags tags={tags} multiline={multiline} />;
    default:
      throw new Error(
        `Не удалось определить, какой Tag рисовать. Ожидали 'text' или 'chip', а в chipTagViewStyle прилетело: ${viewStyle}`
      );
  }
};

export default Tags;
