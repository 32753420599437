import React from 'react';
import Button from '@material-ui/core/Button';
import { InversePropsBag } from '../FormBuilder/FormBuilder';
import { useFormatMessage } from '../../../locale';

const TableFilterFormFooter = ({ defaultValues, reset }: InversePropsBag) => {
  const formatMessages = useFormatMessage();

  return (
    <Button
      color="primary"
      variant="outlined"
      fullWidth
      onClick={() => reset(defaultValues)}
    >
      {formatMessages('resetFilters')}
    </Button>
  );
};

export default TableFilterFormFooter;
