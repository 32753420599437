import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { ClickAwayListener } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { TimelineAuthor } from '../../../../services/Main/types.Component';
import Avatar from '../../Avatar';
import useStyles from './FormHistoryTimelineElementAvatar.styles';

interface OtherUserTimelineElementAvatarProps {
  author: TimelineAuthor;
}

const FormHistoryTimelineElementAvatar = ({
  author,
}: OtherUserTimelineElementAvatarProps) => {
  const classes = useStyles();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const {
    id,
    firstName,
    lastName,
    secondName,
    department,
    email,
    organization,
    phone,
    position,
  } = author;

  const fullName = secondName
    ? `${firstName} ${secondName} ${lastName}`
    : `${firstName} ${lastName}`;

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          open={isTooltipOpen}
          onClose={handleTooltipClose}
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              <Typography variant="h5">{fullName}</Typography>
              {phone && <Typography variant="body2">{phone}</Typography>}
              <Typography variant="body2">{email}</Typography>
              {department && (
                <Typography variant="body2">{department}</Typography>
              )}
              {organization && (
                <Typography variant="body2">{organization}</Typography>
              )}
              {position && <Typography variant="body2">{position}</Typography>}
            </>
          }
        >
          <div>
            <Avatar
              className={classes.OtherUserTimelineElementAvatar}
              name={`${firstName} ${lastName} ${id}`}
              onClick={handleTooltipOpen}
            />
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default FormHistoryTimelineElementAvatar;
