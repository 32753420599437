// Типизация для значений Cookie
interface CookieValues {
  [key: string]: string;
}

// Функция для получения Cookie в виде объекта
export const getCookie = (): CookieValues => {
  const cookies: CookieValues = {};
  document.cookie.split('; ').forEach((cookie) => {
    const [name, value] = cookie.split('=');
    cookies[name] = value;
  });
  return cookies;
};

// Функция для сохранения Cookie
export const setCookie = (
  name: string,
  value: string,
  expires: number
): void => {
  document.cookie = `${name}=${value};expires=${new Date(
    expires
  ).toUTCString()};path=/`;
};

// Функция для удаления Cookie
export const deleteCookie = (name: string): void => {
  setCookie(name, '', -1);
};
