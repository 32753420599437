import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'services/Main/types';

export type UserState = User | null;

export const { reducer, actions } = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {
    set: (state, { payload }: PayloadAction<UserState>) => payload,
  },
});

export default reducer;
