import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import QrCodeIcon from 'svg/QrCode';
import Icon from '../Icon';
import QrReader from '../QrReader/QrReaderButton';
import useStyles from './DrawerFooter.styles';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';
import { useFormatMessage } from '../../../locale';

const DrawerFooter = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const isCompactMenuView = useIsCompactMenuView();

  return (
    <Grid container justify="space-around" className={classes.container}>
      <Grid item>
        <NavLink
          className={classes.link}
          to={{
            pathname: '/profile',
            state: {
              fromMenu: true,
            },
          }}
        >
          <Tooltip
            title={formatMessage('profileButton')}
            placement={isCompactMenuView ? 'right' : 'top'}
          >
            <IconButton>
              <Icon definition="mui:AccountCircle" />
            </IconButton>
          </Tooltip>
        </NavLink>
      </Grid>
      <Grid item>
        <QrReader
          renderButton={(handleOpen) => (
            <Tooltip
              title={formatMessage('scanQrCode')}
              placement={isCompactMenuView ? 'right' : 'top'}
            >
              <IconButton onClick={handleOpen}>
                <QrCodeIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default DrawerFooter;
