import React, { Component, PropsWithChildren, ReactElement } from 'react';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { FormattedMessage } from 'react-intl';
import { metaActions } from 'store/meta';
import { sendMessageToBot } from '../bot';

type State = {
  readonly error: Error | null | undefined;
};

type Props = PropsWithChildren<{
  dispatch: any;
  fallbackRender?: ReactElement | null;
}>;

const initialState = {
  error: undefined,
};

class CleanUnexpectedDataErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(error: Error | null) {
    if (error && error.name === 'UnexpectedDataError') {
      this.handleUnexpectedDataError(error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler
    );
  }

  promiseRejectionHandler = ({ reason }: PromiseRejectionEvent) => {
    if (reason && reason.name === 'UnexpectedDataError') {
      this.handleUnexpectedDataError(reason);
    }
  };

  handleUnexpectedDataError = (error: Error | null) => {
    const { dispatch } = this.props;

    this.setState({ error });
    // this.notifyAdmins(error);
    dispatch(metaActions.hideLoadingScreen());
  };

  notifyAdmins = (error: Error | null) => {
    sendMessageToBot(
      `*⚠️ В приложении обнаружены неожиданные данные:*\n${error?.message}`
    );
  };

  render() {
    const { error } = this.state;
    const { children, fallbackRender } = this.props;

    if (error) {
      if (fallbackRender || fallbackRender === null) return fallbackRender;

      return (
        <Alert severity="error">
          <AlertTitle>
            <FormattedMessage id="unexpectedDataErrorBoundary" />
          </AlertTitle>
          <FormattedMessage id="unexpectedDataErrorBoundaryDescription" />
        </Alert>
      );
    }

    return children;
  }
}

export const UnexpectedDataErrorBoundary = connect(null, (dispatch) => ({
  dispatch,
}))(CleanUnexpectedDataErrorBoundary);
