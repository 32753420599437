import React from 'react';
import QrReader from 'react-qr-reader';
import { useFormatMessage } from '../../../locale';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';

const QrReaderComponent = () => {
  const formatMessage = useFormatMessage();
  const enqueueSnackbar = useEnqueueSnackbar();

  const handleScan = (data: string | null) => {
    // eslint-disable-next-line no-alert
    if (data && window.confirm(formatMessage('followTheLink'))) {
      window.location.href = data;
    }
  };

  const handleError = (err: Error) => {
    enqueueSnackbar(formatMessage('errorOccurredWhileTryingToScan'), {
      variant: 'error',
    });

    console.error(err);
  };

  return (
    <QrReader
      delay={500}
      onError={handleError}
      onScan={handleScan}
      style={{ width: '100%' }}
    />
  );
};

export default QrReaderComponent;
