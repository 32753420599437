import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    logo: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      ...theme.mixins.toolbar,

      '&.compactView': {
        display: 'none',
      },
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    logoSvg: {
      width: 140,
      height: 24,
      marginRight: 5,
    },
  })
);
