import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    quoteContainer: {
      position: 'relative',
      display: 'flex',
      marginBottom: theme.spacing(0.75),
      gap: theme.spacing(1.5),

      '&:hover': {
        background: 'aliceblue',
        cursor: 'pointer',
        userSelect: 'none',
      },
    },
    quote: {
      padding: theme.spacing(0.25, 0.25, 0.25, 2.5),
    },
    divider: {
      width: theme.spacing(0.75),
      borderRadius: theme.spacing(0.75),
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
    },
    quoteAuthor: {
      marginBottom: theme.spacing(0.25),
    },
    quoteText: {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
      fontWeight: 300,
    },
  })
);
