import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import GridBuilder from '../../lowLevel/GridBuilder';
import ComponentLoader from '../../creational/ComponentLoader';
import Header from '../../lowLevel/Header';
import {
  WidgetGroup,
  WidgetsPageItem,
} from '../../../services/Main/types.Page';
import useStyles from './Widgets.styles';

const Widgets = ({ markup, header }: WidgetGroup) => {
  const classes = useStyles();

  return (
    <>
      {header && <Header {...header} />}
      <GridBuilder<WidgetsPageItem>
        spacing={2}
        markup={markup}
        renderGridItem={({ component }) => (
          <Box className={classes.gridItem} component={Paper}>
            <ComponentLoader component={component} />
          </Box>
        )}
      />
    </>
  );
};

export default Widgets;
