import React, { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import useStyles from './WidgetSkeletonContainer.styles';
import { Alert as AlertProps } from '../../../services/Main/types.Component';
import Alert from '../Alert';

interface Props {
  skeleton: ReactNode;
  alert?: AlertProps;
  rootClassName?: string;
  alertClassName?: string;
}

function WidgetSkeletonContainer({
  skeleton,
  alert,
  rootClassName,
  alertClassName,
}: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, rootClassName)}>
      {skeleton}
      <div className={clsx(classes.alertWrapper, alertClassName)}>
        {alert && <Alert {...alert} />}
      </div>
    </div>
  );
}

export default WidgetSkeletonContainer;
