import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { FetchTablePropsResponsePayload } from '../../../../../../services/EntryPicker/EntryPicker.interface';
import { ComponentContext } from '../../../../../creational/ComponentLoader';
import { useSelector } from '../../../../../../store';

const DEFAULT_WIDTH = 200;

export default (
  name: string,
  columns: FetchTablePropsResponsePayload['columns'],
  columnExtensions: FetchTablePropsResponsePayload['columnExtensions'],
  enableColumnResizing: FetchTablePropsResponsePayload['enableColumnResizing']
): [
  TableColumnWidthInfo[],
  Dispatch<SetStateAction<TableColumnWidthInfo[]>>,
  () => void
] => {
  const componentContext = useContext(ComponentContext);
  const user = useSelector((state) => state.user);
  const localStorageKey = `${user?.id}_formId_${componentContext?.id}_entryPickerTableResizeState_${name}`;
  const localStorageJSONState =
    enableColumnResizing && localStorage.getItem(localStorageKey);
  const localStorageState: TableColumnWidthInfo[] | false =
    localStorageJSONState && JSON.parse(localStorageJSONState);

  const defaultWidths = getDefaultWidths(columns, columnExtensions);

  const initialColumnWidths = useMemo(() => {
    if (
      localStorageState &&
      Array.isArray(localStorageState) &&
      localStorageState.length > 0
    ) {
      return localStorageState;
    }

    return defaultWidths;
    // eslint-disable-next-line
  }, [defaultWidths, localStorageState, columns]);

  const [columnWidths, setColumnWidths] =
    useState<TableColumnWidthInfo[]>(initialColumnWidths);

  useEffect(() => {
    enableColumnResizing &&
      localStorage.setItem(localStorageKey, JSON.stringify(columnWidths));
  }, [columnWidths, enableColumnResizing, localStorageKey]);

  useEffect(() => {
    setColumnWidths(initialColumnWidths);
    // eslint-disable-next-line
  }, [columns]);

  const resetColumnWidthsToDefault = () => {
    setColumnWidths(defaultWidths);
  };

  return [columnWidths, setColumnWidths, resetColumnWidthsToDefault];
};

function getDefaultWidths(
  columns: FetchTablePropsResponsePayload['columns'],
  columnExtensions?: FetchTablePropsResponsePayload['columnExtensions']
): TableColumnWidthInfo[] {
  return columns.map(({ name }) => ({
    columnName: name,
    width: getWidth(name, columnExtensions),
  }));
}

function getWidth(
  name: string,
  columnExtensions: FetchTablePropsResponsePayload['columnExtensions']
): number | string {
  if (!columnExtensions) return DEFAULT_WIDTH;

  const colExtension = columnExtensions.find(
    ({ columnName }) => columnName === name
  );

  return colExtension?.width || DEFAULT_WIDTH;
}
