import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ForbiddenPicture from 'svg/Error403';
import ErrorPage from 'components/lowLevel/ErrorController';
import { useDispatch } from 'react-redux';
import { metaActions } from '../../../store/meta';
import { useFormatMessage } from '../../../locale';

const ForbiddenPage = () => {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();

  const hideForbiddenPage = () => {
    dispatch(metaActions.setShowForbiddenPage(false));
  };

  useEffect(() => {
    const unsubscribe = history.listen(hideForbiddenPage);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ErrorPage
      heading={formatMessage('noAccess')}
      description={formatMessage('forbiddenDescription')}
      image={<ForbiddenPicture />}
      onHomeClick={hideForbiddenPage}
    />
  );
};

export default ForbiddenPage;
