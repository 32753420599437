import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './TooltipItem.styles';
import Link from '../../Link';
import { TooltipItemProps } from '../types';

const TooltipItem = ({
  name,
  printValue,
  href,
  color,
  active,
}: TooltipItemProps) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <span className={classes.badge} style={{ background: color }} />
      <Typography className={clsx(classes.text, { active })}>
        {href ? (
          <Link to={href}>{`${name}: ${printValue}`}</Link>
        ) : (
          `${name}: ${printValue}`
        )}
      </Typography>
    </span>
  );
};

export default TooltipItem;
