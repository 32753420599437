import React, { useCallback, useContext } from 'react';
import { styled, lighten } from '@material-ui/core/styles';
import { TableSelection as TableSelectionBase } from '@devexpress/dx-react-grid';
import { Table as DevExpressTable } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import EntryPickerTableContext from '../../EntryPickerTable.context';

const PREFIX = 'TableSelectRow';
export const classes = {
  selected: `${PREFIX}-selected`,
  root: `${PREFIX}-root`,
};

const StyledTableRow = styled(DevExpressTable.Row)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },

  [`&.${classes.selected}`]: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
}));

const TableSelectRow = ({
  children,
  className,
  onToggle,
  row,
  selectByRowClick,
  highlighted,
  tableColumn,
  tableRow,
  forwardedRef,
  ...rest
}: TableSelectionBase.RowProps & any) => {
  const {
    onEntryChoose,
    entryPickerProps: { multiple },
  } = useContext(EntryPickerTableContext);

  const handleClick = useCallback(
    (e) => {
      if (!selectByRowClick) return;

      e.stopPropagation();

      onToggle();
    },
    [onToggle, selectByRowClick]
  );

  const handleDoubleClick = useCallback(() => {
    const { rowId } = tableRow;
    onEntryChoose([rowId]);
  }, [tableRow, onEntryChoose]);

  return (
    <StyledTableRow
      ref={forwardedRef}
      className={clsx(className, {
        [classes.selected]: highlighted,
      })}
      onClick={handleClick}
      onDoubleClick={multiple ? undefined : handleDoubleClick}
      {...rest}
    >
      {children}
    </StyledTableRow>
  );
};

export default TableSelectRow;
