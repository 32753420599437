import React, { useCallback, useMemo, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import authService from '../../services/Auth';
import { useSelector } from '../../store';
import packageJson from '../../../package.json';
import useStyles from './ProfilePage.styles';
import { useFormatMessage } from '../../locale';
import ChangePassword from './ProfilePage.ChangePassword';

const ProfilePage = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const user = useSelector((state) => state.user);
  const version = useSelector((state) => state.version);
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);

  const handleIsInfoClose = useCallback(() => setIsInfoOpen(false), []);
  const handleIsInfoOpen = useCallback(() => setIsInfoOpen(true), []);

  const TooltipContent = useMemo(
    () => (
      <>
        {printRecord('Frontend', packageJson.version)}
        {version &&
          Object.entries(version).map(([key, value]) =>
            printRecord(key, value)
          )}
      </>
    ),
    [version]
  );

  return (
    <Paper>
      <Box p={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography gutterBottom variant="h5">
            {user?.name || 'KeepUp'}
          </Typography>
          <ClickAwayListener onClickAway={handleIsInfoClose}>
            <div>
              <Tooltip
                open={isInfoOpen}
                onClose={handleIsInfoClose}
                title={TooltipContent}
                interactive
                classes={{ tooltip: classes.tooltip }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <IconButton onClick={handleIsInfoOpen}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Box>
        <Box mb={2}>
          <Divider />
        </Box>
        <Box mb={2}>
          <Typography gutterBottom variant="body2">
            {`${formatMessage('language')}: ${formatMessage('localeName')}`}
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={authService.processLogout}>
              {formatMessage('logout')}
            </Button>
          </Grid>
          <Grid item>
            <ChangePassword />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const printRecord = (key: string, value: string) => (
  <Typography variant="body1" key={`${key}_${value}`}>
    {key}:
    <Box ml={1} component="b">
      {value}
    </Box>
  </Typography>
);

export default ProfilePage;
