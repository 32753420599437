import { useDispatch } from 'react-redux';
import { useSelector } from '../../../store';
import { metaActions } from '../../../store/meta';

export default () => {
  const isSomeActionExecuting = useSelector(
    (state) => state.meta.isSomeActionExecuting
  );
  const dispatch = useDispatch();

  const setIsSomeActionExecuting = (newState: boolean) => {
    dispatch(metaActions.setIsSomeActionExecuting(newState));
  };

  return { isSomeActionExecuting, setIsSomeActionExecuting };
};
