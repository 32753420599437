import React, { useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import MuiGrid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import {
  FilterChip,
  RequestConfig,
  Header as HeaderProps,
  TableAction,
  Alert as AlertProps,
  TableQuickFilters,
} from 'services/Main/types.Component';
import SearchInput, { SearchInputProps } from '../SearchInput';
import useStyles from './TableModules.styles';
import TableFilters from '../TableFilters';
import TableFilterChips from '../TableFilterChips';
import Header from '../Header';
import { getHeader } from './helpers';
import TableActionsComponent from '../TableActions';
import TableExtraActions from '../TableExtraActions';
import TableQuickFiltersComponent from '../TableQuickFilters';
import { useFormatMessage } from '../../../locale';
import Alerts from '../Alerts';
import TableWithClosedButton from '../TableWithClosedButton';
import { TableContext } from '../../highLevel/Table';
import TableColumnVisibilityButton from '../TableColumnVisibilityButton';
import { prepareTableFilterFormProps } from './helpers/prepareTableFilterFormProps';
import TableFilterForm from '../TableFilterForm';
import { TableFilterFormProps } from './types';

type TableModulesProps = {
  totalRows: number;
  heading?: string;
  header?: HeaderProps;
  searchInputProps: SearchInputProps;
  requestConfig: RequestConfig;
  tableFilterFormProps?: Omit<
    TableFilterFormProps,
    'filterChips' | 'setFilterChips'
  >;
  showSearchbar?: boolean;
  actions?: TableAction[];
  alerts?: AlertProps[];
  showTotalRows?: boolean;
  quickFilters?: TableQuickFilters | null;
};

const TableModules = ({
  totalRows,
  heading,
  header: headerProps,
  searchInputProps,
  tableFilterFormProps,
  showSearchbar,
  actions,
  alerts,
  showTotalRows = true,
  quickFilters,
}: TableModulesProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tableContext = useContext(TableContext);

  const header = getHeader(headerProps, heading);
  const [filterChips, setFilterChips] = useState<FilterChip[] | undefined>();

  const preparedTableFilterFormProps =
    prepareTableFilterFormProps(tableFilterFormProps);

  return (
    <>
      <Box className={classes.tableModulesContainer}>
        {quickFilters && <TableQuickFiltersComponent {...quickFilters} />}
        <MuiGrid container spacing={1}>
          <MuiGrid item xs={12} md={7}>
            {header && <Header {...header} />}
            {showTotalRows && (
              <Typography className={classes.quantity}>
                {`${formatMessage('total')}: ${totalRows}`}
              </Typography>
            )}
          </MuiGrid>
          <MuiGrid item container xs={12} md={5} spacing={1}>
            {actions && Array.isArray(actions) && actions.length > 0 && (
              <MuiGrid
                item
                container
                spacing={1}
                justify="flex-end"
                className={classes.actionsContainer}
              >
                <TableActionsComponent actions={actions} />
              </MuiGrid>
            )}
            <MuiGrid
              className={classes.utilityActionsWrapper}
              container
              alignItems="flex-start"
              justify="flex-end"
              spacing={1}
            >
              {showSearchbar && (
                <MuiGrid item>
                  <SearchInput
                    className={classes.searchInput}
                    {...searchInputProps}
                  />
                </MuiGrid>
              )}
              {tableContext?.showWithClosedButton && (
                <MuiGrid item>
                  <TableWithClosedButton />
                </MuiGrid>
              )}
              {tableContext?.columnVisibilityConfig?.enabled && (
                <MuiGrid item>
                  <TableColumnVisibilityButton />
                </MuiGrid>
              )}
              {preparedTableFilterFormProps?.tableNotRequiredFilterFormProps && (
                <TableFilters
                  {...preparedTableFilterFormProps.tableNotRequiredFilterFormProps}
                  fieldsWithDisableClearableLength={
                    preparedTableFilterFormProps.fieldsWithDisableClearableLength
                  }
                  filterChips={filterChips}
                  setFilterChips={setFilterChips}
                />
              )}
              <TableExtraActions />
            </MuiGrid>
          </MuiGrid>
          <Alerts staticAlerts={alerts} />
          {tableFilterFormProps && (
            <MuiGrid item container xs={12} alignItems="center" wrap="nowrap">
              {preparedTableFilterFormProps?.tableRequiredFilterFormProps ? (
                <>
                  <MuiGrid
                    item
                    className={clsx(classes.requiredFilterFormContainer, {
                      showDivider: filterChips && filterChips.length > 0,
                    })}
                    md={4}
                  >
                    <TableFilterForm
                      {...preparedTableFilterFormProps.tableRequiredFilterFormProps}
                      filterChips={filterChips}
                      setFilterChips={setFilterChips}
                    />
                  </MuiGrid>
                </>
              ) : null}
              {filterChips && filterChips.length > 0 ? (
                <MuiGrid item xs={12} md={8}>
                  <TableFilterChips
                    {...tableFilterFormProps}
                    filterChips={filterChips}
                    setFilterChips={setFilterChips}
                  />
                </MuiGrid>
              ) : null}
            </MuiGrid>
          )}
        </MuiGrid>
      </Box>
    </>
  );
};

export default TableModules;
