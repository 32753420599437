import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
    tooltipRoot: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    sortLabelRoot: {
      maxWidth: '100%',

      '&.disabled': {
        color: 'inherit',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    sortLabelRight: {
      flexDirection: 'row-reverse',
    },
    sortLabelActive: {
      color: 'inherit',
    },
  })
);
