import React from 'react';
import { PriceFormat } from 'services/Main/types.Field';
import { InputProps } from '@material-ui/core/Input';
import InputComponent from '../InputComponent';
import BaseTextField from '../BaseTextField';

interface OtherProps extends Omit<InputProps, 'autoComplete'> {
  InputProps?: Partial<Omit<InputProps, 'autoComplete'>>;
}

const PriceNumber = ({
  max,
  min,
  currencySign,
  autoComplete,
  ...rest
}: PriceFormat & OtherProps) => {
  return (
    <BaseTextField
      {...rest}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          autoComplete: autoComplete || 'off',
          max,
          min,
          decimalScale: 2,
          thousandSeparator: ' ',
          fixedDecimalScale: true,
          suffix: currencySign && ` ${currencySign}`,
        },
      }}
    />
  );
};
export default PriceNumber;
