import React, { PropsWithChildren, useEffect } from 'react';
import {
  Box,
  Drawer,
  Hidden,
  Toolbar as Offset,
  IconButton,
  useMediaQuery,
  useTheme,
  Tooltip,
  Backdrop,
  Typography,
} from '@material-ui/core';
import ChevronIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import useStyles from './SidebarWrapper.styles';
import { useSidebarWrapperContext } from './SidebarWrapper.provider';
import { useFormatMessage } from '../../../../../locale';
import FilterAltOutlined from '../../../../../svg/FilterAltOutlined';

/**
 * Делать анимацию пульсирования счетчика (3 раза).
 * Красным.
 */
const SidebarWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const theme = useTheme();
  const shouldDrawMobileSidebar = useMediaQuery(theme.breakpoints.down('lg'));

  const { counter, setIsSidebarOpen, isSidebarOpen } =
    useSidebarWrapperContext();

  useEffect(() => {
    // При изменении ширины экрана скрываем/показываем сайдбар
    setIsSidebarOpen(!shouldDrawMobileSidebar);
  }, [shouldDrawMobileSidebar, setIsSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    setIsSidebarOpen(false);
  };

  const CollapseIcon = isSidebarOpen ? ChevronIcon : FilterAltOutlined;
  const tooltipText = isSidebarOpen
    ? formatMessage('collapseSidebar')
    : formatMessage('expandSidebar');

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        // На устройствах ниже smDown добавляем backdrop для закрытия.
        // Данный хак, позволяет отображать один и тот-же drawer без перемонтирования на разных устройствах.
        // Ранее изменялся variant у Drawer, что приводило к перемонтированию компонентов внутри.
        open={isSidebarOpen && shouldDrawMobileSidebar}
        onClick={handleClose}
      />
      <Drawer
        anchor="right"
        open={isSidebarOpen}
        variant="permanent"
        classes={{
          paper: clsx(classes.paper, { isSidebarOpen }),
        }}
        onClose={handleClose}
      >
        <Hidden smUp>{!isSidebarOpen && <Offset />}</Hidden>
        <Box className={classes.drawerContentContainer}>
          <Box className={clsx(classes.buttonContainer, { isSidebarOpen })}>
            <Tooltip
              key={tooltipText}
              title={tooltipText}
              arrow
              placement="left"
            >
              <IconButton
                size="small"
                onClick={toggleSidebar}
                className={clsx(classes.viewButton, {
                  shouldShowCounter: counter && counter > 0 && !isSidebarOpen,
                })}
              >
                {counter && counter > 0 && !isSidebarOpen ? (
                  <Typography
                    variant="caption"
                    className={classes.viewButtonCounter}
                    style={{ fontSize: 12, marginTop: 2 }}
                  >
                    {counter > 9 ? '9+' : counter}
                  </Typography>
                ) : (
                  <CollapseIcon
                    className={clsx(classes.viewButtonIcon, { isSidebarOpen })}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          <Box className={clsx(classes.content, { isSidebarOpen })}>
            {children}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default SidebarWrapper;
