import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    rowWrapper: {
      display: 'flex',
    },
    index: {
      width: 13,
      marginRight: theme.spacing(1),

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    label: {
      fontSize: theme.typography.pxToRem(12),
    },

    tableContainer: {
      scrollSnapType: 'y mandatory',
      marginBottom: theme.spacing(2),
      overflow: 'auto',
      width: '100%',
    },

    table: {
      // у MUI table minWidth = 650
      minWidth: 1300,

      '&$stickyHeader': {
        scrollSnapAlign: 'end',

        '& $tableHead': {
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 600,
          fallbacks: {
            position: '-webkit-sticky',
          },
        },
      },

      '&.disabled': {
        '& $tableCell:not($tableServiceCell), $tableNoData': {
          color: theme.palette.action.disabled,
        },
      },
    },
    tableHead: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(1),

      '& $tableRow $tableCell': {
        padding: theme.spacing(2, 1),
      },
    },
    tableRow: {
      background: theme.palette.background.paper,
    },
    tableCell: {
      padding: theme.spacing(1, 1),
    },
    tableServiceCell: {
      color: theme.palette.text.primary,
      flexGrow: 0,
      maxWidth: 50,
      flexBasis: 50,
    },
    tableBody: {},
    stickyHeader: {},
    removeButton: {
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    sortLabel: {
      color: theme.palette.text.primary,
      maxWidth: '100%',

      '&.active': {
        '& $sortIcon': {
          opacity: 1,
        },

        '& $sortLabel': {
          color: 'inherit',
        },
      },

      '&:not(.active):hover': {
        color: theme.palette.action.active,

        '& $sortIcon': {
          opacity: 0.5,
        },
      },
    },

    sortTitle: {
      ...theme.typography.subtitle2,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    sortIcon: {
      fontSize: theme.typography.pxToRem(18),
      transform: 'rotate(180deg)',
      marginLeft: theme.spacing(0.5),
      opacity: 0,

      '&.directionAsc': {
        transform: 'rotate(180deg)',
      },

      '&.directionDesc': {
        transform: 'rotate(0deg)',
      },
    },
    tableNoData: {
      padding: theme.spacing(2, 0),
    },
  })
);
