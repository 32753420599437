import { makeStyles } from '@material-ui/core';
import { SIDEBAR_WIDTH } from './SidebarLoader/SidebarWrapper/constants';

export default makeStyles((theme) => ({
  pageContainer: {
    '&.hasSidebar': {
      marginRight: SIDEBAR_WIDTH.closed,

      '&.isSidebarOpen': {
        [theme.breakpoints.down('sm')]: {
          marginRight: SIDEBAR_WIDTH.closed,
        },

        [theme.breakpoints.up('xl')]: {
          marginRight: SIDEBAR_WIDTH.openMdUp,
        },
      },
    },
  },
  sidebarContainer: { flexShrink: 0 },
}));
