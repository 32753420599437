import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from 'theme';

export default (highlightColor?: 'error' | 'warning' | 'info' | 'success') =>
  makeStyles((theme: Theme) =>
    createStyles({
      label: {
        fontSize: theme.typography.pxToRem(12),
        color: 'rgba(0, 0, 0, 0.54)',
      },
      formControlLabel: {
        marginRight: 0,
        marginLeft: 0,
      },
      checkbox: highlightColor
        ? {
            color: theme.palette[highlightColor].main,
            '&:hover': {
              color: theme.palette[highlightColor].main,
              backgroundColor: fade(theme.palette[highlightColor].light, 0.15),
            },
            '&.Mui-checked': {
              color: theme.palette[highlightColor].main,
              '&:hover': {
                backgroundColor: fade(
                  theme.palette[highlightColor].light,
                  0.15
                ),
              },
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
            },
          }
        : {},
    })
  );
