import React from 'react';
import { BarChartProps } from '../../../services/Main/types.Component';
import { DonutChartState } from './types';
import DonutChartWidget from './DonutChartWidget';
import getMinHeight from './helpers/getMinHeight';
import useWidgetDataQuery, {
  checkEmptyStateFn,
} from '../../../utils/hooks/useWidgetDataQuery';
import WidgetContainer from '../../lowLevel/WidgetContainer';
import WidgetSkeletonContainer from '../../lowLevel/WidgetSkeletonContainer';
import DonutSkeleton from '../../../svg/skeletons/DonutSkeleton';

const checkEmptyState: checkEmptyStateFn<DonutChartState> = (payload) => {
  return payload.data.length === 0 || !Array.isArray(payload.data);
};

// Сам donut всегда 200х200, c легендой будет где-то 200х260

const DonutChartWidgetDataLoader = (props: BarChartProps) => {
  const { heading, showLegend, requestConfig } = props;

  const height = getMinHeight(300, !!heading, !!showLegend);

  const { isFetching, isFistTimeFetched, widgetState, alert } =
    useWidgetDataQuery<DonutChartState>(requestConfig, checkEmptyState);

  const skeleton = (
    <DonutSkeleton
      className={!isFistTimeFetched ? 'animate-flicker' : undefined}
      style={{ height: 260, width: 200 }}
    />
  );

  const content = (() => {
    if (!isFistTimeFetched)
      return <WidgetSkeletonContainer skeleton={skeleton} />;

    // Информация для пользователя, если данные запросили некорректно.
    if (alert)
      return <WidgetSkeletonContainer skeleton={skeleton} alert={alert} />;

    if (!widgetState) throw new Error('Неизвестная ошибка');

    return <DonutChartWidget {...props} {...widgetState} />;
  })();

  return (
    <WidgetContainer
      style={{ height }}
      showLoader={isFetching}
      heading={heading}
    >
      {content}
    </WidgetContainer>
  );
};

export default DonutChartWidgetDataLoader;
