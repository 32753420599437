import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

const SIZE = 5;

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: theme.spacing(SIZE),
      height: theme.spacing(SIZE),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },

      '&.Mui-disabled': {
        backgroundColor: theme.palette.divider,
      },

      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(20),
        // Визуальная компенсация
        marginLeft: 4,
        marginBottom: 1,
      },
    },
  })
);
