import React from 'react';
import { Divider } from '@material-ui/core';
import { File } from '../../../../../services/Main/types.Field';
import MessageContent from '../MessageContent';
import Attachment from '../Attachment';
import useStyles from './MessageAttachments.styles';

interface Props {
  attachments?: File[];
}

function MessageAttachments({ attachments }: Props) {
  const classes = useStyles();

  if (!attachments || attachments.length === 0) return null;

  return (
    <div>
      <Divider />
      <MessageContent>
        <div className={classes.attachments}>
          {attachments.map((attachment) => (
            <div className={classes.attachment}>
              <Attachment key={attachment.id} attachment={attachment} />
            </div>
          ))}
        </div>
      </MessageContent>
    </div>
  );
}

export default MessageAttachments;
