import React from 'react';
import { useDispatch } from 'react-redux';
import { ShowComponentAction } from '../../../services/Main/types.Component';
import { dialogWithComponentActions } from '../../../store/dialogWithComponent';
import ActionButton from '../ActionButton';
import Icon from '../Icon/Icon';

interface DialogComponentActionButtonProps {
  action: ShowComponentAction;
  onClick: () => void;
}

interface DialogComponentActionProps {
  action: ShowComponentAction;
  renderButton?: (props: DialogComponentActionButtonProps) => JSX.Element;
}
const DialogComponentAction = ({
  action,
  renderButton,
}: DialogComponentActionProps) => {
  const { viewStyle, component, variant, color, icon, label } = action;
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(
      dialogWithComponentActions.open({
        component,
        viewStyle,
      })
    );
  };

  return renderButton ? (
    renderButton({
      action,
      onClick: openDialog,
    })
  ) : (
    <ActionButton
      variant={variant}
      color={color}
      onClick={openDialog}
      startIcon={icon && <Icon definition={icon} />}
    >
      {label}
    </ActionButton>
  );
};

export default DialogComponentAction;
