import React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Breadcrumbs as BreadcrumbsProps } from '../../../services/Main/types.Page';
import BreadcrumbItem from './BreadcrumbItem';
import useStyles from './Breadcrumbs.styles';

interface Props {
  breadcrumbs: BreadcrumbsProps;
}

const Breadcrumbs = ({ breadcrumbs }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiBreadcrumbs
      separator={
        <NavigateNextIcon color="disabled" className={classes.separatorIcon} />
      }
      classes={{
        root: classes.root,
        separator: classes.separator,
      }}
      maxItems={isDesktop ? breadcrumbs.items.length : 3}
    >
      {breadcrumbs.items.map((breadcrumb) => (
        <BreadcrumbItem key={breadcrumb.url} breadcrumb={breadcrumb} />
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
