import React from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { Alert, AlertTitle } from '@material-ui/lab';
import PasswordField from 'components/lowLevel/PasswordField';
import { useFormatMessage } from '../../../locale';
import useStyles from './PasswordSetupPage.styles';
import { SetPasswordResponse } from '../../../services/Password/types';
import usePasswordValidationSchema from '../../../services/Password/hooks/usePasswordValidationSchema';

interface SetPasswordFormProps {
  isLoading: boolean;
  setPasswordResponse: SetPasswordResponse | null;
  onSubmit: ({ password }: UnpackNestedValue<{ password: string }>) => void;
}

const PasswordSetupPageForm = ({
  isLoading,
  setPasswordResponse,
  onSubmit,
}: SetPasswordFormProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const yupSchema = usePasswordValidationSchema();

  const formMethods = useForm({
    resolver: yupResolver(yupSchema),
  });
  const { register, handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card raised className={classes.card}>
          <Grid container>
            <Grid item xs={12} className={classes.marginBottom}>
              <Typography className={classes.title}>
                {formatMessage('settingAPassword')}
              </Typography>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justify="center"
              className={classes.marginBottom}
            >
              <Grid item xs={10} className={classes.marginBottom}>
                <Typography className={classes.infoTextHeader}>
                  {formatMessage('enterYouNewPasswordTitle')}
                </Typography>
                <Typography className={classes.infoText}>
                  {formatMessage('enterYouNewPasswordText')}
                </Typography>
              </Grid>
              <Grid item xs={10} className={classes.marginBottom}>
                <PasswordField
                  name="password"
                  label={formatMessage('newPassword')}
                  inputRef={register()}
                />
              </Grid>
              <Grid item xs={10} className={classes.marginBottom}>
                <PasswordField
                  name="passwordConfirmation"
                  label={formatMessage('repeatPassword')}
                  inputRef={register()}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Grid item xs={10} container justify="flex-end">
                {setPasswordResponse && !setPasswordResponse.isSuccess && (
                  <Alert className={classes.alert} severity="error">
                    {setPasswordResponse.payload.title && (
                      <AlertTitle>
                        {setPasswordResponse.payload.title}
                      </AlertTitle>
                    )}
                    {setPasswordResponse.payload.message}
                  </Alert>
                )}
                <Grid item>
                  <Box mb={2}>
                    <Button
                      disabled={isLoading}
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {formatMessage('send')}
                      {isLoading && (
                        <Box ml={1}>
                          <CircularProgress size="0.9rem" />
                        </Box>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </form>
    </FormProvider>
  );
};

export default PasswordSetupPageForm;
