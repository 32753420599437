import { useMemo } from 'react';
import { arrayToTree, TreeItem } from 'performant-array-to-tree';
import {
  CheckboxTreeSelectOptionWithClassName,
  TreeSelectOptionWithChildren,
} from '../../types';
import {
  CheckboxTreeSelectOption,
  RadioTreeSelectOption,
  TreeSelectOption,
} from '../../../../../services/Main/types.Field';
import highlightFoundedText from '../../helpers/highlightFoundedText';
import { Node } from './types';

export const transformLastChildrenToUndefined = (
  node: TreeSelectOptionWithChildren
): TreeSelectOptionWithChildren => {
  const showCheckbox = (node as RadioTreeSelectOption).hasOwnProperty(
    'selectable'
  )
    ? (node as RadioTreeSelectOption).selectable
    : undefined;

  if (node.children && node.children.length > 0) {
    return {
      ...node,
      showCheckbox,
      // @ts-ignore
      disabled: false, // запрещаем дисейблить отдельные options
      children: node.children.map(transformLastChildrenToUndefined),
    };
  }

  return {
    ...node,
    showCheckbox,
    // @ts-ignore
    disabled: false, // запрещаем дисейблить отдельные options
    children: undefined,
  };
};

export const groupOptionsByValue = (
  options: TreeSelectOption[]
): { [key: string]: TreeSelectOption } => {
  const result: { [key: string]: TreeSelectOption } = {};

  options.forEach((option) => {
    result[option.value] = option;
  });

  return result;
};

export const getParentValues = (
  options: TreeSelectOption[],
  value: string,
  excludeOptionValue?: boolean
): string[] => {
  const result: string[] = [];
  const option = options.find((o) => o.value === value);

  if (!option) {
    return result;
  }

  if (!excludeOptionValue) {
    result.push(option.value);
  }

  if (option.parentValue) {
    result.push(...getParentValues(options, option.parentValue));
  }

  return result;
};

export const determinateOptionsViewStyleReducer = (
  options: TreeSelectOption[],
  filterText: string
) => {
  return options.reduce(
    (acc, option) => {
      const result = { ...acc };

      // Поиск совпадения по filterText.
      if (option.label.toLowerCase().includes(filterText.toLowerCase())) {
        const highlightedOption = {
          ...option,
          label: highlightFoundedText(option.label, filterText) as string,
        };

        result.newOptions.push(highlightedOption);
        result.highlightedOptions.push(highlightedOption);
        return result;
      }

      result.newOptions.push(option);

      return result;
    },
    {
      newOptions: [] as TreeSelectOption[],
      highlightedOptions: [] as TreeSelectOption[],
    }
  );
};

// Функция, которая для переданной ноды собирает всех её детей и внуков и т.д.
export const getAllChildrenReducer = (
  acc: TreeItem[],
  node: TreeItem
): TreeItem[] => {
  if (node.children.length) {
    return [...acc, node, ...node.children.reduce(getAllChildrenReducer, [])];
  }

  return [...acc, node];
};

/**
 * Сбор объекта, типа:
 * { ComboBoxOption.value: TreeItem[] }
 *
 * Это объект в качестве ключа выступают value рутов, а в качестве значения
 * все дети и внуки.
 */
export const useGroupedNodesByRootValue = (defaultNodes: TreeItem[]) => {
  return useMemo(
    () =>
      defaultNodes.reduce((acc: Record<string, TreeItem[]>, node) => {
        if (node.children.length) {
          return {
            ...acc,
            [node.value]: node.children.reduce(getAllChildrenReducer, [node]),
          };
        }

        return {
          ...acc,
          [node.value]: [node],
        };
      }, {} as Record<string, TreeItem[]>),
    [defaultNodes]
  );
};

/**
 * Сбор объекта, типа:
 * { selectOption.value: selectOption.label[].join(' ') }
 *
 * Это объект в качестве ключа выступают value рутов, а в качестве значения
 * сконкатенированные через пробел все лейблы всех детей и внуков для каждого.
 *
 * Своего рода searchText для всего дерева.
 */
export const useGroupedTreeLabelsByRootValue = (
  groupedTreeByRootValue: Record<string, TreeItem[]>
) => {
  return useMemo(
    () =>
      Object.entries(groupedTreeByRootValue).reduce(
        (acc: Record<string, string>, [rootValue, currentTreeNodes]) => ({
          ...acc,
          [rootValue]: currentTreeNodes
            .map((n) => n.label.toLowerCase())
            .join(' '),
        }),
        {} as Record<string, string>
      ),
    [groupedTreeByRootValue]
  );
};

export const useDefaultExpanded = (
  value: TreeSelectOption[],
  options: TreeSelectOption[]
) => {
  return useMemo(
    () =>
      value
        .map((o) => getParentValues(options as TreeSelectOption[], o.value))
        .flat()
        // unique
        .filter((v, i, a) => a.indexOf(v) === i),
    [value, options]
  );
};

export const useDefaultNodes = (options: TreeSelectOption[]) => {
  return useMemo(
    () =>
      arrayToTree(options, {
        id: 'value',
        parentId: 'parentValue',
        dataField: null,
      }),
    [options]
  );
};

export const useGroupedOptionsByValue = (options: TreeSelectOption[]) => {
  return useMemo(() => groupOptionsByValue(options), [options]);
};

interface SelectableBranches {
  selectableBranches: CheckboxTreeSelectOptionWithClassName[];
  selectableBranchValues: string[];
}

const prefix = 'branch';

export const prepareTreeSelectOption = (
  options: CheckboxTreeSelectOption[]
): CheckboxTreeSelectOptionWithClassName[] => {
  const { selectableBranches, selectableBranchValues } =
    options.reduce<SelectableBranches>(
      (acc, option) => {
        if (option.isSelectableBranch) {
          acc.selectableBranchValues.push(option.value);

          acc.selectableBranches.push({
            ...option,
            label: `<${option.label}>`,
            parentValue: `${prefix}-${option.value}`,
            className: 'selectable-branch',
          });
        }

        return acc;
      },
      {
        selectableBranches: [],
        selectableBranchValues: [],
      }
    );

  const optionsWithChangedValue = options.reduce<CheckboxTreeSelectOption[]>(
    (acc, option) => {
      const newOption = { ...option };

      if (option.isSelectableBranch) {
        newOption.value = `${prefix}-${newOption.value}`;
      }

      if (
        option.parentValue &&
        selectableBranchValues.includes(option.parentValue)
      ) {
        newOption.parentValue = `${prefix}-${newOption.parentValue}`;
      }

      return [...acc, newOption];
    },
    []
  );

  return [...selectableBranches, ...optionsWithChangedValue];
};

interface TreeSplitterReturnType {
  leaves: string[];
  branches: string[];
}

export function treeSplitter(tree: TreeItem[]): TreeSplitterReturnType {
  return tree.reduce<TreeSplitterReturnType>(
    (acc, node) => {
      if (Array.isArray(node.children) && node.children.length > 0) {
        const childFoo = treeSplitter(node.children);

        acc.branches.push(node.value, ...childFoo.branches);
        acc.leaves.push(...childFoo.leaves);
      } else {
        acc.leaves.push(node.value);
      }

      return acc;
    },
    {
      leaves: [],
      branches: [],
    }
  );
}

export function useTreeSplitter(
  tree: TreeItem[],
  groupedOptionsByValue: ReturnType<typeof groupOptionsByValue>
) {
  return useMemo(() => {
    const { leaves, branches } = treeSplitter(tree);

    const leaveOptions = leaves.map((value) => groupedOptionsByValue[value]);

    return {
      branches,
      leaveOptions,
    };
  }, [groupedOptionsByValue, tree]);
}

export function collectLeafNodes(node: Node): Node[] {
  function traverse(nodes: Node[]): Node[] {
    return nodes.reduce((acc: Node[], current: Node) => {
      if (!current.children || current.children.length === 0) {
        acc.push(current);
      } else {
        acc = acc.concat(traverse(current.children));
      }
      return acc;
    }, []);
  }

  return traverse([node]);
}