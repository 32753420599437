import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      padding: theme.spacing(3, 1),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
    },
  })
);
