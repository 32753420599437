import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let File: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M13.3158 7.57812V2H4.10526C3.49276 2 3 2.43887 3 2.98437V22.0156C3 22.5611 3.49276 23 4.10526 23H19.5789C20.1914 23 20.6842 22.5611 20.6842 22.0156V8.5625H14.4211C13.8132 8.5625 13.3158 8.11953 13.3158 7.57812ZM20.6842 6.9998V7.25H14.7895V2H15.0704C15.3651 2 15.6461 2.10254 15.8533 2.28711L20.3618 6.30664C20.5691 6.49121 20.6842 6.74141 20.6842 6.9998Z" />
    <text
      x="50%"
      y="80%"
      textAnchor="middle"
      fontSize="5"
      fill="white"
      style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
    >
      {props.children}
    </text>
  </SvgIcon>
);
File.muiName = 'SvgIcon';

File = React.memo(File);
File.displayName = 'File';

const PureFile = File;

export default PureFile;
