import { createContext } from 'react';
import { GlobalSocketRules } from '../../../services/Main/types.Component';

type FormContextValue = {
  globalSocketRules?: GlobalSocketRules;
};

export const FormContext = createContext<FormContextValue>({
  globalSocketRules: {},
});
