import LogBase from './Log.base';
import { LoggerRequest } from '../Main/types';
import { apiClient } from '../Api';

class LogRemote implements LogBase {
  write = (payload: LoggerRequest) => {
    apiClient.post('/logger/v1.0', payload);
  };
}

export default LogRemote;
