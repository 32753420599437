import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
    },
    text: {
      whiteSpace: 'pre-wrap',
    },
  })
);
