import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { globalFilterAction } from '../../../store/globalFilter';

export default function useGlobalFilterCleaner() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(globalFilterAction.set(null));
    };
    // eslint-disable-next-line
  }, []);
}
