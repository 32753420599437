import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    container: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1),
      },
      '& .MuiTabScrollButton-root': {
        height: 36,
      },
    },
    cleanButton: {
      marginLeft: theme.spacing(1),
      borderRadius: 0,
    },
    tab: {
      borderRadius: 2,
      boxShadow: 'none',
      color: theme.additionalPalette.title,
      textTransform: 'none',
      background: 'rgba(9, 30, 66, 0.04)',
      marginRight: theme.spacing(1),

      '&:last-child': {
        marginRight: 0,
      },

      '&:hover': {
        boxShadow: 'none',
      },
      '&.active': {
        backgroundColor: theme.additionalPalette.title,
        color: 'rgb(244, 245, 247)',
      },
      '&.MuiTab-root': {
        minHeight: 36,
      },
    },
  })
);
