import React, { PropsWithChildren } from 'react';
import { Route as RouterRoute } from 'react-router-dom';
import { UnexpectedDataErrorBoundary } from 'utils/errors/boundaries/UnexpectedDataErrorBoundary';
import { Route } from '../../../services/Main/types';

export default ({
  path,
  exact,
  children,
}: PropsWithChildren<Omit<Route, 'pageId'>>) => (
  <RouterRoute path={path} exact={exact === true}>
    <UnexpectedDataErrorBoundary>{children}</UnexpectedDataErrorBoundary>
  </RouterRoute>
);
