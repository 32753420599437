import React, {
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
} from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useDispatch } from 'react-redux';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { DragStartEvent } from '@dnd-kit/core/dist/types';
import useStyles from './TableCellDragAndDropProvider.styles';
import useTableContext from '../../useTableContext';
import TableDragAndDropCellContext from './TableCellDragAndDrop.context';
import mainService from '../../../../../services/Main';
import reLoadCells, {
  UpdateCellsResponsePayload,
} from '../../../../../responseReactions/reLoadCells/reLoadCellsReaction';
import { alertsFromActionServerResponseActions } from '../../../../../store/alertsFromActionServerResponse';
import { metaActions } from '../../../../../store/meta';
import store from '../../../../../store';
import { ComponentContext } from '../../../../creational/ComponentLoader';
import useEnqueueSnackbar from '../../../../../utils/hooks/useEnqueueSnackbar';
import { DialogAlertContext } from '../../../../lowLevel/DialogAlert/DialogAlert';
import { TableOnDropRequestBody } from '../../../../../services/Main/types.Component';

const TableCellDragAndDropProvider = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const tableContext = useTableContext();
  const componentContext = useContext(ComponentContext);
  const dialogAlertContext = useContext(DialogAlertContext);
  const { dragNDropConfig } = tableContext;

  const [draggableItem, setDraggableItem] = useState<ReactElement | null>(null);
  const [loadingCellIds, setLoadingCellIds] = useState<string[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Параметр distance указывает минимальное расстояние, которое должно
        // быть пройдено указателем от момента mouseDown до того, как dnd будет
        // активирован. Это значение было выбрано на основании эксперимента
        // (идеальное значение в диапазоне от 5 до 10 пикселей).
        // Добавление этого параметра вызвано тем, что у элемента есть onClick
        // и эвенты dnd (onMouseDown, onMouseMove и т.д.) перебивали его.
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    if (!dragNDropConfig) return;
    const { requestConfig } = dragNDropConfig;

    const { active, over } = event;

    // Удаляем элемент, который перетаскивали. Это необходимо для того, чтобы
    // не было мерцания элемента, который перетаскивается.
    setDraggableItem(null);

    // Используется для определения того, что перетаскиваемый элемент находится
    // над другим элементом, и что этот элемент отличается от активного элемента
    // (т.е. того, который был начат перетаскивать).
    // - `over` - это элемент, над которым находится активный элемент в текущий
    // момент. Если перетаскиваемый элемент находится за пределами контейнера,
    // то значение `over` будет `null`.
    // - `active` - это активный элемент, т.е. тот, который переносится.
    if (over && active.id !== over.id) {
      setLoadingCellIds((prevState) => {
        return [...prevState, `${active.id}`, `${over.id}`];
      });

      const params: TableOnDropRequestBody = {
        from: {
          rowId: active.data.current?.rowId,
          columnName: active.data.current?.columnName,
          cell: active.data.current?.value,
        },
        to: {
          rowId: over.data.current?.rowId,
          columnName: over.data.current?.columnName,
          cell: over.data.current?.value,
        },
      };

      mainService
        .makeActionRequest<UpdateCellsResponsePayload>(requestConfig, params)
        .then(
          ({
            payload,
            snackbar,
            dialogAlert,
            preventSuccessResponseReactionReasons,
          }) => {
            if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
            if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

            const alertsId = `${componentContext.businessComponentId}_${componentContext.id}`;

            if (
              preventSuccessResponseReactionReasons &&
              Array.isArray(preventSuccessResponseReactionReasons)
            ) {
              dispatch(
                alertsFromActionServerResponseActions.setForPage({
                  id: alertsId,
                  alerts: preventSuccessResponseReactionReasons,
                })
              );
              dispatch(metaActions.setIsSomeActionExecuting(false));
              return;
            }

            if (
              store.getState().alertsFromActionServerResponse[alertsId]
                ?.length > 0
            ) {
              dispatch(alertsFromActionServerResponseActions.clear(alertsId));
            }

            reLoadCells(payload, tableContext);
          }
        )
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response?.data?.snackbar) {
              enqueueSnackbar(
                error.response?.data?.snackbar.text,
                error.response?.data?.snackbar.options
              );
            }

            if (error.response?.data?.dialogAlert) {
              if (error.response?.data?.dialogAlert)
                dialogAlertContext.setDialogState(
                  error.response.data.dialogAlert
                );
            }
          }

          console.log('Ошибка', error);
        })
        .finally(() => {
          setLoadingCellIds((prevState) =>
            prevState.filter((id) => id !== active.id && id !== over.id)
          );
        });
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    if (isValidElement(event.active.data.current?.element)) {
      setDraggableItem(event.active.data.current?.element as ReactElement);
    }
  };

  const handleDragCancel = () => {
    setDraggableItem(null);
  };

  return (
    <TableDragAndDropCellContext.Provider
      value={{ loadingCellIds, setLoadingCellIds }}
    >
      <DndContext
        sensors={sensors}
        modifiers={[snapCenterToCursor]}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        {children}
        {!!draggableItem && (
          <DragOverlay
            className={classes.draggableItemContainer}
            dropAnimation={undefined}
          >
            <div className={classes.draggableItem}>{draggableItem}</div>
          </DragOverlay>
        )}
      </DndContext>
    </TableDragAndDropCellContext.Provider>
  );
};

export default TableCellDragAndDropProvider;
