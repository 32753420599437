import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import MenuItemComponent from '../MenuItem';
import { LinkMenuItem } from '../../../services/Main/types';
import { useIsSideBarOpen } from '../../../utils/hooks/useIsSidebarOpen';
import { MainMenuProps } from '../../template/PrivateRoutesTemplate/MainMenu/types';
import useStyles from './MenuLink.styles';

interface OtherMenuLinkProps extends Pick<MainMenuProps, 'indentLevel'> {}

type MenuLinkProps = LinkMenuItem & OtherMenuLinkProps;

const MenuLink = ({ to, label, indentLevel }: MenuLinkProps) => {
  const classes = useStyles();
  const [, setOpen] = useIsSideBarOpen();

  const handleMenuItemClick = () => {
    setOpen(false);
  };

  return (
    <ListItem
      className={clsx(classes.listItem, 'hidden-compactView')}
      disableGutters
    >
      <MenuItemComponent
        key={to}
        to={to}
        pl={indentLevel || 0}
        onClick={handleMenuItemClick}
      >
        <ListItemText classes={{ primary: classes.label }} primary={label} />
      </MenuItemComponent>
    </ListItem>
  );
};

export default MenuLink;
