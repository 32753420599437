import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.pxToRem(12),
    },
  })
);
