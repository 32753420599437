import React from 'react';
import { List } from '@material-ui/core';
import clsx from 'clsx';
import MenuModule from 'components/lowLevel/MenuModule';
import MenuLink from 'components/lowLevel/MenuLink';
import MenuGrouper from 'components/lowLevel/MenuGrouper';
import { MainMenuProps } from './types';
import { MenuItemsGrouper } from '../../../../services/Main/types';
import { useIsCompactMenuView } from '../../../../utils/hooks/useIsCompactMenuView';
import useStyles from './MainMenu.styles';

const MainMenu = ({ items, indentLevel, isSubModule }: MainMenuProps) => {
  const classes = useStyles();
  const isCompactMenuView = useIsCompactMenuView();

  return (
    <List
      className={clsx(classes.mainMenu, {
        compactView: isCompactMenuView && !isSubModule,
      })}
      disablePadding
    >
      {items?.map((menuItem, index) => {
        switch (menuItem.type) {
          case 'module':
            return <MenuModule key={`module_${index}`} {...menuItem} />;

          case 'group':
            return (
              <MenuGrouper
                key={`grouper_${index}`}
                indentLevel={indentLevel}
                {...(menuItem as MenuItemsGrouper)}
              />
            );

          case 'link':
            return (
              <MenuLink
                key={`link_${index}`}
                indentLevel={indentLevel || 0}
                {...menuItem}
              />
            );
          default:
            throw new Error(
              `Не удалось определить тип пункта меню. Прилетело: ${JSON.stringify(
                menuItem,
                null,
                2
              )}`
            );
        }
      })}
    </List>
  );
};

export default MainMenu;
