export const getDynamicUrl = (
  urlTemplate: string,
  entity: any
): string | undefined => {
  // селектит что-то типа `:bar` из `//api/foo/:bar`
  const regExp = /:\w+/g;
  const matches = regExp.exec(urlTemplate);

  if (!matches) return undefined;
  if (typeof entity !== 'object') return undefined;

  // стучимся в переданный объект по ключу, который
  // пришёл из шаблона, получаем значение.
  const entityValue = entity[matches[0].slice(1)];

  if (!entityValue) return undefined;

  return urlTemplate.replace(regExp, entityValue);
};
