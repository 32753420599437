import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from 'theme';

export default (color: 'error' | 'success' | 'secondary') =>
  makeStyles((theme: Theme) =>
    createStyles({
      trend: {
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        color: theme.palette[color].dark,
        backgroundColor: fade(theme.palette[color].light, 0.15),
        overflowWrap: 'break-word',
      },
    })
  );
