import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let User: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
    <path
      d="M10.3 4C10.3 5.82278 8.82278 7.3 7 7.3C5.17722 7.3 3.7 5.82278 3.7 4C3.7 2.17722 5.17722 0.7 7 0.7C8.82278 0.7 10.3 2.17722 10.3 4ZM7 10.2C7.86163 10.2 8.68245 10.0194 9.42649 9.7H9.8C11.7322 9.7 13.3 11.2678 13.3 13.2V14.5C13.3 14.9415 12.9415 15.3 12.5 15.3H1.5C1.05847 15.3 0.7 14.9415 0.7 14.5V13.2C0.7 11.2678 2.26785 9.7 4.2 9.7H4.57402C5.31947 10.019 6.13748 10.2 7 10.2Z"
      fill="transparent"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
User.muiName = 'SvgIcon';

User = React.memo(User);
User.displayName = 'User';

export default User;
