import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export const getMenuItemStyles = (theme: Theme) => ({
  color: 'rgba(0, 0, 0, 0.54)',
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.shortest,
  }),
});

export default makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      ...getMenuItemStyles(theme),
      flexGrow: 1,
      whiteSpace: 'normal',
      paddingRight: theme.spacing(2),
      stroke: theme.additionalPalette.title,
      '&.open, &.open svg, &.active, &.active svg': {
        color: theme.additionalPalette.accentBlue,
        stroke: theme.additionalPalette.accentBlue,
      },
    },
  })
);
