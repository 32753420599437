import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { IconButton } from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import Box from '@material-ui/core/Box';
import useTableContext from '../../useTableContext';

const TruckIconCheckboxTypeProvider: FunctionComponent<DataTypeProvider.ValueFormatterProps> =
  ({ value, row, column }) => {
    const tableContext = useTableContext();

    const rowId = row.id;
    const columnKey = column.name;
    const { setRows } = tableContext;

    const toggleCell = () => {
      setRows((prevRows) =>
        prevRows.map((r) => {
          if (r.id === rowId) {
            return {
              ...r,
              [columnKey]: !r[columnKey],
            };
          }

          return r;
        })
      );
    };

    return (
      <IconButton onClick={toggleCell} color="primary">
        {value ? (
          <LocalShippingOutlinedIcon />
        ) : (
          <Box style={{ width: 24, height: 24 }} />
        )}
      </IconButton>
    );
  };

interface DateTypeProviderProps {
  for: string[];
}

const DateTypeProvider = (props: DateTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={TruckIconCheckboxTypeProvider}
    {...props}
  />
);

export default DateTypeProvider;
