import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      marginTop: theme.spacing(1),
      padding: 0,
      '&.compactView': {
        justifyContent: 'center',
      },
    },
    label: {
      color: theme.additionalPalette.title,
      fontSize: theme.typography.pxToRem(15),

      '&.compactView': {
        padding: theme.spacing(0, 1),
      },
    },
    iconButton: {
      color: theme.additionalPalette.title,

      '&.active': {
        color: theme.additionalPalette.accentBlue,
      },

      '&.fullView': {
        cursor: 'default',
        pointerEvents: 'none',
        backgroundColor: 'transparent',
        padding: theme.spacing(1),
        fontSize: theme.typography.pxToRem(20),
      },

      '&:hover, &.menuOpen': {
        color: theme.additionalPalette.title,
        backgroundColor: 'rgba(16, 156, 241, 0.1)',
        '&.active': {
          color: theme.additionalPalette.accentBlue,
        },
      },
    },
    wrapper: {
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    link: {
      flexGrow: 1,
      color: theme.additionalPalette.title,
      padding: theme.spacing(0, 1),

      '&.active, &.active *': {
        color: theme.additionalPalette.accentBlue,
      },

      '&:focus-visible': {
        outline: 'none',
        background: 'rgba(16, 156, 241, 0.1)',
      },
    },
    dropdownMenu: {
      minWidth: 240,
      width: 240,
      height: 240,
      overflowY: 'auto',
      padding: theme.spacing(1, 0),
    },
  })
);
