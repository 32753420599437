import { ChartDatum } from '../../../../services/Main/types.Component';
import getLongestWord from '../../../../utils/getLongestWord';
import measureSvgTextWidth from '../../../../utils/measureSvgTextWidth';

function useAxisWidthCalculate(data: ChartDatum[]) {
  // Поиск самого длинного слова среди всех name в данных
  const longestWord = data.reduce<string>((longest, item) => {
    const longestWordInItem = getLongestWord(item.name);
    return longest.length > longestWordInItem.length
      ? longest
      : longestWordInItem;
  }, '');

  // Получение стилей шрифта от body
  const bodyStyle = window.getComputedStyle(document.body);

  // Измеряем ширину самого длинного слова с использованием стилей шрифта от body
  return measureSvgTextWidth(
    longestWord,
    bodyStyle.fontSize,
    bodyStyle.fontFamily
  );
}

export default useAxisWidthCalculate;
