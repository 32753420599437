import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { FilterOptions } from '../../services/Main/types.Component';

export type GlobalFilterState = FilterOptions | null;

const set: CaseReducer<GlobalFilterState, PayloadAction<GlobalFilterState>> = (
  state,
  action
) => action.payload;

export const { reducer, actions } = createSlice({
  name: 'globalFilter',
  initialState: null as GlobalFilterState,
  reducers: {
    set,
  },
});

export default reducer;
