import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: theme.spacing(0.75),
    },
  })
);
