import { FormHistoryInterface } from './FormHistory.interface';

let FormHistoryService: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  FormHistoryService = require('./FormHistory.local').default;
} else {
  FormHistoryService = require('./FormHistory.remote').default;
}

const formHistoryService = new FormHistoryService() as FormHistoryInterface;

export default formHistoryService;
