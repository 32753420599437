import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { SelectOption, HighlightColor } from 'services/Main/types.Field';
import useStyles from '../../Select.styles';
import { InputProps as RenderInputProps } from '../sharedTypes';

interface OtherProps {
  highlightColor?: HighlightColor;
}

const MultiSelectInput = ({
  params,
  value,
  label,
  name,
  error,
  helperText,
  highlightColor,
}: RenderInputProps<SelectOption[] | null | undefined> & OtherProps) => {
  const classes = useStyles(highlightColor)();
  const { InputProps, ...restParams } = params;
  const { endAdornment, ...restInputProps } = InputProps;

  return (
    <TextField
      {...restParams}
      className={clsx(classes.autocomplete, classes.textField)}
      variant="standard"
      label={label}
      name={name}
      error={!!error}
      helperText={error?.message || helperText}
      InputProps={{
        ...restInputProps,
        endAdornment: (
          <InputAdornment position="end">
            {value && value?.length > 0 && (
              <Chip
                className={classes.chip}
                label={value.length}
                size="small"
              />
            )}
            {endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MultiSelectInput;
