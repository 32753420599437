import { useEffect, useRef, useState } from 'react';
import {
  SocketConfig,
  TimelineElement,
} from '../../../../services/Main/types.Component';
import TimelineService from '../../../../services/Timeline';

export default (timelineSocketConfig: SocketConfig) => {
  const [timelineElements, setTimelineElements] = useState<TimelineElement[]>(
    []
  );
  const [isTimelineInitialized, setIsTimelineInitialized] =
    useState<boolean>(false);

  const timelineService = useRef<TimelineService>();

  useEffect(() => {
    timelineService.current = new TimelineService(timelineSocketConfig);

    timelineService.current?.subscribeOnTimelineInitialized((elements) => {
      const sortedElements = [...elements].sort((a, b) => a.sort - b.sort);
      setIsTimelineInitialized(true);
      setTimelineElements(sortedElements); // Занимает больше всего времени
    });

    timelineService.current?.subscribeOnTimelineElementAdded((element) => {
      setTimelineElements((elements) =>
        [...elements, element].sort((a, b) => a.sort - b.sort)
      );
    });

    return () => {
      timelineService.current?.client.close();
    };
  }, [timelineSocketConfig]);

  return { timelineElements, isTimelineInitialized };
};
