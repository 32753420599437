import React from 'react';
import { useFormatMessage } from '../../../../locale';
import { getDonutTooltipData } from '../helpers/getDonutTooltipData';

type Props = {
  activeIndex?: number;
} & any;

function DonutTotalValue(props: Props) {
  const formatMessage = useFormatMessage();
  const { activeIndex } = props;

  // Получаем пропсы из группы секторов
  const { cx, cy, data } = props.formattedGraphicalItems[0].props;

  const { total } = getDonutTooltipData(data);

  if (typeof activeIndex === 'undefined') {
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={-5}
          textAnchor="middle"
          fill="rgba(0, 0, 0, 0.87)"
          fontSize="0.7rem"
        >
          {formatMessage('total')}
        </text>
        <text
          x={cx}
          y={cy}
          dy={18}
          textAnchor="middle"
          fontSize="1.2rem"
          fill="rgba(0, 0, 0, 0.87)"
        >
          {total}
        </text>
      </g>
    );
  }

  return null;
}

export default DonutTotalValue;
