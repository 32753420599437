import React, { PropsWithChildren } from 'react';
import { useSelector } from 'store';
import PreLoaderScreen from './PreLoaderScreen';

const PreLoaderScreenContainer = ({ children }: PropsWithChildren<{}>) => {
  const isAppLoading = useSelector(({ meta }) => meta.isAppLoading);

  return <PreLoaderScreen show={isAppLoading}>{children}</PreLoaderScreen>;
};

export default PreLoaderScreenContainer;
