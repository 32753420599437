import React from 'react';
import clsx from 'clsx';
import { Grid, Hidden, IconButton, Tooltip } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { useDispatch } from 'react-redux';
import Logo from 'components/lowLevel/Logo';
import useStyles from './DrawerHeader.styles';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';
import { metaActions } from '../../../store/meta';
import { useFormatMessage } from '../../../locale';

const DrawerHeader = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const isCompactMenuView = useIsCompactMenuView();

  const handleToggleMenuView = () => {
    dispatch(metaActions.setIsCompactMenuView(!isCompactMenuView));
  };

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={clsx(classes.container, { compactView: isCompactMenuView })}
    >
      <Hidden xsDown>
        <Grid item>
          <Tooltip
            title={
              isCompactMenuView
                ? formatMessage('expandMenuTooltip')
                : formatMessage('collapseMenuTooltip')
            }
            placement="right"
          >
            <IconButton
              className={clsx(classes.menuViewIcon, {
                compactView: isCompactMenuView,
              })}
              onClick={handleToggleMenuView}
            >
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Hidden>
      <Grid item className={classes.logoWrapper}>
        <Logo />
      </Grid>
    </Grid>
  );
};

export default DrawerHeader;
