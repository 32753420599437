import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CardHeader, Grid, Typography } from '@material-ui/core';
import useStyles from './WidgetContainer.styles';

interface WidgetContainerProps {
  style?: CSSProperties;
  heading?: string;
  showLoader?: boolean;
  footer?: ReactNode;
}

function WidgetContainer({
  style,
  heading,
  showLoader,
  children,
  footer,
}: PropsWithChildren<WidgetContainerProps>) {
  const classes = useStyles();

  return (
    <div style={{ height: '100%' }}>
      {heading && (
        <CardHeader
          classes={{ root: classes.root }}
          title={
            <Grid container wrap="wrap">
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {heading}
                  {showLoader && (
                    <CircularProgress size={12} style={{ marginLeft: 8 }} />
                  )}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
      <div style={style}>{children}</div>
      {footer}
    </div>
  );
}

export default WidgetContainer;
