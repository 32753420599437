import { RefObject, useEffect, useRef, useState } from 'react';

function useScrollDetector(ref: RefObject<HTMLDivElement>): boolean {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (ref.current) {
        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current);
        }

        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        setIsScrolling(
          scrollHeight > clientHeight &&
            scrollTop > 0 &&
            scrollTop + clientHeight < scrollHeight
        );
        scrollTimeout.current = setTimeout(() => {
          setIsScrolling(false);
        }, 100);
      }
    };

    if (ref.current) {
      scrollHandler();
      ref.current.addEventListener('scroll', scrollHandler);
    }
  }, [ref]);

  return isScrolling;
}

export default useScrollDetector;
