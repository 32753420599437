type UseScrollToReturn<T> = (ref: T) => void;

/**
 * Возвращает функцию, которая принимает реф (ссылку на элемент).
 * Если isScrollTo === true прокручивает контейнер родителя до
 * активного ref.
 */
export default <T extends HTMLElement>(
  shouldScroll: boolean
): UseScrollToReturn<T> => {
  return (ref: T) => {
    if (ref && shouldScroll) {
      ref.scrollIntoView({
        block: 'nearest',
      });
    }
  };
};
