import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import useStyles from './ActionButton.styles';

const ActionButton = (props: ButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      classes={{
        iconSizeMedium: classes.iconSizeMedium,
        label: classes.buttonLabel,
      }}
    />
  );
};

export default ActionButton;
