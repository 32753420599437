import React, { useState } from 'react';
import { BarChartProps } from '../../../services/Main/types.Component';
import BarChartWidget from './BarChartWidget';
import getMinHeight from '../DonutChartWidget/helpers/getMinHeight';
import useWidgetDataQuery, {
  checkEmptyStateFn,
} from '../../../utils/hooks/useWidgetDataQuery';
import { BarChartState } from './types';
import WidgetContainer from '../../lowLevel/WidgetContainer';
import ChartSkeleton from '../../../svg/skeletons/ChartSkeleton';
import WidgetSkeletonContainer from '../../lowLevel/WidgetSkeletonContainer';
import BarLegend from './Legend';

const checkEmptyState: checkEmptyStateFn<BarChartState> = (payload) => {
  return (
    payload.data.length === 0 ||
    !Array.isArray(payload.data) ||
    payload.bars.length === 0 ||
    !Array.isArray(payload.bars)
  );
};

const BarChartWidgetDataLoader = (props: BarChartProps) => {
  const { heading, showLegend, requestConfig, height: propsHeight } = props;

  const height = getMinHeight(propsHeight || 300, !!heading, true);

  const { isFetching, isFistTimeFetched, widgetState, alert } =
    useWidgetDataQuery<BarChartState>(requestConfig, checkEmptyState);

  const [hiddenBarKeys, setHiddenBarKeys] = useState<string[]>([]);

  const skeleton = (
    <ChartSkeleton
      className={!isFistTimeFetched ? 'animate-flicker' : undefined}
    />
  );

  const { footer, content } = (() => {
    if (!isFistTimeFetched)
      return {
        footer: null,
        content: <WidgetSkeletonContainer skeleton={skeleton} />,
      };

    // Информация для пользователя, если данные запросили некорректно.
    if (alert)
      return {
        footer: null,
        content: <WidgetSkeletonContainer skeleton={skeleton} alert={alert} />,
      };

    if (!widgetState) throw new Error('Неизвестная ошибка');

    return {
      footer: showLegend ? (
        <BarLegend
          {...widgetState}
          hiddenBarKeys={hiddenBarKeys}
          setHiddenBarKeys={setHiddenBarKeys}
        />
      ) : null,
      content: (
        <BarChartWidget
          {...props}
          {...widgetState}
          hiddenBarKeys={hiddenBarKeys}
          height={height}
        />
      ),
    };
  })();

  return (
    <WidgetContainer
      style={{ height }}
      showLoader={isFetching}
      heading={heading}
      footer={footer}
    >
      {content}
    </WidgetContainer>
  );
};

export default BarChartWidgetDataLoader;
