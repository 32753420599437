import { TableContextValue } from '../../components/highLevel/Table/TableContext';
import { TableCell } from '../../services/Main/types.Component';
import store from '../../store';
import { metaActions } from '../../store/meta';

export interface UpdateCellResponsePayload {
  rowId: string | number;
  columnName: string;
  cell: TableCell;
}

export interface UpdateCellsResponsePayload {
  cells: UpdateCellResponsePayload[];
}

/**
 * Функция для реализации обновления данных ячейки Table
 */
const reLoadCells = (
  payload: UpdateCellsResponsePayload,
  tableContextValue?: TableContextValue
) => {
  if (!tableContextValue) throw new Error('Не удалось получить tableContext');

  const { rowsFromState, setRows } = tableContextValue;
  const { cells } = payload;

  // Группируем данные для обновления ячеек по rowId
  const updateCellsByRowId = cells.reduce(
    (acc, { rowId, cell, columnName }) => {
      if (!acc[rowId]) {
        acc[rowId] = {};
      }

      acc[rowId][columnName] = cell;

      return acc;
    },
    {} as Record<string, Record<string, TableCell>>
  );

  const newRows = rowsFromState.map((r) => {
    if (updateCellsByRowId[r.id]) {
      return {
        ...r,
        ...updateCellsByRowId[r.id],
      };
    }

    return r;
  });

  store.dispatch(metaActions.setIsSomeActionExecuting(false));
  setRows(newRows);
};
export default reLoadCells;
