import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    container: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',

      // for smooth animation on change view
      flexDirection: 'row',
      flexWrap: 'nowrap',

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },

      '&.compactView': {
        padding: theme.spacing(1, 0),
      },
    },
    menuViewIcon: {
      margin: theme.spacing(0, 1),

      '& .MuiSvgIcon-root': {
        transition: theme.transitions.create('transform', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      '&.compactView': {
        margin: theme.spacing(0, 0.75),
        '& .MuiSvgIcon-root': {
          transform: 'rotate(-180deg)',
        },
      },
    },
    logoWrapper: {
      flexGrow: 1,
    },
  })
);
