import React, { PropsWithChildren } from 'react';
import { Box } from '@material-ui/core';

const TableContainer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box flexGrow={1} width="100%">
      {children}
    </Box>
  );
};

export default TableContainer;
