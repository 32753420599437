import { TimelineElement } from '../../../../../../services/Main/types.Component';

function getAuthorFullName(timelineElement?: TimelineElement) {
  if (!timelineElement) return undefined;

  if (timelineElement.type === 'system') {
    return 'Keep Up';
  }

  const {
    author: { lastName, secondName, firstName },
  } = timelineElement;

  return [lastName, firstName, secondName].filter(Boolean).join(' ');
}

export default getAuthorFullName;
