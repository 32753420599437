import React from 'react';
import clsx from 'clsx';
import { Tabs, Tab, Button } from '@material-ui/core';
import {
  TableQuickFilterOption,
  TableQuickFilters,
} from '../../../services/Main/types.Component';
import useStyles from './TableQuickFilters.styles';
import { useFormatMessage } from '../../../locale';
import useTableContext from '../../highLevel/Table/useTableContext';

export default ({ options }: TableQuickFilters) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { quickFiltersState, onQuickFiltersChange } = useTableContext();

  const hasFilter =
    Array.isArray(quickFiltersState) && quickFiltersState.length !== 0;

  const cleanFilters = () => {
    onQuickFiltersChange([]);
  };

  return (
    <div className={classes.wrapper}>
      <Tabs
        variant="scrollable"
        textColor="primary"
        value={false} // см. аннотацию свойства
        className={classes.container}
      >
        {options.map(TableQuickFilter)}
      </Tabs>
      {hasFilter && (
        <Button
          className={classes.cleanButton}
          variant="text"
          onClick={cleanFilters}
        >
          {formatMessage('reset')}
        </Button>
      )}
    </div>
  );
};

function TableQuickFilter({ value, label }: TableQuickFilterOption) {
  const classes = useStyles();
  const { quickFiltersState, onQuickFiltersChange } = useTableContext();
  const isActive = quickFiltersState.some((v) => value === v);

  const handleClick = () => {
    if (quickFiltersState.includes(value)) {
      onQuickFiltersChange(quickFiltersState.filter((f) => f !== value));
      return;
    }

    onQuickFiltersChange([...quickFiltersState, value]);
  };

  return (
    <Tab
      key={value}
      className={clsx(classes.tab, {
        active: isActive,
      })}
      label={label}
      onClick={handleClick}
    />
  );
}
