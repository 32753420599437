export function serializeObjectToUrl(obj: any, prefix?: string): string {
  const str = [];
  let p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];

      str.push(
        v !== null && typeof v === 'object'
          ? serializeObjectToUrl(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
    }
  }

  return str.join('&');
}

// Из { foo: 'bar', baz: 'buzz' }
// в "?foo=bar&baz=buzz"
export function stringifyParams(params: object): string {
  const entries = Object.entries(params);

  if (entries.length === 0) return '';

  return entries.reduce((acc, [key, value]) => {
    return acc === '?' ? `${acc}${key}=${value}` : `${acc}&${key}=${value}`;
  }, '?');
}

// Из { foo: 'bar', baz: 'buzz' }
// в /bar/buzz
export function stringifyParamsValues(params: object) {
  const values = Object.values(params);

  if (values.length === 0) return '';

  return values.reduce((acc, val) => {
    return acc === '' ? `/${val}/` : `${acc}/${val}`;
  }, '');
}
