export const moveItemsInArray = <T>(
  data: (T | undefined)[],
  from: number,
  to: number
): (T | undefined)[] => {
  if (Array.isArray(data)) {
    if (typeof data[to] === 'undefined') {
      data[to] = undefined;
    }
    data.splice(to, 0, data.splice(from, 1)[0]);
    return data;
  }

  return [];
};
