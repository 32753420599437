import { useEffect, useMemo, useState } from 'react';
import { ArrayField, useFormContext } from 'react-hook-form';
import {
  DirtyFormValues,
  SortingObject,
} from '../../../../services/Main/types.Component';
import { Field } from '../../../../services/Main/types.Field';
import {
  preparedSortingArrayOf,
  sortArrayOfByColumn,
} from '../helpers/sortArrayOfByColumn';
import useFormErrors from './useFormErrors';
import { getArrayOfFieldNames } from '../helpers/getArrayOfFieldNames';

interface Props {
  name: string;
  rowDefinition: Field[];
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  defaultSorting?: SortingObject;
}

/**
 *   Есть след. поля:
 *   case 'comboBox':
 *   case 'entryPicker':
 *   case 'datePicker':
 *   case 'formattedNumber':
 *   case 'text':
 *   - case 'checkbox': (Заменен на comboBox с Да/Нет)
 *   - case 'radio': (Нет кейсов)
 *   - case 'select': (Устарело)
 *   - case 'file': (Нет кейсов)
 *   - case 'arrayOf': (Нет кейсов)
 *
 *   Правила:
 *   1. Сортировать только поля со значениями! Пустые поля всегда снизу.
 *
 *   Алгоритм:
 *   1. Создаём стейт `currentSortColumn` в котором храним текущую выбранную
 *   колонку для сортировки;
 *
 *   2. Пробегаемся по `rowDefinition` и собираем коллекцию `fieldByName`, где
 *   ключ — системное имя поля, а значение его пропсы;
 *
 *   3. Создаём функцию `changeSortingArrayOf`:
 *      3.1. Получаем текущее значение ArrayOf (используя метод `getValues`
 *      из RHF);
 *      3.2. Получаем пропсы поля, по которому происходит сортировка, используя
 *      коллекцию `fieldByName`:
 *      3.3 Если arrayOf пустой или такого поля не существует в коллекции,
 *      то выходим из функции (return);
 *      3.4. Изменяем стейт с текущим статусом сортировки `currentSortColumn`
 *      3.5. Собираем два массива, используя функцию `preparedSortingArrayOf`:
 *        - `filledArrayOfFields` - Массив строк (с непустыми полями);
 *        - `emptyArrayOfFields` - Массив строк (с пустыми полями).
 *      3.6. Если filledArrayOfFields пустой, то выходим из функции (return);
 *      3.7. Сортируем поля используя функцию `sortArrayOfByColumn`;
 *      3.8. Собираем новое value для ArrayOf из отсортированных (заполненых)
 *      и пустых полей;
 *      3.9. Устанавливаем новое значения для ArrayOf (используя метод
 *      `setValues` из RHF);
 *
 *   4. Создаем функцию `handleSortingChange`:
 *      4.1 Собираем новый статус сортировки `newCurrentSortColumn`:
 *      - Если `currentSortColumn` не пуст и он совпадает с именем колонки,
 *      по которой кликнул пользователь, то меняем направление сортировки
 *      на противоположное;
 *      - Иначе создаём новый статус с сортировкой по убыванию.
 *      4.2 Вызываем функцию `changeSortingArrayOf` с новым статусом сортировки;
 *
 *   Оптимизаци алгоритма сортировки:
 *   1. После повторного нажатия на колонку не пробегаться повторно, а вызывать
 *   `revense()` у массива.
 *
 */

const useSorting = ({ name, rowDefinition, fields, defaultSorting }: Props) => {
  const { getValues, setValue, trigger, errors } = useFormContext();
  const [currentSortColumn, setCurrentSortColumn] =
    useState<SortingObject | null>(null);

  const { clearErrors } = useFormErrors();

  const fieldByName = useMemo(
    () => new Map([...rowDefinition].map((field) => [field.name, field])),
    [rowDefinition]
  );

  const changeSortingArrayOf = (
    sortingConfig: SortingObject,
    shouldValidate?: boolean
  ) => {
    // Получаем текущее значение ArrayOf
    const arrayOfValues: DirtyFormValues[] = getValues()[name];
    // Получаем пропсы поля, по которому происходит сортировка
    const field = fieldByName.get(sortingConfig.columnName);

    // Если arrayOf пустой, то ничего не делаем
    if (!Array.isArray(arrayOfValues) || arrayOfValues.length === 0 || !field) {
      return;
    }

    // Изменяем текущий статус сортировки
    setCurrentSortColumn(sortingConfig);

    // Собираем два массива:
    // filledArrayOfFields - Массив строк (с непустыми полями);
    // emptyArrayOfFields - Массив строк (с пустыми полями).
    const { emptyArrayOfFields, filledArrayOfFields } = preparedSortingArrayOf({
      field,
      arrayOfValues,
      sortingConfig,
    });

    // Если filledArrayOfFields пустой, то не сортируем и не подставляем новое
    // значение ArrayOf.
    if (filledArrayOfFields.length !== 0) {
      // Отсортированные заполненные поля ArrayOf
      const sortableArrayOfFields = sortArrayOfByColumn({
        field,
        arrayOfFields: filledArrayOfFields,
        sortingConfig,
      });

      // Собираем новое value для ArrayOf
      // Из отсортированных (заполненных) полей и пустых.
      const newArrayOfValues = [
        ...sortableArrayOfFields,
        // По ФТ пустые поля всегда снизу
        ...emptyArrayOfFields,
      ];

      // setValue принимает конфиг в котором можно указать shouldValidate: true,
      // что значит "Перепроверь поля, которые подставил", однако
      // он не работает в ArrayOf.
      Promise.resolve(setValue(name, newArrayOfValues)).then(() => {
        if (shouldValidate && Object.keys(errors).length > 0) {
          const fieldNames = getArrayOfFieldNames(name, fields);

          clearErrors(...fieldNames);

          trigger(fieldNames).then();
        }
      });
    }
  };

  const handleSortingChange = (columnName: string) => () => {
    let newCurrentSortColumn: SortingObject | null;

    if (currentSortColumn && currentSortColumn.columnName === columnName) {
      newCurrentSortColumn = {
        ...currentSortColumn,
        direction: currentSortColumn.direction === 'asc' ? 'desc' : 'asc',
      };
    } else {
      newCurrentSortColumn = {
        columnName,
        direction: 'asc',
      };
    }

    changeSortingArrayOf(newCurrentSortColumn, true);
  };

  useEffect(() => {
    if (defaultSorting) {
      changeSortingArrayOf(defaultSorting);
    }

    // eslint-disable-next-line
  }, []);

  return {
    handleSortingChange,
    currentSortColumn,
    setCurrentSortColumn,
  };
};

export default useSorting;
