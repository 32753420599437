import { useContext, useMemo } from 'react';
import EntryPickerTableContext from '../EntryPickerTable.context';

export interface SortingColumnExtension {
  columnName: string;
  sortingEnabled: boolean;
}

export function useSorting() {
  const { tableProps } = useContext(EntryPickerTableContext);

  const sortingStateColumnExtensions = useMemo(
    () =>
      (tableProps?.columns || []).reduce(
        (acc, column) => [
          ...acc,
          {
            columnName: column.name,
            sortingEnabled: !!column.options?.sortable,
          },
        ],
        [] as SortingColumnExtension[]
      ),
    [tableProps]
  );

  const someColumnSortable = useMemo(
    () =>
      tableProps && Array.isArray(tableProps.columns)
        ? tableProps.columns.some((column) => column.options?.sortable)
        : false,
    [tableProps]
  );

  return {
    sortingStateColumnExtensions,
    someColumnSortable,
  };
}
