import React from 'react';
import { useController } from 'react-hook-form';
import { IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Icon from '../../../../../lowLevel/Icon/Icon';
import useStyles from './PrivacyToggleButton.styles';
import { useFormatMessage } from '../../../../../../locale';

interface Props {
  name: string;
}

function PrivacyToggleButton({ name }: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const {
    field: { value, onChange },
  } = useController({ name, defaultValue: false });

  const handleClick = () => {
    onChange(!value);
  };

  const icon = value ? 'mui:LockOutlined' : 'mui:LockOpenOutlined';
  const tooltipText = value
    ? formatMessage('messageVisibleOnlyEmployees')
    : formatMessage('messageVisibleEveryone');

  return (
    <Tooltip
      title={tooltipText}
      arrow
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <IconButton
        className={clsx(classes.button, { active: value })}
        onClick={handleClick}
      >
        <Icon definition={icon} />
      </IconButton>
    </Tooltip>
  );
}

export default PrivacyToggleButton;
