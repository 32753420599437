import { RenderPageReaction } from 'services/Main/types.Component';
import { Page } from '../../services/Main/types.Page';
import { PageContext } from '../../components/creational/PageLoader/PageLoader';

export default (
  reaction: RenderPageReaction,
  pageContext: PageContext,
  payload?: unknown
) => {
  // Или нужно потому, что typeof null === 'object'
  if (typeof payload !== 'object' || payload === null)
    throw new Error(
      'С сервера вернулся некорректный ответ для renderPageReaction.'
    );

  pageContext?.setPage(payload as Page);
};
