import React from 'react';
import TimelineElement from '../TimelineElement';
import TimelineScrollToBottom from '../TimelineScrollToBottom';
import useTimelineContext from '../../hooks/useTimelineContext';
import useStyles from './TimelineElements.styles';

function TimelineElements() {
  const classes = useStyles();

  const { timelineElements, timelineElementsContainer } = useTimelineContext();

  return (
    <div className={classes.elementsContainer} ref={timelineElementsContainer}>
      <div className={classes.elementsList}>
        {timelineElements.map((timelineElementProps) => (
          <TimelineElement
            key={timelineElementProps.id}
            {...timelineElementProps}
          />
        ))}
      </div>
      <TimelineScrollToBottom />
    </div>
  );
}

export default TimelineElements;
