import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      // TODO передать сверху. Для Donut flex-end, для TableWidget flex-start...
      alignItems: 'center',
    },
    alertWrapper: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -54%)',
    },
  })
);
