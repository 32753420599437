import React, { MouseEvent, useContext, useState } from 'react';
import {
  Menu,
  MenuItem,
  Tooltip,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import useStyles from '../../EntryPickerTable.styles';
import { useFormatMessage } from '../../../../../../../locale';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import Icon from '../../../../../Icon';

const EntryPickerTableColumnVisibilityButton = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const { tableProps, setHiddenColumnNames, hiddenColumnNames } = useContext(
    EntryPickerTableContext
  );

  const isMenuOpen = Boolean(dropdownAnchorEl);

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  if (!tableProps) return null;

  const handleHiddenColumnNameChange = (columnName: string) => {
    return () => {
      setHiddenColumnNames((prevState) =>
        prevState.includes(columnName)
          ? prevState.filter((name) => name !== columnName)
          : [...prevState, columnName]
      );
    };
  };

  return (
    <>
      <Tooltip title={formatMessage('columnVisibilityControl')}>
        <IconButton onClick={handleIconClick}>
          <Icon definition="mui:VisibilityOff" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={dropdownAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {tableProps.columns.map(({ name, title }, index) => (
          <MenuItem
            key={`${name}_${index}`}
            onClick={handleHiddenColumnNameChange(name)}
            className={classes.menuItem}
            dense
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                name={name}
                className={classes.checkbox}
                size="small"
                checked={!hiddenColumnNames.includes(name)}
                color="primary"
              />
            </ListItemIcon>
            <ListItemText>{title || name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EntryPickerTableColumnVisibilityButton;
