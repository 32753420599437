import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
} from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { yupResolver } from '@hookform/resolvers';
import { Alert, AlertTitle } from '@material-ui/lab';
import Header from '../../components/lowLevel/Header';
import { useFormatMessage } from '../../locale';
import usePasswordValidationSchema from '../../services/Password/hooks/usePasswordValidationSchema';
import useStyles from './ProfilePage.styles';
import passwordService from '../../services/Password';
import { ChangePasswordResponse } from '../../services/Password/types';
import PasswordField from '../../components/lowLevel/PasswordField';
import useEnqueueSnackbar from '../../utils/hooks/useEnqueueSnackbar';

/**
 * Алгоритм работы:
 *
 * 1. + Авторизованный пользователь в профиле нажимает на "сменить пароль" и попадает в диалог.
 * 2. + В диалоге пользователь вводит старый пароль, новый пароль и подтверждение пароля.
 * 3. + Пароль валидируется при помощи yup.string().validatePassword().
 * 4. + При успешной проверке происходит отправка формы на бек (старй пароль, новый пароль, авторизационный токен).
 * 5. После успешной отправки возможны следующие сценарии:
 *  - + Если пароль успешно изменен, диалог закрывается, вываливается снекбар с текстом успешности.
 *  - + Если текущий пароль был введён некорректно, то выводится ошибка (модалка не закрывается).
 *  - + Если по каким-то другим причинам (серверная валидация или внештатная ситуация) пароль не был изменён,
 *  то выводится ошибка (модалка не закрывается).
 *
 */
export default () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const enqueueSnackbar = useEnqueueSnackbar();
  const yupBaseSchema = usePasswordValidationSchema();
  const yupSchema = yupBaseSchema.shape({
    currentPassword: yup.string().required(formatMessage('requiredField')),
  });

  const formMethods = useForm({
    resolver: yupResolver(yupSchema),
  });
  const { register, handleSubmit } = formMethods;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [changePasswordResponse, setChangePasswordResponse] =
    useState<ChangePasswordResponse | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Типизация UnpackNestedValue<{ currentPassword: string, newPassword: string }>
  // или UnpackNestedValue<Record<string, any>>
  const onSubmit = ({
    currentPassword,
    password,
  }: UnpackNestedValue<{
    currentPassword: string;
    password: string;
  }>) => {
    setIsLoading(true);

    passwordService.change({ password, currentPassword }).then((response) => {
      setIsLoading(false);

      if (response.isSuccess) {
        setIsOpen(false);
        enqueueSnackbar(response.payload.message, {
          variant: 'success',
          autoHideDuration: 30000,
        });
        return;
      }

      setChangePasswordResponse(response);
    });
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpenDialog}>
        {formatMessage('changePassword')}
      </Button>
      <Dialog open={isOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>
          <Header heading={formatMessage('settingAPassword')} />
        </DialogTitle>
        <DialogContent>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <PasswordField
                      name="currentPassword"
                      label={formatMessage('currentPassword')}
                      variant="standard"
                      inputRef={register()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordField
                      name="password"
                      label={formatMessage('newPassword')}
                      variant="standard"
                      inputRef={register()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordField
                      name="passwordConfirmation"
                      label={formatMessage('repeatPassword')}
                      variant="standard"
                      inputRef={register()}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={1} justify="flex-end">
                {changePasswordResponse && !changePasswordResponse.isSuccess && (
                  <Alert className={classes.alert} severity="error">
                    {changePasswordResponse.payload.title && (
                      <AlertTitle>
                        {changePasswordResponse.payload.title}
                      </AlertTitle>
                    )}
                    {changePasswordResponse.payload.message}
                  </Alert>
                )}
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCloseDialog}
                    disabled={isLoading}
                  >
                    {formatMessage('cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    type="submit"
                  >
                    {formatMessage('set')}
                    {isLoading && (
                      <Box ml={1}>
                        <CircularProgress size="0.9rem" />
                      </Box>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
