import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    OtherUserTimelineElementAvatar: {
      cursor: 'pointer',
      fontSize: theme.typography.pxToRem(16),

      [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
        fontSize: theme.typography.pxToRem(11),
      },
    },
  })
);
