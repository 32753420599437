import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import mainService from 'services/Main';
import { AsyncSelect, SelectOption } from 'services/Main/types.Field';
import useStyles from '../Select.styles';
import { useFormatMessage } from '../../../../locale';

interface OtherProps {
  value?: any;
  onChange?: any;
}

export default ({
  disabled,
  label,
  searchOptionsRequestConfig,
  value,
  helperText,
  onChange,
  name,
  highlightColor,
}: AsyncSelect & OtherProps) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles(highlightColor)();
  const { errors } = useFormContext();
  const error = get(errors, name);
  const [isOptionsFetched, setIsOptionsFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectOption[]>([]);

  const handleOpen = () => {
    if (isOptionsFetched) return;

    setIsLoading(true);
    mainService
      .makeActionRequest<SelectOption[]>(searchOptionsRequestConfig)
      .then(({ payload: newOptions }) => {
        setOptions(newOptions);
        setIsLoading(false);
        setIsOptionsFetched(true);
      });
  };

  const handleChange = (_event: unknown, option: SelectOption) => {
    onChange(option);
  };

  return (
    <Autocomplete
      onOpen={handleOpen}
      className={classes.autocompleteRoot}
      loading={isLoading}
      loadingText={formatMessage('loading')}
      noOptionsText={formatMessage('noOptions')}
      clearText={formatMessage('clear')}
      closeText={formatMessage('close')}
      openText={formatMessage('open')}
      classes={{
        loading: 'animate-flicker',
      }}
      disabled={disabled}
      value={value || null}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      getOptionDisabled={(o) => o.disabled}
      options={options}
      autoComplete
      includeInputInList
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          variant="standard"
          label={label}
          name={name}
          helperText={error?.message || helperText}
          error={!!error}
        />
      )}
    />
  );
};
