import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      padding: theme.spacing(0.5, 2),
    },
    listItemIcon: {
      minWidth: 0,
    },
    checkbox: {
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  })
);
