import React, { Ref, useCallback, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles from './PasswordField.styles';
import { useFormatMessage } from '../../../locale';

interface PasswordFieldProps {
  name: string;
  label: string;
  inputRef: Ref<{}>;
  variant?: 'outlined' | 'standard';
}

const PasswordField = ({
  name,
  label,
  inputRef,
  variant = 'outlined',
}: PasswordFieldProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { errors } = useFormContext();

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const toggleIsPasswordVisible = useCallback(() => {
    setIsPasswordVisible((prevState) => !prevState);
  }, []);

  return (
    <TextField
      size="small"
      color="primary"
      variant={variant}
      name={name}
      label={
        <>
          <span style={{ color: 'red' }}>*</span>
          {label}
        </>
      }
      type={isPasswordVisible ? 'text' : 'password'}
      fullWidth
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={
                isPasswordVisible
                  ? formatMessage('hidePassword')
                  : formatMessage('showPassword')
              }
            >
              <IconButton onClick={toggleIsPasswordVisible} size="small">
                {isPasswordVisible ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      FormHelperTextProps={{
        classes: {
          error: classes.formHelperTextError,
        },
      }}
      error={!!errors[name]}
      helperText={errors[name] && errors[name].message}
    />
  );
};

export default PasswordField;
