import React from 'react';
import { TimelineContext } from './TimelineContext';
import Timeline from './Timeline';
import {
  TimelineElement,
  TimelineComponentProps,
} from '../../../services/Main/types.Component';
import useTimeline from './hooks/useTimeline';
import useScrollManager from './hooks/useScrollManager';
import useAddTimelineElementForm from './hooks/useAddTimelineElementForm';

const TimelineContainer = (props: TimelineComponentProps) => {
  const { timelineSocketConfig, addCommentUrl } = props;

  const { timelineElements, isTimelineInitialized } =
    useTimeline(timelineSocketConfig);

  const getTimelineElementById = (id: TimelineElement['id']) => {
    return timelineElements.find((element) => element.id === id);
  };

  const { timelineElementsContainer, timelineScrollManager } =
    useScrollManager();

  const { quote, setQuote } = useAddTimelineElementForm(addCommentUrl);

  return (
    <TimelineContext.Provider
      value={{
        timelineElements,
        isTimelineInitialized,
        getTimelineElementById,
        timelineScrollManager,
        timelineElementsContainer,
        quote,
        setQuote,
      }}
    >
      <Timeline {...props} />
    </TimelineContext.Provider>
  );
};

export default TimelineContainer;
