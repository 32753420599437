import React from 'react';
import { Validation } from '../services/Main/types.Field';

export default function getFormControlLabel(
  label?: string | Element | JSX.Element,
  validations?: Validation[]
) {
  if (!label) return undefined;

  return validations?.some((v) => v.type === 'required') ? (
    <>
      <span style={{ color: 'red' }}>*</span>
      {label}
    </>
  ) : (
    label
  );
}
