import React, { PropsWithChildren, useEffect } from 'react';
import {
  useMediaQuery,
  useTheme,
  DrawerProps,
  Drawer as MuiDrawer,
} from '@material-ui/core';
import { useIsSideBarOpen } from 'utils/hooks/useIsSidebarOpen';

const Drawer = ({ children, ...rest }: PropsWithChildren<DrawerProps>) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useIsSideBarOpen();

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(false);
  }, [isXs, setOpen]);

  return (
    <MuiDrawer
      variant={isXs ? 'temporary' : 'permanent'}
      open={open}
      onClose={toggleOpen}
      {...rest}
    >
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
