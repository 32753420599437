import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    bubble: {
      backgroundColor: '#fbfbfb',
    },
    avatar: {
      cursor: 'pointer',
    },
  })
);
