import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import MuiMenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector } from 'store';
import useStyles from './MenuGrouper.styles';
import { MenuItem, MenuItemsGrouper } from '../../../services/Main/types';
import { childrenMenuItemsSelector } from '../../../store/mainMenu/selectors';
import MainMenu from '../../template/PrivateRoutesTemplate/MainMenu/MainMenu';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';
import trimQueryParams from '../../../utils/trimQueryParams';

interface MenuGrouperProps extends MenuItemsGrouper {
  indentLevel?: number;
}

const MenuGrouper = ({ label, id, indentLevel }: MenuGrouperProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const childrenMenuItems = useSelector(childrenMenuItemsSelector(id));
  const menuLinksByGroup = useMemo(
    () =>
      childrenMenuItems.reduce<string[]>((acc, menuItem) => {
        if ('to' in menuItem) {
          return [...acc, trimQueryParams(menuItem.to)];
        }
        return acc;
      }, []),
    [childrenMenuItems]
  );
  const isCompactMenuView = useIsCompactMenuView();

  const [isExpandedGrouper, setIsExpandedGrouper] = useState<boolean>(
    menuLinksByGroup.some((menuLink) => pathname.includes(menuLink))
  );

  const toggleOpen = useCallback(() => {
    setIsExpandedGrouper((prevState) => !prevState);
  }, []);

  useEffect(() => {
    setIsExpandedGrouper(
      menuLinksByGroup.some((menuLink) => pathname.includes(menuLink))
    );
    // eslint-disable-next-line
  }, [isCompactMenuView]);

  return (
    <>
      <MuiMenuItem
        className={clsx(classes.menuItem, 'hidden-compactView', {
          open: isExpandedGrouper,
        })}
        disableGutters
        tabIndex={0}
        onClick={toggleOpen}
      >
        <ListItemText classes={{ primary: classes.label }} primary={label} />
        <ChevronRightIcon className="chevron" />
      </MuiMenuItem>
      <Collapse in={isExpandedGrouper} className="hidden-compactView">
        <MainMenu
          items={childrenMenuItems as MenuItem[]}
          indentLevel={indentLevel ? indentLevel + 1 : 1}
          isSubModule
        />
      </Collapse>
    </>
  );
};

export default MenuGrouper;
