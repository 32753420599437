import React from 'react';
import ErrorPage from 'components/lowLevel/ErrorController';
import { useFormatMessage } from '../../../locale';
import RemovedPagePicture from '../../../svg/RemovedPage';

const RemovedPage = () => {
  const formatMessage = useFormatMessage();

  return (
    <ErrorPage
      heading={formatMessage('removedPageTitle')}
      description={formatMessage('removedPageDescription')}
      image={<RemovedPagePicture />}
    />
  );
};

export default RemovedPage;
