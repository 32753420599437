import { TableFilterFormProps } from '../types';
import { separateFields } from './separateFields';

type TableFilterProps = Omit<
  TableFilterFormProps,
  'filterChips' | 'setFilterChips'
>;

interface ReturnType {
  tableRequiredFilterFormProps: TableFilterProps | null;
  tableNotRequiredFilterFormProps: TableFilterProps;
  fieldsWithDisableClearableLength: number;
}

export const prepareTableFilterFormProps = (
  tableFilterFormProps?: TableFilterProps
): ReturnType | null => {
  if (!tableFilterFormProps) return null;

  const { props } = tableFilterFormProps;
  const { fieldGroups } = props;

  // Разбиваем поля на массив полей:
  // fieldsWithDisableClearable - с включенным параметром DisableClearable
  // fieldsWithoutDisableClearable - без параметра DisableClearable
  const { fieldsWithoutDisableClearable, fieldsWithDisableClearable } =
    separateFields(fieldGroups[0]);

  // Если есть поля с disableClearable, то создаем инструкцию для
  // TableRequiredFilter (полей, которые отображаются рядом с чипсами)
  const tableRequiredFilterFormProps =
    fieldsWithDisableClearable.length > 0
      ? {
          ...tableFilterFormProps,
          props: {
            ...tableFilterFormProps.props,
            fieldGroups: [
              {
                ...tableFilterFormProps.props.fieldGroups[0],
                fields: fieldsWithDisableClearable,
              },
            ],
          },
        }
      : null;

  // Собираем массив полей для фильтра в Drawer
  // По ТЗ все поля с disableClearable должны быть вверху
  const fieldsForNotRequiredFilter = [
    ...fieldsWithDisableClearable,
    ...fieldsWithoutDisableClearable,
  ];

  // Отключаем сетку для полей, которые в Drawer
  const fieldsWithoutMdCols = fieldsForNotRequiredFilter.map((field) => ({
    ...field,
    mdCols: undefined,
  }));

  const tableNotRequiredFilterFormProps = {
    ...tableFilterFormProps,
    props: {
      ...tableFilterFormProps.props,
      fieldGroups: [
        {
          ...tableFilterFormProps.props.fieldGroups[0],
          fields: fieldsWithoutMdCols,
        },
      ],
    },
  };

  return {
    tableRequiredFilterFormProps,
    tableNotRequiredFilterFormProps,
    fieldsWithDisableClearableLength: fieldsWithDisableClearable.length,
  };
};
