import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '2px dashed #c2cfe0',
      borderRadius: 3,
      padding: theme.spacing(3),
      transition: '150ms',
      outline: 'none !important',
      fontWeight: 'bold',
      backgroundColor: '#fafafa',
      color: '#c7c7c7',
      '&.active, &:hover': {
        borderColor: theme.additionalPalette.accentBlue,
        color: theme.additionalPalette.accentBlue,
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '&.disabled': {
        borderColor: 'rgba(0, 0, 0, 0.42)',
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.42)',
        opacity: 0.5,
      },
      '&.error': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
      '&.hidden': {
        display: 'none',
      },
    },
    label: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: theme.typography.pxToRem(12),
      marginBottom: theme.spacing(1),
    },
    disabledFile: {
      color: 'rgba(0, 0, 0, 0.42)',
      userSelect: 'none',
    },
    progressBar: {
      marginTop: theme.spacing(1),
      height: 10,
    },
    error: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
      fontSize: theme.typography.pxToRem(12),
    },
  })
);
