import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { InputProps as MuiInputProps } from '@material-ui/core/Input';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';

interface OtherProps extends MuiInputProps {
  value?: any;
  onChange?: any;
  InputProps?: Partial<MuiInputProps>;
  label?: string | Element | JSX.Element;
  name: string;
  helperText?: string;
}

const BaseTextField = ({
  label,
  disabled,
  value,
  onChange,
  InputProps,
  name,
  helperText,
}: OtherProps) => {
  const { errors } = useFormContext();
  const error = get(errors, name);
  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <MuiTextField
      name={name}
      label={label}
      defaultValue={value || value === 0 ? value : ''}
      disabled={disabled}
      onChange={handleChange}
      fullWidth
      helperText={error?.message || helperText}
      error={!!error}
      InputProps={InputProps}
    />
  );
};

export default BaseTextField;
