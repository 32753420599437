import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
  })
);

let Error404: any = (props: any) => (
  <SvgIcon
    viewBox="0 0 475 402"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={useStyles().root}
    {...props}
  >
    <path
      d="M36.5665 355.562C39.3681 351.632 41.2965 347.265 41.3692 341.915C41.4784 330.487 33.874 316.294 30.5266 305.412C28.4163 298.57 27.5067 293.075 29.2896 286.124C33.2555 270.802 40.9326 256.099 53.0123 245.618C58.9066 240.523 65.8924 235.828 73.0602 232.771C81.465 229.205 89.033 227.058 95.0728 219.597C104.969 207.332 101.367 195.177 98.9659 180.802C96.3826 165.189 98.4929 146.774 112.865 137.858C126.836 129.196 144.81 129.742 160.419 132.617C181.049 136.402 202.953 135.492 221.618 125.084C232.061 119.261 240.32 111.691 252.581 109.762C283.545 104.958 297.844 126.212 322.367 140.624C333.901 147.393 347.763 144.554 360.389 146.701C371.959 148.667 384.148 156.382 387.131 168.464C391.206 184.914 380.837 203.402 389.569 219.087C393.753 226.621 400.994 231.825 408.125 236.411C431.521 251.587 440.071 270.693 437.233 298.716C436.469 306.286 435.05 314.11 432.903 321.462C427.445 340.35 438.361 343.225 443.819 352.36C449.276 361.494 451.314 398.506 421.296 401.927C421.296 401.927 32.455 401.927 31.509 401.927C18.3014 401.782 12.6982 388.753 17.9012 378.017C22.0854 369.428 30.9996 363.35 36.5665 355.562Z"
      fill="#DEF0FF"
    />
    <path
      d="M312.652 266.144C312.652 266.144 303.301 235.938 295.115 233.172C286.928 230.406 290.567 207.405 288.893 204.348C287.219 201.291 274.849 196.342 266.48 210.644C258.112 224.947 256.875 227.931 245.414 233.572C233.916 239.213 225.147 272.44 229.514 293.075C233.916 313.71 226.13 330.997 226.13 330.997L245.414 327.03L281.252 318.914L312.652 266.144Z"
      fill="#1C6EB1"
    />
    <path
      d="M250.908 269.383L252.036 267.672C252.363 267.891 258.767 272.222 261.568 279.609C262.805 274.078 264.224 268.691 265.753 263.596H265.68C265.753 263.378 265.862 263.16 265.935 262.905C272.302 241.87 280.052 226.075 280.197 225.747L282.053 226.657C281.907 226.948 275.358 240.305 269.391 258.683C273.83 255.262 280.488 253.625 280.889 253.515L281.362 255.517C278.123 256.281 269.719 259.193 267.863 263.596C261.969 283.03 257.275 306.868 260.986 326.812L258.985 327.175C256.475 313.674 257.748 298.461 260.622 284.013C259.276 275.06 250.98 269.456 250.908 269.383Z"
      fill="white"
    />
    <path
      d="M285.91 328.668C285.91 328.668 270.01 277.098 295.733 267.345C321.457 257.555 331.827 248.675 339.249 226.439C346.672 204.203 368.866 204.494 374.506 201.837C380.146 199.18 391.388 187.025 394.954 181.384C398.956 175.052 410.017 172.795 415.766 177.235C431.775 189.5 419.259 219.124 407.106 229.387C397.61 237.393 393.462 285.978 367.993 296.059C359.989 299.225 353.621 312 350.165 319.096C347.327 324.846 344.962 330.487 338.922 333.472C331.172 337.293 322.331 338.093 313.817 337.948C310.033 337.875 297.262 338.785 294.496 336.347L285.91 328.668Z"
      fill="#2C98F0"
    />
    <path
      d="M395.791 212.901L394.663 211.518C384.475 219.779 365.919 242.925 348.819 265.562C349.037 258.247 346.854 250.276 346.745 249.876L345.035 250.349C345.071 250.458 347.836 260.612 346.817 268.218C335.429 283.394 324.841 298.061 318.037 307.596C317.382 300.099 313.416 289.982 313.234 289.508L311.597 290.164C311.634 290.309 316.582 302.828 316.364 309.998C313.016 314.693 310.869 317.75 310.433 318.368L311.888 319.387C312.107 319.096 319.092 309.088 329.135 295.259C330.19 294.058 340.596 291.983 349.728 290.709L349.473 288.963C345.944 289.436 336.375 290.855 331.136 292.529C340.013 280.337 350.892 265.634 361.517 252.023C365.847 249.294 382.984 246.746 383.166 246.746L382.911 244.999C382.292 245.072 370.467 246.819 363.845 249.112C376.434 232.99 388.441 218.869 395.791 212.901Z"
      fill="white"
    />
    <path
      d="M338.194 381.474C338.194 381.474 382.365 385.841 407.871 353.815C433.34 321.789 431.229 322.663 458.481 312.8C485.733 302.938 474.2 268.946 459.864 270.184C445.529 271.421 427.773 284.996 408.307 282.048C388.841 279.1 331.136 295.732 315.126 342.461C315.126 342.424 301.446 373.177 338.194 381.474Z"
      fill="#54B2FF"
    />
    <path
      d="M440.217 298.061L439.598 296.278C424.608 301.482 405.651 310.908 387.459 320.989C394.263 307.741 394.299 296.787 394.299 296.314H392.407C392.407 296.46 392.371 308.724 384.403 322.699C352.566 340.423 323.786 359.384 323.313 359.675L324.332 361.24C324.696 360.985 343.979 348.32 368.321 334.054C383.457 332.78 396.191 339.877 396.3 339.95L397.21 338.312C396.701 338.021 385.785 331.943 371.741 332.016C394.372 318.878 420.678 304.83 440.217 298.061Z"
      fill="white"
    />
    <path
      d="M224.42 311.09C224.42 311.09 237.373 266.29 216.233 247.438C195.094 228.623 190.291 231.206 187.562 211.991C186.143 202.092 177.338 195.723 167.623 195.432C151.978 194.995 156.381 210.972 161.62 219.706C168.533 231.206 157.472 240.196 153.324 249.876C147.757 262.869 155.58 278.226 162.348 289.181C167.987 298.316 175.628 306.322 184.651 312.145C190.291 315.784 196.877 318.732 203.244 320.807L224.42 311.09Z"
      fill="#1C6EB1"
    />
    <path
      d="M206.555 269.383L205.427 267.672C205.099 267.891 198.696 272.222 195.894 279.609C194.657 274.078 193.202 268.691 191.71 263.596H191.746C191.674 263.378 191.564 263.16 191.492 262.905C185.124 241.87 177.374 226.075 177.229 225.747L175.373 226.657C175.519 226.948 182.068 240.305 188.035 258.683C183.596 255.262 176.938 253.625 176.538 253.515L176.065 255.517C179.303 256.281 187.708 259.193 189.563 263.596C195.458 283.03 200.151 306.832 196.44 326.812L198.441 327.175C200.952 313.674 199.678 298.461 196.804 284.013C198.186 275.06 206.446 269.456 206.555 269.383Z"
      fill="white"
    />
    <path
      d="M187.016 314.62C187.016 314.62 176.101 272.986 137.206 265.489C137.206 265.489 107.189 259.338 94.1994 239.904C81.2466 220.434 72.3687 208.497 58.3607 212.937C58.3607 212.937 35.1474 233.754 66.2197 259.338C70.1857 262.614 75.9344 264.288 80.155 267.6C90.0152 275.388 97.1102 284.304 101.258 296.241C105.006 307.014 109.008 318.259 119.123 324.482C128.474 330.232 138.552 334.854 149.54 336.201C153.725 336.71 157.945 336.856 162.166 336.82C163.767 336.82 175.482 337.511 176.028 336.419C176.101 336.456 187.016 314.62 187.016 314.62Z"
      fill="#2C98F0"
    />
    <path
      d="M168.06 349.266C164.894 334.636 157.654 320.843 148.703 308.397L148.74 308.36C148.667 308.287 148.594 308.251 148.521 308.178C145.793 304.393 142.918 300.718 139.935 297.187L140.08 297.115C136.988 290.709 140.08 282.667 140.117 282.594L138.225 281.829C138.115 282.121 135.932 287.762 136.915 293.694C113.338 267.163 85.649 249.84 85.2124 249.585L84.1208 251.332C84.6302 251.623 118.795 273.022 143.282 304.539C136.988 301.336 131.785 302.392 131.53 302.428L131.967 304.43C132.039 304.393 139.498 302.865 147.102 309.598C155.871 321.826 162.966 335.364 166.059 349.667L168.06 349.266Z"
      fill="#E1E2FF"
    />
    <path
      d="M135.423 387.807C135.423 387.807 90.9977 394.576 75.5343 359.42C75.5343 359.42 72.5871 337.766 54.1765 336.638C35.766 335.51 28.7074 328.122 25.4691 315.057C22.1945 301.955 11.3519 294.822 4.36612 290.236C-2.65609 285.614 -4.62086 255.845 25.1781 262.468C25.1781 262.468 35.8387 271.275 53.8855 275.497C71.9322 279.682 73.715 285.032 78.8452 293.912C83.9755 302.756 83.4661 301.336 99.3297 306.65C115.193 311.963 145.174 328.558 150.741 360.148L135.423 387.807Z"
      fill="#54B2FF"
    />
    <path
      d="M99.5844 343.116C103.951 335.764 101.476 326.047 101.404 325.611L99.4025 326.12C99.4389 326.193 101.622 334.891 98.1654 341.478C88.851 330.596 76.9896 318.841 62.8724 308.688C64.3278 302.61 61.417 294.13 61.3079 293.766L59.3795 294.458C59.4159 294.531 61.9628 301.955 61.0532 307.414C53.4124 302.064 45.1531 297.224 36.2753 293.221L35.4385 295.077C47.5181 300.499 58.3607 307.45 67.9299 314.984C63.0543 318.077 57.5603 317.75 57.5239 317.75L57.3783 319.788C57.6694 319.824 64.0367 320.188 69.6036 316.33C85.7947 329.395 98.2382 344.062 106.752 355.781C102.932 358.401 96.5645 358.183 96.4917 358.183L96.419 360.221C96.71 360.221 103.587 360.475 107.953 357.418C116.249 369.028 120.506 377.325 120.578 377.507L121.561 377.253L122.579 377.107C122.398 375.688 114.357 360.73 99.5844 343.116Z"
      fill="white"
    />
    <path
      d="M248.906 304.939L247.815 303.811C277.759 274.624 285.437 242.343 291.622 216.394C294.278 205.331 296.752 194.85 300.609 186.115L302.028 186.734C298.244 195.323 295.77 205.731 293.114 216.758C286.892 242.889 279.142 275.424 248.906 304.939Z"
      fill="#2D3581"
    />
    <path
      d="M293.187 213.083C293.187 213.083 305.084 201.073 312.943 205.695C320.984 210.462 312.761 222.763 293.187 213.083Z"
      fill="#2D3581"
    />
    <path
      d="M294.933 206.386C294.933 206.386 298.317 192.593 291.586 190.337C284.672 188.008 279.469 199.69 294.933 206.386Z"
      fill="#2D3581"
    />
    <path
      d="M289.73 228.623C289.73 228.623 293.55 212.973 285.909 210.426C278.087 207.769 272.193 221.016 289.73 228.623Z"
      fill="#2D3581"
    />
    <path
      d="M281.762 253.698C281.762 253.698 281.798 237.612 273.757 236.92C265.534 236.229 262.951 250.459 281.762 253.698Z"
      fill="#2D3581"
    />
    <path
      d="M266.699 282.157C266.699 282.157 270.483 269.019 264.079 266.29C257.493 263.524 252.036 274.478 266.699 282.157Z"
      fill="#2D3581"
    />
    <path
      d="M300.573 186.115C300.573 186.115 312.47 174.105 320.293 178.727C328.37 183.495 320.147 195.759 300.573 186.115Z"
      fill="#2D3581"
    />
    <path
      d="M285.618 243.18C285.618 243.18 297.698 236.483 302.319 242.052C307.013 247.765 297.625 255.59 285.618 243.18Z"
      fill="#2D3581"
    />
    <path
      d="M276.486 265.343C276.486 265.343 283.253 253.261 290.094 255.626C297.116 257.992 293.223 269.565 276.486 265.343Z"
      fill="#2D3581"
    />
    <path
      d="M261.35 289.545C261.35 289.545 267.972 281.466 272.229 284.45C276.595 287.58 271.974 295.804 261.35 289.545Z"
      fill="#2D3581"
    />
    <path
      d="M205.754 306.431L206.628 305.34C177.411 282.23 167.624 254.353 159.728 231.934C156.381 222.363 153.179 213.301 148.958 205.95L147.758 206.641C151.905 213.92 155.071 222.909 158.418 232.407C166.314 254.971 176.21 283.067 205.754 306.431Z"
      fill="#2D3581"
    />
    <path
      d="M157.982 229.168C157.982 229.168 146.302 219.633 139.789 224.474C133.131 229.387 141.572 239.504 157.982 229.168Z"
      fill="#2D3581"
    />
    <path
      d="M155.835 223.345C155.835 223.345 151.578 211.445 157.327 208.825C163.221 206.131 168.897 215.994 155.835 223.345Z"
      fill="#2D3581"
    />
    <path
      d="M162.53 242.597C162.53 242.597 157.691 229.096 164.204 226.111C170.898 223.054 177.338 234.227 162.53 242.597Z"
      fill="#2D3581"
    />
    <path
      d="M171.881 264.07C171.881 264.07 170.353 249.803 177.411 248.457C184.652 247.074 188.254 259.484 171.881 264.07Z"
      fill="#2D3581"
    />
    <path
      d="M187.853 287.871C187.853 287.871 183.269 276.589 188.726 273.568C194.293 270.548 200.151 279.755 187.853 287.871Z"
      fill="#2D3581"
    />
    <path
      d="M148.958 205.913C148.958 205.913 137.279 196.378 130.766 201.218C124.108 206.168 132.513 216.285 148.958 205.913Z"
      fill="#2D3581"
    />
    <path
      d="M167.478 255.117C167.478 255.117 156.162 250.277 152.597 255.663C148.958 261.158 157.982 267.199 167.478 255.117Z"
      fill="#2D3581"
    />
    <path
      d="M177.629 273.896C177.629 273.896 170.534 263.851 164.676 266.544C158.673 269.31 163.185 279.209 177.629 273.896Z"
      fill="#2D3581"
    />
    <path
      d="M193.275 293.948C193.275 293.948 186.653 287.398 183.16 290.455C179.594 293.621 184.433 300.463 193.275 293.948Z"
      fill="#2D3581"
    />
    <path
      d="M154.343 354.907L155.035 354.034C131.276 335.255 123.307 312.545 116.904 294.312C114.175 286.524 111.592 279.173 108.135 273.168L107.153 273.75C110.536 279.646 113.083 286.961 115.812 294.713C122.289 313.091 130.293 335.91 154.343 354.907Z"
      fill="#2D3581"
    />
    <path
      d="M115.485 292.092C115.485 292.092 105.988 284.341 100.712 288.271C95.2911 292.274 102.131 300.499 115.485 292.092Z"
      fill="#2D3581"
    />
    <path
      d="M113.738 287.361C113.738 287.361 110.245 277.681 114.939 275.57C119.742 273.35 124.362 281.393 113.738 287.361Z"
      fill="#2D3581"
    />
    <path
      d="M119.159 303.01C119.159 303.01 115.23 292.02 120.542 289.618C126 287.106 131.239 296.205 119.159 303.01Z"
      fill="#2D3581"
    />
    <path
      d="M126.8 320.479C126.8 320.479 125.563 308.87 131.312 307.778C137.17 306.65 140.117 316.731 126.8 320.479Z"
      fill="#2D3581"
    />
    <path
      d="M139.789 339.84C139.789 339.84 136.078 330.669 140.481 328.231C145.029 325.72 149.759 333.217 139.789 339.84Z"
      fill="#2D3581"
    />
    <path
      d="M108.135 273.168C108.135 273.168 98.6385 265.416 93.3628 269.347C87.9415 273.386 94.7818 281.611 108.135 273.168Z"
      fill="#2D3581"
    />
    <path
      d="M123.234 313.2C123.234 313.2 114.029 309.27 111.118 313.637C108.135 318.114 115.484 323.027 123.234 313.2Z"
      fill="#2D3581"
    />
    <path
      d="M131.457 328.486C131.457 328.486 125.672 320.297 120.906 322.481C116.067 324.737 119.705 332.78 131.457 328.486Z"
      fill="#2D3581"
    />
    <path
      d="M144.192 344.79C144.192 344.79 138.807 339.476 135.969 341.951C133.058 344.499 136.988 350.067 144.192 344.79Z"
      fill="#2D3581"
    />
    <path
      d="M316.691 354.907L316 354.034C339.759 335.255 347.727 312.545 354.131 294.312C356.859 286.524 359.443 279.173 362.899 273.168L363.882 273.75C360.498 279.646 357.915 286.961 355.222 294.713C348.746 313.091 340.705 335.91 316.691 354.907Z"
      fill="#2D3581"
    />
    <path
      d="M355.513 292.092C355.513 292.092 365.01 284.341 370.285 288.271C375.743 292.274 368.903 300.499 355.513 292.092Z"
      fill="#2D3581"
    />
    <path
      d="M357.296 287.361C357.296 287.361 360.789 277.681 356.095 275.57C351.293 273.35 346.672 281.393 357.296 287.361Z"
      fill="#2D3581"
    />
    <path
      d="M351.838 303.01C351.838 303.01 355.768 292.02 350.456 289.618C345.034 287.106 339.795 296.205 351.838 303.01Z"
      fill="#2D3581"
    />
    <path
      d="M344.234 320.479C344.234 320.479 345.471 308.87 339.722 307.778C333.864 306.65 330.917 316.731 344.234 320.479Z"
      fill="#2D3581"
    />
    <path
      d="M331.245 339.84C331.245 339.84 334.956 330.669 330.553 328.231C326.005 325.72 321.275 333.217 331.245 339.84Z"
      fill="#2D3581"
    />
    <path
      d="M362.899 273.168C362.899 273.168 372.396 265.416 377.671 269.347C383.093 273.386 376.252 281.611 362.899 273.168Z"
      fill="#2D3581"
    />
    <path
      d="M347.8 313.2C347.8 313.2 357.005 309.27 359.916 313.637C362.899 318.114 355.513 323.027 347.8 313.2Z"
      fill="#2D3581"
    />
    <path
      d="M339.577 328.486C339.577 328.486 345.362 320.297 350.128 322.481C354.967 324.737 351.293 332.78 339.577 328.486Z"
      fill="#2D3581"
    />
    <path
      d="M326.842 344.79C326.842 344.79 332.227 339.476 335.065 341.951C337.976 344.499 334.01 350.067 326.842 344.79Z"
      fill="#2D3581"
    />
    <path
      d="M90.088 402C82.4473 402 79.5729 378.199 104.387 369.61C109.153 367.972 113.993 366.225 117.486 362.404C123.089 356.29 121.743 347.92 125.09 340.787C129.565 331.215 141.754 329.505 150.996 328.085C156.781 327.175 163.767 326.193 168.751 322.845C175.992 317.968 176.247 307.268 182.577 301.482C188.363 296.205 196.695 293.111 204.335 295.55C218.089 299.953 224.602 309.015 238.792 299.407C247.815 293.293 261.932 295.077 266.48 304.939C274.885 323.245 296.825 307.778 311.888 319.424C332.554 335.364 320.839 345.336 328.843 358.656C333.137 365.789 349.837 366.116 361.007 374.014C376.398 384.895 374.87 402 367.666 402C367.629 402 90.2699 402 90.088 402Z"
      fill="#2D3581"
    />
    <path d="M235.808 192.666H216.015V402H235.808V192.666Z" fill="#D24A48" />
    <path
      d="M196.076 194.158L153.87 151.869V92.0386L196.076 49.7132H255.747L297.953 92.0386V151.869L255.747 194.158H196.076Z"
      fill="#D24A48"
    />
    <path
      d="M198.914 187.316L160.71 149.03V94.8773L198.914 56.5552H252.945L291.149 94.8773V149.03L252.945 187.316H198.914Z"
      stroke="white"
      fill="#D24A48"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.1"
      d="M235.808 194.158H216.015V199.035H235.808V194.158Z"
      fill="#231F20"
    />
    <path
      d="M208.229 138.804H204.154V148.922H196.513V138.804H178.903V134.037L192.256 95.278H200.406C200.406 95.4236 200.406 95.5327 200.406 95.6055L187.89 131.999H196.513V115.84H204.154V131.999H208.229V138.804Z"
      fill="#EEFFF9"
    />
    <path
      d="M239.192 144.008C239.192 147.284 237.591 148.921 234.353 148.921H217.616C214.451 148.921 212.85 147.284 212.85 144.008V100.191C212.85 96.9153 214.487 95.2412 217.725 95.2412H234.353C237.591 95.2412 239.192 96.8789 239.192 100.191V144.008ZM231.333 142.007V102.156H220.745V142.043H231.333V142.007Z"
      fill="#EEFFF9"
    />
    <path
      d="M272.957 138.804H268.882V148.921H261.241V138.804H243.631V134.036L256.948 95.2776H265.098C265.098 95.4231 265.098 95.5323 265.098 95.6051L252.581 131.998H261.205V115.84H268.845V131.998H272.92V138.804H272.957Z"
      fill="#EEFFF9"
    />
  </SvgIcon>
);
Error404.muiName = 'SvgIcon';

Error404 = React.memo(Error404);
Error404.displayName = 'Error404';

const NotFoundPicture = Error404;

export default NotFoundPicture;
