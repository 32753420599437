import React from 'react';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Icon from 'components/lowLevel/Icon';
import { RadioGroup as RadioGroupProps } from 'services/Main/types.Field';
import useStyles from './RadioGroup.styles';

interface OtherProps {
  value?: any;
  onChange?: any;
}

const RadioGroup = ({
  disabled,
  name,
  options,
  label,
  value,
  onChange,
}: RadioGroupProps & OtherProps) => {
  const classes = useStyles();

  const handleChange = (event: any, option: any) => {
    onChange(option);
  };

  return (
    <FormControl disabled={disabled}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <MuiRadioGroup
        name={name}
        value={value || (options.length && options[0].value)}
        onChange={handleChange}
      >
        {options.length > 0 &&
          options.map((option, index) => (
            <FormControlLabel
              key={`${option.label}${index}`}
              value={option.value}
              control={
                option.icon ? (
                  <Radio icon={<Icon definition={option.icon} />} />
                ) : (
                  <Radio />
                )
              }
              label={option.label}
            />
          ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
