import { convertFieldValueFromServerToClient } from '../../FormBuilder/helpers';
import { Field } from '../../../../services/Main/types.Field';

export const getEmptyRow = (rowDefinition: Field[]) =>
  rowDefinition.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: convertFieldValueFromServerToClient(
        field,
        field?.defaultValue
      ),
    }),
    {}
  );
