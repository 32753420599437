export default function (name: string) {
  let Icon: any;

  if (name === 'AccessTime') {
    Icon = require('@material-ui/icons/AccessTime').default;
  }
  if (name === 'AccountCircle') {
    Icon = require('@material-ui/icons/AccountCircle').default;
  }
  if (name === 'AccountTree') {
    Icon = require('@material-ui/icons/AccountTree').default;
  }
  if (name === 'Add') {
    Icon = require('@material-ui/icons/Add').default;
  }
  if (name === 'Alarm') {
    Icon = require('@material-ui/icons/Alarm').default;
  }
  if (name === 'Archive') {
    Icon = require('@material-ui/icons/Archive').default;
  }
  if (name === 'ArrowDropDown') {
    Icon = require('@material-ui/icons/ArrowDropDown').default;
  }
  if (name === 'ArrowRight') {
    Icon = require('@material-ui/icons/ArrowRight').default;
  }
  if (name === 'AttachFile') {
    Icon = require('@material-ui/icons/AttachFile').default;
  }
  if (name === 'FindInPage') {
    Icon = require('@material-ui/icons/FindInPage').default;
  }
  if (name === 'Attachment') {
    Icon = require('@material-ui/icons/Attachment').default;
  }
  if (name === 'Ballot') {
    Icon = require('@material-ui/icons/Ballot').default;
  }
  if (name === 'Block') {
    Icon = require('@material-ui/icons/Block').default;
  }
  if (name === 'BorderAll') {
    Icon = require('@material-ui/icons/BorderAll').default;
  }
  if (name === 'Brush') {
    Icon = require('@material-ui/icons/Brush').default;
  }
  if (name === 'Build') {
    Icon = require('@material-ui/icons/Build').default;
  }
  if (name === 'CalendarToday') {
    Icon = require('@material-ui/icons/CalendarViewDay').default;
  }
  if (name === 'CalendarViewDay') {
    Icon = require('@material-ui/icons/CalendarViewDay').default;
  }
  if (name === 'CallMerge') {
    Icon = require('@material-ui/icons/CallMerge').default;
  }
  if (name === 'CallMissed') {
    Icon = require('@material-ui/icons/CallMissed').default;
  }
  if (name === 'ChatBubbleOutline') {
    Icon = require('@material-ui/icons/ChatBubbleOutline').default;
  }
  if (name === 'Check') {
    Icon = require('@material-ui/icons/Check').default;
  }
  if (name === 'CheckBox') {
    Icon = require('@material-ui/icons/CheckBox').default;
  }
  if (name === 'CheckCircleOutline') {
    Icon = require('@material-ui/icons/CheckCircleOutline').default;
  }
  if (name === 'ChildCare') {
    Icon = require('@material-ui/icons/ChildCare').default;
  }
  if (name === 'Clear') {
    Icon = require('@material-ui/icons/Clear').default;
  }
  if (name === 'CompareArrows') {
    Icon = require('@material-ui/icons/CompareArrows').default;
  }
  if (name === 'Delete') {
    Icon = require('@material-ui/icons/Delete').default;
  }
  if (name === 'Crop169') {
    Icon = require('@material-ui/icons/Crop169').default;
  }
  if (name === 'CropFree') {
    Icon = require('@material-ui/icons/CropFree').default;
  }
  if (name === 'CropSquare') {
    Icon = require('@material-ui/icons/CropSquare').default;
  }
  if (name === 'DescriptionOutlined') {
    Icon = require('@material-ui/icons/DescriptionOutlined').default;
  }
  if (name === 'Dialpad') {
    Icon = require('@material-ui/icons/Dialpad').default;
  }
  if (name === 'DirectionsWalk') {
    Icon = require('@material-ui/icons/DirectionsWalk').default;
  }
  if (name === 'Dns') {
    Icon = require('@material-ui/icons/Dns').default;
  }
  if (name === 'DoneAll') {
    Icon = require('@material-ui/icons/DoneAll').default;
  }
  if (name === 'DynamicFeed') {
    Icon = require('@material-ui/icons/DynamicFeed').default;
  }
  if (name === 'Edit') {
    Icon = require('@material-ui/icons/Edit').default;
  }
  if (name === 'ErrorOutline') {
    Icon = require('@material-ui/icons/ErrorOutline').default;
  }
  if (name === 'Event') {
    Icon = require('@material-ui/icons/Event').default;
  }
  if (name === 'Filter1') {
    Icon = require('@material-ui/icons/Filter1').default;
  }
  if (name === 'Filter2') {
    Icon = require('@material-ui/icons/Filter2').default;
  }
  if (name === 'Filter3') {
    Icon = require('@material-ui/icons/Filter3').default;
  }
  if (name === 'Filter4') {
    Icon = require('@material-ui/icons/Filter4').default;
  }
  if (name === 'Filter5') {
    Icon = require('@material-ui/icons/Filter5').default;
  }
  if (name === 'Filter6') {
    Icon = require('@material-ui/icons/Filter6').default;
  }
  if (name === 'Filter7') {
    Icon = require('@material-ui/icons/Filter7').default;
  }
  if (name === 'Filter8') {
    Icon = require('@material-ui/icons/Filter8').default;
  }
  if (name === 'Filter9') {
    Icon = require('@material-ui/icons/Filter9').default;
  }
  if (name === 'Filter9Plus') {
    Icon = require('@material-ui/icons/Filter9Plus').default;
  }
  if (name === 'FilterList') {
    Icon = require('@material-ui/icons/FilterList').default;
  }
  if (name === 'FilterNone') {
    Icon = require('@material-ui/icons/FilterNone').default;
  }
  if (name === 'FormatSize') {
    Icon = require('@material-ui/icons/FormatSize').default;
  }
  if (name === 'FolderOutlined') {
    Icon = require('@material-ui/icons/FolderOutlined').default;
  }
  if (name === 'GetApp') {
    Icon = require('@material-ui/icons/GetApp').default;
  }
  if (name === 'GridOn') {
    Icon = require('@material-ui/icons/GridOn').default;
  }
  if (name === 'HelpOutline') {
    Icon = require('@material-ui/icons/HelpOutline').default;
  }
  if (name === 'HighlightOff') {
    Icon = require('@material-ui/icons/HighlightOff').default;
  }
  if (name === 'History') {
    Icon = require('@material-ui/icons/History').default;
  }
  if (name === 'Home') {
    Icon = require('@material-ui/icons/Home').default;
  }
  if (name === 'Launch') {
    Icon = require('@material-ui/icons/Launch').default;
  }
  if (name === 'Link') {
    Icon = require('@material-ui/icons/Link').default;
  }
  if (name === 'ListAlt') {
    Icon = require('@material-ui/icons/ListAlt').default;
  }
  if (name === 'Lock') {
    Icon = require('@material-ui/icons/Lock').default;
  }
  if (name === 'LockOutlined') {
    Icon = require('@material-ui/icons/LockOutlined').default;
  }
  if (name === 'LockOpenOutlined') {
    Icon = require('@material-ui/icons/LockOpenOutlined').default;
  }
  if (name === 'Menu') {
    Icon = require('@material-ui/icons/Menu').default;
  }
  if (name === 'MonetizationOnOutlined') {
    Icon = require('@material-ui/icons/MonetizationOnOutlined').default;
  }
  if (name === 'Mood') {
    Icon = require('@material-ui/icons/Mood').default;
  }
  if (name === 'MoodBad') {
    Icon = require('@material-ui/icons/MoodBad').default;
  }
  if (name === 'MoreHoriz') {
    Icon = require('@material-ui/icons/MoreHoriz').default;
  }
  if (name === 'MoreVert') {
    Icon = require('@material-ui/icons/MoreVert').default;
  }
  if (name === 'Mouse') {
    Icon = require('@material-ui/icons/Mouse').default;
  }
  if (name === 'NotificationImportant') {
    Icon = require('@material-ui/icons/NotificationImportant').default;
  }
  if (name === 'OfflineBolt') {
    Icon = require('@material-ui/icons/OfflineBolt').default;
  }
  if (name === 'Palette') {
    Icon = require('@material-ui/icons/Palette').default;
  }
  if (name === 'PauseCircleOutline') {
    Icon = require('@material-ui/icons/PauseCircleOutline').default;
  }
  if (name === 'RadioButtonChecked') {
    Icon = require('@material-ui/icons/RadioButtonChecked').default;
  }
  if (name === 'RemoveFromQueue') {
    Icon = require('@material-ui/icons/RemoveFromQueue').default;
  }
  if (name === 'Replay') {
    Icon = require('@material-ui/icons/Replay').default;
  }
  if (name === 'Reply') {
    Icon = require('@material-ui/icons/Reply').default;
  }
  if (name === 'Restore') {
    Icon = require('@material-ui/icons/Restore').default;
  }
  if (name === 'RoomService') {
    Icon = require('@material-ui/icons/RoomService').default;
  }
  if (name === 'Search') {
    Icon = require('@material-ui/icons/Search').default;
  }
  if (name === 'Send') {
    Icon = require('@material-ui/icons/Send').default;
  }
  if (name === 'Sync') {
    Icon = require('@material-ui/icons/Sync').default;
  }
  if (name === 'Tab') {
    Icon = require('@material-ui/icons/Tab').default;
  }
  if (name === 'TableChart') {
    Icon = require('@material-ui/icons/TableChart').default;
  }
  if (name === 'TextFields') {
    Icon = require('@material-ui/icons/TextFields').default;
  }
  if (name === 'TimerOff') {
    Icon = require('@material-ui/icons/TimerOff').default;
  }
  if (name === 'TouchApp') {
    Icon = require('@material-ui/icons/TouchApp').default;
  }
  if (name === 'WorkOutline') {
    Icon = require('@material-ui/icons/WorkOutline').default;
  }
  if (name === 'Settings') {
    Icon = require('@material-ui/icons/SettingsOutlined').default;
  }
  if (name === 'PeopleOutline') {
    Icon = require('@material-ui/icons/PeopleOutline').default;
  }
  if (name === 'Eco') {
    Icon = require('@material-ui/icons/Eco').default;
  }
  if (name === 'Business') {
    Icon = require('@material-ui/icons/Business').default;
  }
  if (name === 'EventAvailable') {
    Icon = require('@material-ui/icons/EventAvailable').default;
  }
  if (name === 'BookOutlined') {
    Icon = require('@material-ui/icons/BookOutlined').default;
  }
  if (name === 'DeveloperBoardOutlined') {
    Icon = require('@material-ui/icons/DeveloperBoardOutlined').default;
  }
  if (name === 'ThumbsUpDownOutlined') {
    Icon = require('@material-ui/icons/ThumbsUpDownOutlined').default;
  }
  if (name === 'PlaylistAddCheck') {
    Icon = require('@material-ui/icons/PlaylistAddCheck').default;
  }
  if (name === 'TrendingUp') {
    Icon = require('@material-ui/icons/TrendingUp').default;
  }
  if (name === 'AssessmentOutlined') {
    Icon = require('@material-ui/icons/AssessmentOutlined').default;
  }
  if (name === 'MenuBook') {
    Icon = require('@material-ui/icons/MenuBook').default;
  }
  if (name === 'VisibilityOff') {
    Icon = require('@material-ui/icons/VisibilityOff').default;
  }
  if (name === 'Save') {
    Icon = require('@material-ui/icons/Save').default;
  }
  if (name === 'SendRounded') {
    Icon = require('@material-ui/icons/SendRounded').default;
  }

  return Icon || undefined;
}
