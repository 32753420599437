import React from 'react';
import {
  FormattedNumber,
  PriceFormat as PriceFormatProps,
  FlexibleNumberFormat as FlexibleNumberFormatProps,
} from 'services/Main/types.Field';
import PriceNumber from './PriceNumber';
import FlexibleNumber from './FlexibleNumber';

export default ({ formattedNumberType, ...rest }: FormattedNumber) => {
  switch (formattedNumberType) {
    case 'price':
      return <PriceNumber {...(rest as PriceFormatProps)} />;
    case 'flexibleNumber':
      return <FlexibleNumber {...(rest as FlexibleNumberFormatProps)} />;
    default:
      throw new Error('Не удалось опредилить тип FormattedNumber-компонента');
  }
};
