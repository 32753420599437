import { DirtyFormValue } from '../../../../services/Main/types.Component';
import { ComboBoxOption, Field } from '../../../../services/Main/types.Field';
import { getDate } from '../../DatePicker/helpers';
import { ParsableDate } from '../../../../services/Main/types.common';

/**
 * Функция, которая возвращает (label) локализированное название выбранного значения в поле.
 * + case 'comboBox': Соединяет все label (join(' ')) и возвращает string
 * + case 'entryPicker': Соединяет все label (join(' ')) и возвращает string
 * + case 'text': Возвращает string
 * + case 'formattedNumber': Возвращает number
 * + ? case 'datePicker': Возвращает number (DateRange не поддерживается)
 * - case 'radio': В ArrayOf не будет radio
 * - case 'checkbox': В ArrayOf не будет checkBox
 * - case 'select': Поле устарело
 * - case 'file': Нет кейсов
 * - case 'arrayOf': Нет кейсов
 */
export const getFieldValueLabel = (value: DirtyFormValue, field: Field) => {
  if (field.type === 'comboBox' || field.type === 'entryPicker') {
    if (Array.isArray(value)) {
      return value.length > 0
        ? (value as ComboBoxOption[]).map((o) => o.label).join(' ')
        : null;
    }
    return (value as ComboBoxOption).label;
  }

  if (field.type === 'text') {
    return value as string;
  }

  if (field.type === 'formattedNumber') {
    return value as number;
  }

  if (field.type === 'datePicker') {
    if (field.pickerType === 'date' || field.pickerType === 'dateTime') {
      return getDate(value as ParsableDate)?.getTime() || null;
    }

    return null;
  }

  return null;
};
