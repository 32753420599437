import React, { MouseEventHandler, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import Icon from 'components/lowLevel/Icon';
import DropZoneFileUpload from '../DropZoneFileUpload';
import { File, FileUpload } from '../../../../services/Main/types.Field';
import useStyles from './ClipFileUploader.styles';
import { useFormatMessage } from '../../../../locale';
import ClipFileList from './ClipFileList';

interface ClipFileUploaderProps {
  value?: File[];
}

const ClipFileUploader = ({
  value = [],
  label,
  disabled,
  ...rest
}: FileUpload & ClipFileUploaderProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [isOpen, setOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { errors } = useFormContext();
  const error = get(errors, rest.name);

  const isValueEmpty: boolean = value.length === 0;

  const handleClickOpen: MouseEventHandler<HTMLDivElement | HTMLButtonElement> =
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setOpen(true);
    };

  const handleClose = () => {
    if (isPending) return;
    setOpen(false);
  };

  const handleChangePending = (pending: boolean) => {
    setIsPending(pending);
  };

  return (
    <>
      <TextField
        fullWidth
        focused={!disabled ? isOpen : false}
        disabled={disabled}
        className={classes.field}
        label={label}
        error={!!error}
        helperText={error?.message || rest?.helperText}
        InputLabelProps={{
          shrink: !disabled || !isValueEmpty,
        }}
        inputProps={{
          className: classes.fieldInput,
        }}
        InputProps={{
          onClick: disabled && isValueEmpty ? undefined : handleClickOpen,
          startAdornment: (
            <div className={classes.fieldStartAdornment}>
              {isValueEmpty ? (
                <Typography
                  variant="body1"
                  noWrap
                  className={classes.fieldHelperText}
                >
                  {disabled ? '' : formatMessage('clickToUploadFiles')}
                </Typography>
              ) : (
                <ClipFileList disabled={disabled} value={value} />
              )}
            </div>
          ),
          endAdornment: (
            <div className={classes.fieldEndAdornment}>
              <Chip
                disabled={disabled && isValueEmpty}
                label={value.length}
                size="small"
              />
              <IconButton
                className={classes.iconButton}
                disabled={disabled && isValueEmpty}
              >
                <Icon definition="mui:AttachFile" />
              </IconButton>
            </div>
          ),
        }}
      />
      <Dialog fullWidth open={isOpen} onClose={handleClose}>
        <DialogTitle>{formatMessage('uploadFiles')}</DialogTitle>
        <DialogContent>
          <DropZoneFileUpload
            value={value}
            handleChangePending={handleChangePending}
            label={label}
            disabled={disabled}
            {...rest}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isPending} onClick={handleClose} color="primary">
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClipFileUploader;
