import React, { PropsWithChildren } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import SnackbarReduxListener from './SnackbarReduxListener';
import SnackbarCloseButton from './SnackbarCloseButton';

const SnackbarProvider = ({ children }: PropsWithChildren<any>) => (
  <NotistackSnackbarProvider
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    maxSnack={3}
    action={SnackbarCloseButton}
  >
    <SnackbarReduxListener />
    {children}
  </NotistackSnackbarProvider>
);

export default SnackbarProvider;
