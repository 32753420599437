import React, { useContext } from 'react';
import clsx from 'clsx';
import { Table as DevExpressTable } from '@devexpress/dx-react-grid-material-ui';
import {
  Component,
  DialogWithComponent,
  ShowAsyncModalOnCellClickReaction,
} from 'services/Main/types.Component';
import useStyles from './getTableCell.styles';
import mainService from '../../../../services/Main';
import useTableDialogWithComponentContext from '../components/TableDialogWithComponent/useTableDialogWithComponentContext';
import useTableContext from '../useTableContext';
import TableDragAndDropCellContext from '../components/TableCellDragAndDropProvider/TableCellDragAndDrop.context';
import DragAndDropCellContainer from '../components/TableCellDragAndDropProvider/DragAndDropCellContainer';
import Cell from '../components/Cell';

const getTableCell =
  (
    cellClickReaction: ShowAsyncModalOnCellClickReaction,
    allowClickOnEmptyCell: boolean = true
  ) =>
  ({
    children,
    column,
    tableColumn,
    tableRow,
    row,
    value,
    className,
    ...rest
  }: DevExpressTable.DataCellProps & any) => {
    const { setDialogWithComponent } = useTableDialogWithComponentContext();
    const tableDragAndDropCellContext = useContext(TableDragAndDropCellContext);
    const { dragNDropConfig } = useTableContext();
    const classes = useStyles();

    let isClickableCell =
      cellClickReaction.columnNames.includes(column.name) &&
      (allowClickOnEmptyCell || !!value);

    // Убираем кликабельность ячейки, если это STMCells, так как на каждую
    // ячейку внутри STMCells уже навешан onClick в STMCellsTypeProvider.tsx.
    if (column.options.formatAs === 'STMCells') {
      isClickableCell = false;
    }

    const isDragAndDropCell = dragNDropConfig?.draggableColumns.includes(
      column.name
    );

    const handleClick = () => {
      mainService
        .makeActionRequest<Component | DialogWithComponent>(
          cellClickReaction.fetchModalDataRequestConfig,
          { column, row }
        )
        .then(({ isSuccess, payload }) => {
          if (isSuccess) {
            const isDialogWithComponent =
              'component' in payload && 'viewStyle' in payload;

            if (!isDialogWithComponent) {
              setDialogWithComponent({
                component: payload as Component,
                viewStyle: 'modal',
              });
            } else {
              // if (
              //   process.env.REACT_APP_ENV === 'production' ||
              //   process.env.REACT_APP_IS_REMOTE === 'true'
              // ) {
              //   sendMessageToBot(
              //     'Похоже, бек освоил isDialogWithComponent для ППРов и можно выпилить поддержку обратной совместимости. В файле getTableCell.tsx'
              //   );
              // }

              // TODO: После освоения бэка оставить только строку ниже, остальное удалить.
              //  Также нужно поправить типизацию у makeActionRequest
              setDialogWithComponent(payload as DialogWithComponent);
            }
          }
        });
    };

    const id = `${row.id}_${column.name}`;

    return (
      <Cell
        tableRow={tableRow}
        tableColumn={tableColumn}
        row={row}
        column={column}
        value={value}
        className={clsx(className, {
          [classes.clickable]: isClickableCell,
          [classes.dragging]: isDragAndDropCell,
          [classes.loading]:
            tableDragAndDropCellContext?.loadingCellIds.includes(id),
        })}
        onClick={isClickableCell ? handleClick : undefined}
        {...rest}
      >
        {isDragAndDropCell ? (
          <DragAndDropCellContainer
            id={id}
            rowId={row.id}
            columnName={column.name}
            value={value}
          >
            {children}
          </DragAndDropCellContainer>
        ) : (
          children
        )}
      </Cell>
    );
  };

export default getTableCell;
