import React, { PropsWithChildren } from 'react';
import { useHideLoadingScreen } from 'utils/hooks/useHideLoadingScreen';

/**
 * Компонент-обёртка для скрытия экрана загрузки.
 * Удобно использовать в роутере
 *
 * @param children
 * @constructor
 */
const HidePreLoader = ({ children }: PropsWithChildren<{}>) => {
  useHideLoadingScreen();

  return <>{children}</>;
};

export default HidePreLoader;
