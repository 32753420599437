import React, { useContext } from 'react';
import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import useStyles from './TableSummaryRowFooter.styles';
import { TableContext } from '../../TableContext';

function TableSummaryRowFooter() {
  const classes = useStyles();
  const tableContext = useContext(TableContext);

  if (!tableContext || !tableContext?.summaryRow) return null;

  const { summaryRow, columns, columnExtensions } = tableContext;

  const { cells } = summaryRow;

  const columnWithWidth = (columnExtensions || []).filter(({ width }) =>
    Number.isInteger(width)
  );

  const columnExtensionsByColumnName = new Map(
    columnExtensions?.map((extension) => [extension.columnName, extension])
  );

  const shouldRenderStubCell = columnWithWidth.length === columns.length;

  return (
    <TableFooter className={classes.root}>
      <TableRow>
        {columns.map((column) => {
          const columnExtension = columnExtensionsByColumnName.get(column.name);

          return (
            <TableCell
              key={column.name}
              colSpan={1}
              className={classes.cell}
              style={{
                // align выше по приоритету, чем alignAll
                textAlign: columnExtension?.align || columnExtension?.alignAll,
              }}
            >
              {cells.find(({ columnName }) => columnName === column.name)
                ?.label || ''}
            </TableCell>
          );
        })}
        {shouldRenderStubCell && (
          <TableCell colSpan={1} className={classes.cell} />
        )}
      </TableRow>
    </TableFooter>
  );
}

export default TableSummaryRowFooter;
