import {
  ChangePasswordRequestBody,
  ChangePasswordResponse,
  ForgotPasswordRequestBody,
  ForgotPasswordResponse,
  SetPasswordRequestBody,
  SetPasswordResponse,
  ValidateTokenRequestBody,
  ValidateTokenResponse,
} from './types';
import PasswordBase from './Password.base';
import { post } from '../Api';

const urls = {
  forgot: process.env.REACT_APP_PASSWORD_FORGOT_URL || '/api/password/forgot',
  validateToken:
    process.env.REACT_APP_PASSWORD_VALIDATE_TOKEN_URL ||
    '/api/password/validate-token',
  set: process.env.REACT_APP_PASSWORD_SET_URL || '/api/password/set',
  change: process.env.REACT_APP_PASSWORD_CHANGE_URL || '/api/password/change',
};

export default class PasswordServiceRemote implements PasswordBase {
  forgot(body: ForgotPasswordRequestBody): Promise<ForgotPasswordResponse> {
    return post(urls.forgot, body).then((res) => res.data);
  }

  validateToken(
    body: ValidateTokenRequestBody
  ): Promise<ValidateTokenResponse> {
    return post(urls.validateToken, body).then((res) => res.data);
  }

  set(body: SetPasswordRequestBody): Promise<SetPasswordResponse> {
    return post(urls.set, body).then((res) => res.data);
  }

  change(body: ChangePasswordRequestBody): Promise<ChangePasswordResponse> {
    return post(urls.change, body).then((res) => res.data);
  }
}
