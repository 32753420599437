import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonLabel: {
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
      },
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: 'inherit',
      },
    },
  })
);
