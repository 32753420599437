import { createContext, Dispatch, SetStateAction } from 'react';
import { DialogWithComponent } from '../../../../../services/Main/types.Component';

interface DialogWithComponentContext {
  dialogWithComponent: DialogWithComponent | null;
  setDialogWithComponent: Dispatch<SetStateAction<DialogWithComponent | null>>;
}

export type DialogWithComponentContextValue = DialogWithComponentContext | null;

export default createContext<DialogWithComponentContextValue>(null);
