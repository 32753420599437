const ALLOWED_COLORS = ['#8865cc', '#e96799', '#ff8e70', '#dabd56'];

export const stringAsciiPRNG = (value: string, m: number): number => {
  const charCodes = value.split('').map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) random = (a * random + c) % m;

  return random;
};

export const getRandomColor = (
  value: string,
  colors = ALLOWED_COLORS
): string => {
  const colorIndex = stringAsciiPRNG(value, colors.length);

  return colors[colorIndex];
};
