import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
    },
    content: {
      '&.scaled': {
        cursor: 'move',
      },
    },
  })
);
