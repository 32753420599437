import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Button } from '@material-ui/core';
import useStyles from '../TreeComboBox.styles';
import { useFormatMessage } from '../../../../../../locale';

interface ExpandTreeButtonProps {
  expanded: boolean;
  onClick: () => void;
}

const TreeExpandButton = ({ expanded, onClick }: ExpandTreeButtonProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const handleClick = () => {
    onClick();
  };

  const label = expanded
    ? formatMessage('collapseAll')
    : formatMessage('expandAll');

  return (
    <Button
      size="small"
      color="primary"
      className={classes.button}
      disableRipple
      startIcon={
        expanded ? (
          <KeyboardArrowDownIcon fontSize="inherit" />
        ) : (
          <KeyboardArrowRightIcon fontSize="inherit" />
        )
      }
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default TreeExpandButton;
