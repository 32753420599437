import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Avatar from '../../Avatar';
import getAuthorAvatarLetters from '../helpers/getAuthorAvatarLetters';
import { TimelineAuthor } from '../../../../../../services/Main/types.Component';
import { useFormatMessage } from '../../../../../../locale';
import useStyles from './OtherUserTimelineElement.styles';

interface Props {
  color: string;
  author: TimelineAuthor;
}

function OtherUserTimelineElementAvatar(props: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { color, author } = props;
  const {
    organization,
    department,
    position,
    phone,
    email,
    firstName,
    lastName,
    secondName,
  } = author;

  const authorFullName = [lastName, firstName, secondName]
    .filter(Boolean)
    .join(' ');

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Avatar color={color} onClick={handleOpen} className={classes.avatar}>
        {getAuthorAvatarLetters(author)}
      </Avatar>
      <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h6" color="textPrimary">
            {authorFullName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {organization && (
            <Typography variant="body2">
              {`${formatMessage('organization')}: ${organization}`}
            </Typography>
          )}
          {department && (
            <Typography variant="body2">
              {`${formatMessage('department')}: ${department}`}
            </Typography>
          )}
          {position && (
            <Typography variant="body2">
              {`${formatMessage('position')}: ${position}`}
            </Typography>
          )}
          {phone && (
            <Typography variant="body2">
              {`${formatMessage('phone')}: ${phone}`}
            </Typography>
          )}
          {email && (
            <Typography variant="body2">
              {`${formatMessage('email')}: ${email}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
}

export default OtherUserTimelineElementAvatar;
