import { Header as HeaderProps } from 'services/Main/types.Component';

export const getHeader = (headerProps?: HeaderProps, heading?: string) => {
  let header: HeaderProps | undefined;
  if (headerProps) {
    header = headerProps;
  } else if (heading) {
    header = { heading };
  }
  return header;
};
