import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    fab1: {
      position: 'fixed',
      top: theme.spacing(1),
      left: theme.spacing(22),
      zIndex: 10000,
    },
    fab2: {
      position: 'fixed',
      top: theme.spacing(1),
      left: theme.spacing(28),
      zIndex: 10000,
    },
    dialogContent: {
      width: 1000,
      height: '90vh',
      overflowY: 'scroll',
      padding: '0 !important',
      backgroundColor: 'rgb(38, 40, 34)',
      fontSize: theme.typography.pxToRem(18),

      '& .pretty-json-container': {
        padding: theme.spacing(2, 3),
      },
    },
    tip: {
      zIndex: 10000,
    },
  })
);
