import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'components/lowLevel/Icon';

const getShortcutXsLabel = (label: string) => {
  if (label.length <= 20) return label;

  return (
    <Box display="flex" alignItems="center">
      {`${label.substring(0, 20)}...`}
      <Tooltip title={label} enterTouchDelay={0}>
        <Box onClick={(e) => e.preventDefault()}>
          <Icon definition="mui:HelpOutline" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export const getLabel = (
  label: string | Element | JSX.Element,
  classes: any,
  isXs: boolean
) => {
  if (typeof label !== 'string') return label;

  if (isXs) {
    return (
      <Box display="flex" className={classes.label}>
        {label.length <= 20 ? label : getShortcutXsLabel(label)}
      </Box>
    );
  }

  return (
    <Tooltip title={label} enterTouchDelay={0}>
      <Box className={classes.label}>
        {label.length <= 20 ? label : `${label.substring(0, 20)}...`}
      </Box>
    </Tooltip>
  );
};
