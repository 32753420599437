import React, { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import hash from 'object-hash';
import { FormAction, FormComponentProps } from 'services/Main/types.Component';
import FormBuilder from '../../lowLevel/FormBuilder';
import Header from '../../lowLevel/Header';
import FormActions from '../../lowLevel/FormActions';
import Alerts from '../../lowLevel/Alerts';
import { FormContext } from './Form.context';
import { useFormatMessage } from '../../../locale';

const Form = (props: FormComponentProps) => {
  const formatMessage = useFormatMessage();
  const { form, alerts, actionsYPosition, actions: actionsFromProps } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // TODO: Выпилить, когда бэк освоит:
  // https://auchan.atlassian.net/l/cp/u1RXZJ5u
  const { historyConfig } = form;

  const actions: FormAction[] = useMemo(() => {
    const res = [...(actionsFromProps ?? [])];

    if (historyConfig) {
      res.push({
        type: 'showHistory',
        label: formatMessage('history'),
        placement: 'extra',
        icon: 'mui:History',
        requestConfig: historyConfig.requestConfig,
      });
    }

    return res;
  }, [actionsFromProps, formatMessage, historyConfig]);
  // \\ TODO

  if (form.type === 'preset') return <h1>Will be soon...</h1>;

  return (
    <FormContext.Provider
      value={{
        globalSocketRules: form.globalSocketRules,
      }}
    >
      <FormBuilder
        key={hash(form)}
        header={() => (
          <Box mb={1}>
            <Box mb={1}>
              <Grid container spacing={1} wrap={isMobile ? 'wrap' : 'nowrap'}>
                <Grid item xs="auto" style={{ overflow: 'hidden' }}>
                  <Header {...form.header} />
                </Grid>
                {actionsYPosition !== 'bottom' && actions.length > 0 ? (
                  <Grid
                    item
                    container
                    justify={isMobile ? 'flex-start' : 'flex-end'}
                    wrap={isMobile ? 'wrap' : 'nowrap'}
                    style={{
                      flexShrink: 10,
                    }}
                    xs
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <FormActions actions={actions} />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
            <Alerts staticAlerts={alerts} />
          </Box>
        )}
        footer={() => (
          <>
            {actionsYPosition === 'bottom' && actions.length > 0 ? (
              <Grid container item spacing={1} xs={12} justify="flex-end">
                <FormActions actions={actions} />
              </Grid>
            ) : null}
          </>
        )}
        fieldGroups={form.fieldGroups}
        globalDisabled={form.disabled}
      />
    </FormContext.Provider>
  );
};

export default Form;
