import React from 'react';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import Chip from './Chip';
import { RenderTagsProps } from '../../helpers/renderTags';
import TagsContainer from './Tags.container';

interface ChipTagsProps extends RenderTagsProps {
  tags: ComboBoxOption[];
  getTagProps: AutocompleteGetTagProps;
}

interface TagProps {
  'data-tag-index': number;
  key: number;
  tabIndex: number;
  onDelete: () => {};
}

const ChipTags = ({
  tags,
  multiline,
  getTagProps,
  disabled,
  disableClearable,
}: ChipTagsProps) => {
  const canRemoveTag = !(disabled || disableClearable);

  return (
    <TagsContainer multiline={multiline}>
      {tags.map((tag, index) => {
        const { label, href } = tag;

        const tagProps = getTagProps({ index });
        const { onDelete } = tagProps as TagProps;

        return (
          <Chip
            key={`${label}_${href}`}
            handleDelete={canRemoveTag ? onDelete : undefined}
            {...tag}
          />
        );
      })}
    </TagsContainer>
  );
};

export default ChipTags;
