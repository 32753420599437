import history from 'utils/history';
import { RedirectReaction } from '../../services/Main/types.Component';

export default ({ redirectTo }: RedirectReaction, target?: '_blank') => {
  if (target && target === '_blank') {
    window.open(redirectTo);
    return;
  }

  history.push(redirectTo);
};
