import { createContext, Dispatch, SetStateAction } from 'react';
import {
  FilterOptions,
  SortingState,
  TableComponentProps,
  TableRow,
} from '../../../services/Main/types.Component';

type AdditionalProps = {
  resetColumnWidthsToDefault: () => void;
  resetColumnOrderToDefault: () => void;
  quickFiltersState: string[];
  onQuickFiltersChange: (newQuickFilters: string[]) => void;
  withClosed: boolean;
  setWithClosed: Dispatch<SetStateAction<boolean>>;
  setHiddenColumnNames: Dispatch<SetStateAction<string[]>>;
  hiddenColumnNames: string[];
  resetColumnWidthToDefault: () => void;
  rowsFromState: TableRow[];
  setRows: Dispatch<SetStateAction<TableRow[]>>;
  filterState?: FilterOptions;
  sortingState?: SortingState;
};

export type TableContextValue = (TableComponentProps & AdditionalProps) | null;
export const TableContext = createContext<TableContextValue>(null);
