import React from 'react';
import { FileUpload as FileUploadProps } from '../../../services/Main/types.Field';
import DropZoneFileUpload from './DropZoneFileUpload';
import ClipFileUploader from './ClipFileUploader';

const FileUpload = ({ viewConfig, ...rest }: FileUploadProps) => {
  switch (viewConfig?.viewStyle) {
    case 'textFieldWithClip':
      return <ClipFileUploader viewConfig={viewConfig} {...rest} />;
    case 'dropZone':
    default:
      return <DropZoneFileUpload viewConfig={viewConfig} {...rest} />;
  }
};

export default FileUpload;
