// собирает пропы для DataTypeProviders
import {
  FormatAs,
  TableColumn,
  DataTypeProvidersProps,
} from 'services/Main/types.Component';

// ищет список колонок для форматирования
function findColumnsToFormat(columns: TableColumn[], formatAs: FormatAs) {
  return columns
    .filter(({ options }) => options && options.formatAs === formatAs)
    .map(({ name }) => name);
}

export function findDataTypes(
  columns: TableColumn[],
  dataTypes: FormatAs[]
): DataTypeProvidersProps {
  const props: DataTypeProvidersProps = {};

  dataTypes.forEach((t) => {
    props[t] = findColumnsToFormat(columns, t);
  });

  return props;
}
