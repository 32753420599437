import { createStyles, makeStyles } from '@material-ui/core';
import theme, { drawerWidth } from 'theme';

export const MAIN_SPACING = theme.spacing(2);

export default makeStyles(() => {
  return createStyles({
    main: {
      padding: MAIN_SPACING,
      marginLeft: drawerWidth.full,
      marginTop: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),

      '&.compactView': {
        marginLeft: drawerWidth.compact,
      },

      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginTop: theme.mixins.toolbar.height,
      },
    },
    paperAnchorLeft: {
      width: drawerWidth.full,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      overflowX: 'hidden', // remove scrollX on toggle menu view

      '&.compactView': {
        width: drawerWidth.compact,
      },
    },
    content: {
      flexGrow: 1,
      overflowY: 'auto',
      overflowX: 'hidden', // remove scrollX on toggle menu view
    },
  });
});
