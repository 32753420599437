import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: '100%',
      padding: theme.spacing(3),
      borderRadius: theme.spacing(3),
    },
  })
);
