import React from 'react';
import {
  ArrayOfProps,
  TableArrayOfProps,
  FormStyleArrayOfProps,
} from './types';
import ArrayOfTable from './ArrayOf.table';
import ArrayOfForm from './ArrayOf.form';

function ArrayOf(props: ArrayOfProps) {
  switch (props.viewStyle) {
    case 'table':
      return <ArrayOfTable {...(props as TableArrayOfProps)} />;

    // Здесь case undefined исключительно для обратной совместимости,
    // чтобы после внедрения viewStyle не сломались ArrayOf старого
    // представления. После полного освоения беком имеет смысл viewStyle
    // сделать обязательным.
    case 'form':
    case undefined:
      return <ArrayOfForm {...(props as FormStyleArrayOfProps)} />;

    default:
      throw new Error(
        `Передан некорректный тип viewStyle для ArrayOf. Ожидалось 'table' | 'form' | undefined, пришло ${
          (props as ArrayOfProps).viewStyle
        }`
      );
  }
}

export default ArrayOf;
