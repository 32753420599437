import React, { PropsWithChildren, useState, MouseEvent } from 'react';
import clsx from 'clsx';
import { Menu, MenuItem } from '@material-ui/core';
import { TimelineElement } from '../../../../../services/Main/types.Component';
import useStyles from './TimelineRowContainer.styles';
import Icon from '../../../../lowLevel/Icon';
import useTimelineContext from '../../hooks/useTimelineContext';
import { useFormatMessage } from '../../../../../locale';

interface Props {
  timelineElement: TimelineElement;
  align: string;
}

type ContextMenuState = { mouseX: number; mouseY: number } | null;

function TimelineRowContainer({
  children,
  timelineElement,
  align,
}: PropsWithChildren<Props>) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { id, body } = timelineElement;

  const { setQuote } = useTimelineContext();

  const [contextMenu, setContextMenu] = useState<ContextMenuState>(null);

  const handleContextMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    // Если контекстное меню уже открыто, повторное нажатие правой кнопкой мыши
    // обычно приводит к его закрытию в некоторых браузерах, например, в Chrome
    // 84 на Ubuntu. Для избежания подобного поведения код проверяет, открыто
    // ли уже контекстное меню, и в случае его наличия закрывает его.
    //
    // Это позволяет избежать нежелательного поведения при повторном вызове
    // контекстного меню и обеспечивает более корректное отображение меню для
    // пользователя.
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleQuoteClick = () => {
    setQuote({
      quotedElementId: id,
      body,
    });
    handleClose();
  };

  return (
    <div
      className={clsx(classes.timelineContainer, {
        [classes.alignLeft]: align === 'left',
        [classes.alignRight]: align === 'right',
        [classes.highlight]: contextMenu !== null,
      })}
      data-timeline-element-id={id}
      onContextMenu={handleContextMenu}
    >
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem className={classes.menuItem} onClick={handleQuoteClick}>
          <Icon definition="mui:Reply" />
          {formatMessage('quote')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default TimelineRowContainer;
