import { useContext, useEffect, useState } from 'react';
import { TableColumn } from '../../../services/Main/types.Component';
import { ComponentContext } from '../../creational/ComponentLoader';
import { useSelector } from '../../../store';

export default (columns: TableColumn[], enabled?: boolean) => {
  const componentContext = useContext(ComponentContext);
  const user = useSelector((state) => state.user);
  const localStorageKey = `${user?.id}_tableColumnOrderState_${componentContext?.id}`;
  const localStorageJSONState =
    enabled && localStorage.getItem(localStorageKey);
  const localStorageState: string[] | false =
    localStorageJSONState && JSON.parse(localStorageJSONState);

  const [columnOrder, setColumnOrder] = useState<string[]>(
    localStorageState || getColumnNames(columns)
  );

  useEffect(() => {
    enabled &&
      localStorage.setItem(localStorageKey, JSON.stringify(columnOrder));
  }, [columnOrder, enabled, localStorageKey]);

  const resetColumnOrderToDefault = () => {
    setColumnOrder(getColumnNames(columns));
  };

  return {
    columnOrder,
    setColumnOrder,
    resetColumnOrderToDefault,
  };
};

function getColumnNames(columns: TableColumn[]) {
  return columns.map((c) => c.name);
}
