import { useMemo } from 'react';
import { IntegratedGrouping } from '@devexpress/dx-react-grid';
import {
  FormatAs,
  GroupingConfig,
  TableColumn,
} from '../../../services/Main/types.Component';

/**
 * Из документации dx:
 * A grouping criterion function. It returns an object with the key field by
 * which data is grouped. If you need to group data by a non-primitive value
 * (for example, a date), assign its string representation to the key field
 * and the value to the value field.
 *
 * Пояснение:
 * Это функция, которая должна вернуть геттер для данных, которые потом
 * отрисуются в виде "*название колонки:* значение, по которому сгруппировали".
 *
 * Возвращает функцию, которая должна вернуть:
 * {
 *   key: '004 Красногорск',
 *   value: {
 *     '004 Красногорск',
 *     href: 'http://auchan.keepup.com/shops/0affe65b-c8f8-4c0a-b236-d489cb94c36b'
 *   },
 * }
 */
const getColumnCriteria = (
  formatAs?: FormatAs
): IntegratedGrouping.ColumnExtension['criteria'] => {
  switch (formatAs) {
    case 'link':
      return (value: any) => ({
        key: value.label,
        value,
      });
    case 'cutTextWithTooltip':
    case undefined:
      return (value: any) => ({
        key: value,
        value,
      });
    default:
      throw new Error(`Группировка по формату ${formatAs} - не реализована`);
  }
};

export function useGroupingColumnExtensions(
  columns: TableColumn[],
  groupingConfig?: GroupingConfig
) {
  return useMemo(() => {
    if (!groupingConfig || !Array.isArray(groupingConfig.defaultGrouping)) {
      // Этот список не поддерживает группировку.
      // Возвращаем null, чтобы не подключать плагины для группировки.
      // TODO сделать, чтобы можно было возвращать null.
      return [];
    }

    const columnByName = new Map<string, TableColumn>(
      columns.map((column) => [column.name, column])
    );

    return groupingConfig.defaultGrouping.reduce<
      IntegratedGrouping.ColumnExtension[]
    >((acc, { columnName }) => {
      const column = columnByName.get(columnName);

      if (!column) {
        throw new Error(
          `Не удалось найти ${columnName} из 'groupingConfig.defaultGrouping' в массиве 'columns'`
        );
      }

      return [
        ...acc,
        {
          columnName: column.name,
          criteria: getColumnCriteria(column.options?.formatAs),
        },
      ];
    }, []);
  }, [columns, groupingConfig]);
}
