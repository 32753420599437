import React, { MouseEvent, useContext, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Icon from '../../../../../Icon/Icon';
import { useFormatMessage } from '../../../../../../../locale';
import EntryPickerTableContext from '../../EntryPickerTable.context';

const EntryPickerTableActions = () => {
  const formatMessage = useFormatMessage();
  const {
    tableProps,
    resetColumnWidthsToDefault,
    resetColumnVisibilityToDefault,
    resetColumnOrderToDefault,
  } = useContext(EntryPickerTableContext);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const isMenuOpen = Boolean(dropdownAnchorEl);

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  if (!tableProps) return null;

  const handleResetColumnOrderToDefaultClick = () => {
    resetColumnOrderToDefault();
    handleMenuClose();
  };

  const handleResetColumnWidthToDefaultClick = () => {
    resetColumnWidthsToDefault();
    handleMenuClose();
  };

  const handleResetColumnVisibilityToDefaultClick = () => {
    resetColumnVisibilityToDefault();
    handleMenuClose();
  };

  const menuItems = (() => {
    const items: any[] = [];

    if (tableProps.columnVisibilityConfig?.enabled) {
      items.push({
        icon: 'mui:Restore',
        label: formatMessage('resetColumnVisibilityToDefault'),
        onClick: handleResetColumnVisibilityToDefaultClick,
      });
    }

    if (tableProps.enableColumnResizing) {
      items.push({
        icon: 'mui:Restore',
        label: formatMessage('resetColumnWidthsToDefault'),
        onClick: handleResetColumnWidthToDefaultClick,
      });
    }

    if (tableProps.enableColumnReordering) {
      items.push({
        icon: 'mui:Restore',
        label: formatMessage('resetColumnOrderToDefault'),
        onClick: handleResetColumnOrderToDefaultClick,
      });
    }

    return items;
  })();

  if (menuItems.length === 0) return null;

  return (
    <>
      <IconButton onClick={handleIconClick}>
        <Icon definition="mui:MoreVert" />
      </IconButton>
      <Menu
        anchorEl={dropdownAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ onClick, icon, label }) => (
          <MenuItem onClick={onClick}>
            <Icon definition={icon} style={{ marginRight: 10 }} />
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EntryPickerTableActions;
