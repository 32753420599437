import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Icon from '../../Icon/Icon';
import useStyles from './TableFilterIconButton.styles';

type TableFilterIconButtonProps = {
  onClick: () => void;
  filterCount: number;
};

export default function TableFilterIconButton({
  onClick,
  filterCount = 0,
}: TableFilterIconButtonProps) {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick}>
      <Badge
        badgeContent={filterCount > 9 ? '9+' : filterCount}
        classes={{
          anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
        }}
        color="primary"
      >
        <Icon
          definition={
            filterCount > 0 ? 'local:FilterAltSharp' : 'local:FilterAltOutlined'
          }
        />
      </Badge>
    </IconButton>
  );
}
