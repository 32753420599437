/**
 * Функция для проверки объекта даты на
 * состояние invalidDate.
 *
 * @param d
 */
export default function isValidDate(d: any) {
  // @ts-ignore
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}
