import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let TransparentRect: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <rect />
  </SvgIcon>
);
TransparentRect.muiName = 'SvgIcon';

TransparentRect = React.memo(TransparentRect);
TransparentRect.displayName = 'Stub';

export default TransparentRect;
