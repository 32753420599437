// @ts-ignore
import { TelegramClient } from 'messaging-api-telegram';

const BOT_ID = '1073272823:AAFwIXSfvv1-RykZc4OlJ8aMqtltVD36fug';

const USERS_TO_NOTIFY = [
  102749892, // Pasechnik Vladisav @PasVV
];

// get accessToken from telegram [@BotFather](https://telegram.me/BotFather)
export const bot = TelegramClient.connect(BOT_ID);

export const sendMessageToBot = (message: string) => {
  USERS_TO_NOTIFY.forEach((userId) => {
    bot.sendMessage(userId, message, { parse_mode: 'markdown' });
  });
};
