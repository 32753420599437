import { RequestConfig } from '../Main/types.Component';
import { get, post } from '../Api';
import { ServerResponse } from '../Main/types';
import {
  FormHistoryInterface,
  FormHistoryResponse,
} from './FormHistory.interface';

class FormHistoryRemote implements FormHistoryInterface {
  fetchFormHistory = ({ url, method }: RequestConfig) => {
    const fetch = method === 'POST' ? post : get;

    return fetch<ServerResponse<FormHistoryResponse>>(url).then(
      ({ data }) => data
    );
  };
}

export default FormHistoryRemote;
