import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Shop: any = (props: any) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 448 448"
    xmlSpace="preserve"
    fill="#000000"
    {...props}
  >
    <path d="m248 296h32v32h-32zm0 0" />
    <path d="m152 280h16v16h-16zm0 0" />
    <path d="m120 200h80v-80h-80zm16-56c0-4.417969 3.582031-8 8-8h32c4.417969 0 8 3.582031 8 8v32c0 4.417969-3.582031 8-8 8h-32c-4.417969 0-8-3.582031-8-8zm0 0" />
    <path d="m120 328h80v-80h-80zm16-56c0-4.417969 3.582031-8 8-8h32c4.417969 0 8 3.582031 8 8v32c0 4.417969-3.582031 8-8 8h-32c-4.417969 0-8-3.582031-8-8zm0 0" />
    <path d="m152 152h16v16h-16zm0 0" />
    <path d="m280 152h16v16h-16zm0 0" />
    <path d="m224 0c-123.710938 0-224 100.289062-224 224s100.289062 224 224 224 224-100.289062 224-224c-.140625-123.652344-100.347656-223.859375-224-224zm-8 336c0 4.417969-3.582031 8-8 8h-96c-4.417969 0-8-3.582031-8-8v-96c0-4.417969 3.582031-8 8-8h96c4.417969 0 8 3.582031 8 8zm0-128c0 4.417969-3.582031 8-8 8h-96c-4.417969 0-8-3.582031-8-8v-96c0-4.417969 3.582031-8 8-8h96c4.417969 0 8 3.582031 8 8zm16 32c0-4.417969 3.582031-8 8-8h32v16h-24v24h-16zm72 16v16h-40v-16zm-8 80c0 4.417969-3.582031 8-8 8h-48c-4.417969 0-8-3.582031-8-8v-48c0-4.417969 3.582031-8 8-8h48c4.417969 0 8 3.582031 8 8zm48 0c0 4.417969-3.582031 8-8 8h-32v-16h24v-40h-16v-16h24c4.417969 0 8 3.582031 8 8zm0-72h-16v-16h-40v-16h48c4.417969 0 8 3.582031 8 8zm0-56c0 4.417969-3.582031 8-8 8h-96c-4.417969 0-8-3.582031-8-8v-16h16v8h80v-80h-80v56h-16v-64c0-4.417969 3.582031-8 8-8h96c4.417969 0 8 3.582031 8 8zm-80-32v-32c0-4.417969 3.582031-8 8-8h32c4.417969 0 8 3.582031 8 8v32c0 4.417969-3.582031 8-8 8h-32c-4.417969 0-8-3.582031-8-8zm0 0" />
  </SvgIcon>
);
Shop.muiName = 'SvgIcon';

Shop = React.memo(Shop);
Shop.displayName = 'Shop';

export default Shop;
