import React from 'react';
import measureSvgTextWidth from '../../../../utils/measureSvgTextWidth';

// Желаемый оффет сверху, который мы вычитаем из оси Y, чтобы определить
// в какую сторону рисовать BarLabel (вверх или вниз).
const TOP_OFFSET = 30;

const MIN_FONT_SIZE = 6;
const MAX_FONT_SIZE = 11;

type CustomizedLabelProps = {
  fill: string;
} & any;
const BarLabel = (props: CustomizedLabelProps) => {
  const { x, y, width, value, fill } = props;

  // Вычисляем размер шрифта лейбла.
  // Для "скукоженных" будет рассчитываться относительно
  // ширины Bar, иначе maxFontSize.
  const fontSize = width - 2 < MAX_FONT_SIZE ? width - 2 : MAX_FONT_SIZE;

  if (fontSize < MIN_FONT_SIZE) return null;

  const labelRenderWidth = measureSvgTextWidth(
    (value || '').toString(),
    `${fontSize}px`,
    window.getComputedStyle(document.body).fontFamily
  );

  const drawPosition = y - TOP_OFFSET < labelRenderWidth ? 'inside' : 'outside';

  if (drawPosition === 'inside')
    return (
      <text
        dominantBaseline="central"
        fontSize={fontSize}
        fill="#fff"
        textAnchor="end"
        transform={`translate(${x + width / 2}, ${y + 4}) rotate(-90)`}
      >
        {value}
      </text>
    );

  if (drawPosition === 'outside')
    return (
      <text
        dominantBaseline="central"
        fontSize={fontSize}
        fill={fill}
        textAnchor="start"
        transform={`translate(${x + width / 2}, ${y - 4}) rotate(-90)`}
      >
        {value}
      </text>
    );

  return null;
};

export default BarLabel;
