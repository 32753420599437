import { createContext, Dispatch, SetStateAction } from 'react';
import { DirtyFormValues } from '../../../services/Main/types.Component';
import {
  ComboBoxOption,
  Field,
  TreeSelectOption,
} from '../../../services/Main/types.Field';

export type FormSubmitHandlers = {
  [key: string]: FormSubmitHandler;
} | null;

export type FormSubmitHandler = (dirtyValues: DirtyFormValues) => void;

export type SelectOptions = Record<
  string,
  ComboBoxOption[] | TreeSelectOption[] | null
>;

export interface FormBuilderContextValue {
  onSubmitHandlers: FormSubmitHandlers;
  setOnSubmitHandlers: Dispatch<SetStateAction<FormSubmitHandlers>>;
  firedSubmitAction: string | null;
  setFiredSubmitAction: (actionName: string) => void;
  fields: Field[];
  fieldsByName: Record<string, Field>;
  setSelectOptions: Dispatch<SetStateAction<SelectOptions>>;
  selectOptions: SelectOptions;
}

export default createContext<FormBuilderContextValue>({
  onSubmitHandlers: null,
  setOnSubmitHandlers: () => null,
  firedSubmitAction: null,
  setFiredSubmitAction: () => {},
  fields: [],
  fieldsByName: {},
  setSelectOptions: () => {},
  selectOptions: {},
});
