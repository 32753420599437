import { post } from 'services/Api';
import { AuthServiceBase } from '../AuthService.base';

export default class AuthServiceRemote extends AuthServiceBase {
  login = (login: string, password: string) => {
    return post('/auth/v1.0/credential', { login, password }).then(
      this.handleLoginResponse
    );
  };

  loginWithGoogle = (tokenId: string) => {
    return post('/auth/v1.0/google', { tokenId }).then(
      this.handleLoginResponse
    );
  };

  updateToken = () => {
    const data = {
      expiredToken: this.token,
      refreshToken: this.refreshToken,
    };

    return post('/auth/v1.0/refresh-token', data).then(
      this.handleLoginResponse
    );
  };
}
