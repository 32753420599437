import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { TabsViewPage } from 'services/Main/types.Page';
import ComponentLoader from '../../creational/ComponentLoader';
import useStyles from '../pageViews.styles';
import { getHash, resetHash } from '../../../utils/hashHelpsers';

const Tabs = ({ tabs }: TabsViewPage) => {
  const classes = useStyles();
  const history = useHistory();

  const [tab, setTab] = React.useState(getInitialTab(tabs.length));

  useEffect(() => {
    setTab(+getHash());
  }, [history.location.hash]);

  const handleChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    if (window.location.hash !== `#${newTab}`) {
      if (newTab === 0) {
        history.replace({ hash: undefined });
      } else {
        history.replace({ hash: `#${newTab}` });
      }
    }
    setTab(newTab);
  };

  return (
    <Paper>
      <AppBar position="static" color="transparent">
        <MuiTabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map(({ label }) => (
            <Tab label={label} key={`${label}_tab`} />
          ))}
        </MuiTabs>
      </AppBar>
      {tabs.map(
        ({ label, component }, index) =>
          tab === index && (
            <Box key={`${label}_body`} className={classes.contentContainer}>
              <ComponentLoader component={component} />
            </Box>
          )
      )}
    </Paper>
  );
};

function getInitialTab(tabsLength: number) {
  const tabFromHash = getHash();
  const defaultTab = 0;

  // Если нет хеша, возвращаем первую вкладку
  if (!tabFromHash) {
    return defaultTab;
  }

  const tab = +tabFromHash;

  // Подходит только от одного до tabsLength
  if (tab > 0 && tab < tabsLength) {
    // Исключаем дробные числа
    return Math.round(tab);
  }

  resetHash();

  return defaultTab;
}

export default Tabs;
