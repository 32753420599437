import React, { useState } from 'react';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { Grid } from '@material-ui/core';
import passwordService from '../../../services/Password';
import PasswordForgotPageForm from './PasswordForgotPage.Form';
import PasswordForgotPageSuccessResultDialog from './PasswordForgotPage.SuccessResultDialog';
import { ForgotPasswordResponse } from '../../../services/Password/types';

const PasswordForgotPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<ForgotPasswordResponse | null>(null);

  // Типизация UnpackNestedValue<{ login: string, email: string }>
  // или UnpackNestedValue<Record<string, any>>
  const onSubmit = ({
    login,
    email,
  }: UnpackNestedValue<{ login: string; email: string }>) => {
    setIsLoading(true);

    passwordService.forgot({ login, email }).then((response) => {
      setIsLoading(false);

      setResponse(response);
    });
  };

  return (
    <Grid container justify="center">
      <Grid item xs={11} sm={8} md={6}>
        {response && response.isSuccess ? (
          <PasswordForgotPageSuccessResultDialog
            title={response.payload.title}
            message={response.payload.message}
          />
        ) : (
          <PasswordForgotPageForm
            isLoading={isLoading}
            onSubmit={onSubmit}
            response={response}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PasswordForgotPage;
