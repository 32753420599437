import React, { useContext, useMemo, ChangeEvent } from 'react';
import {
  Select,
  InputBase,
  MenuItem,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import useStyles from '../../EntryPickerTable.styles';
import { useFormatMessage } from '../../../../../../../locale';

const PagingPanel = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const {
    totalRows,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    loadRows,
    filterOptions,
    sorting,
    setIsLoading,
    tableProps,
  } = useContext(EntryPickerTableContext);

  const handleChange = (e: ChangeEvent<unknown>, newPage: number) => {
    setIsLoading(true);
    setCurrentPage(newPage - 1);

    loadRows({
      pageSize,
      currentPage: newPage - 1,
      filter: filterOptions,
      sort: sorting,
    });
  };

  const handleChangePageSize = (event: ChangeEvent<{ value: unknown }>) => {
    setIsLoading(true);
    const newPageSize = event.target.value as number;

    setPageSize(newPageSize);
    setCurrentPage(0);

    loadRows({
      pageSize: newPageSize,
      currentPage: 0,
      filter: filterOptions,
      sort: sorting,
    });
  };

  const totalPages = useMemo(
    () => Math.ceil(totalRows / pageSize),
    [totalRows, pageSize]
  );

  if (!tableProps) return null;

  return (
    <Grid container spacing={1} className={classes.pagingPanelContainer}>
      {Array.isArray(tableProps.options.pageSizeOptions) && (
        <Grid item>
          <Typography component="span" variant="body2">
            {`${formatMessage('rowsPerPage')}:`}
          </Typography>
          <Select
            classes={{
              select: classes.select,
            }}
            input={<InputBase className={clsx(classes.input)} />}
            value={pageSize}
            onChange={handleChangePageSize}
          >
            {tableProps.options.pageSizeOptions.map((rowsPerPageOption) => (
              <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                {rowsPerPageOption}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      <Grid item>
        <Pagination
          count={totalPages}
          page={currentPage + 1}
          size={isMdUp ? 'medium' : 'small'}
          onChange={handleChange}
          showFirstButton={isMdUp}
          showLastButton={isMdUp}
        />
      </Grid>
    </Grid>
  );
};

export default PagingPanel;
