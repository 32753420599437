import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import ComponentLoader from '../../../../creational/ComponentLoader';
import useTableDialogWithComponentContext from './useTableDialogWithComponentContext';

const TableDialogWithComponent = () => {
  const { dialogWithComponent, setDialogWithComponent } =
    useTableDialogWithComponentContext();

  const handleClose = () => {
    setDialogWithComponent(null);
  };

  if (!dialogWithComponent) return null;

  const { component, viewStyle } = dialogWithComponent;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={viewStyle === 'fullScreenModal'}
      onClose={handleClose}
      aria-labelledby="dialog-with-component"
      open={!!component}
    >
      <Box p={[2, 3]}>
        <ComponentLoader component={component} />
      </Box>
    </Dialog>
  );
};

export default TableDialogWithComponent;
