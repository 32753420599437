import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { TableColumnVisibility as TableColumnVisibilityBase } from '@devexpress/dx-react-grid';
import { useFormatMessage } from '../../../../../locale';
import useStyles from './TableColumnVisibilityMessage.styles';
import { TableContext } from '../../TableContext';

const TableColumnVisibilityMessage = ({
  getMessage,
}: TableColumnVisibilityBase.EmptyMessageProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const tableContext = useContext(TableContext);

  const noColumnsMessage = getMessage('noColumns');

  const handleResetColumnVisibilityToDefaultClick = () => {
    tableContext?.resetColumnWidthToDefault();
  };

  return (
    <Box className={classes.messageContainer}>
      <Typography>{noColumnsMessage}</Typography>
      <u
        className={classes.pseudoLink}
        onMouseDown={handleResetColumnVisibilityToDefaultClick}
      >
        {formatMessage('resetColumnVisibilityToDefault')}
      </u>
    </Box>
  );
};

export default TableColumnVisibilityMessage;
