import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    img: {
      maxWidth: '100%',
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
    },
  })
);
