import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    table: {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      '& .MuiTableHead-root': {
        position: 'sticky',
        fallbacks: {
          position: '-webkit-sticky',
        },
        zIndex: 500,
        top: 0,
        left: 0,
        right: 0,
        overflow: 'visible',
        background: theme.palette.background.paper,
      },
      '& .MuiTableCell-stickyHeader': {
        background: theme.palette.background.paper,
      },
    },
  })
);
