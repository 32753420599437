import { useCallback } from 'react';
import useStyles from './useBodyBlock.styles';

type UseBlockPointEventsReturns = {
  blockInterface: () => void;
  unblockInterface: () => void;
};

export function useInterfaceBlocker(): UseBlockPointEventsReturns {
  const classes = useStyles();

  const blockInterface = useCallback(() => {
    document.body.classList.add(classes.block);
    // Игнорируем подписку на classes
    // eslint-disable-next-line
  }, []);

  const unblockInterface = useCallback(() => {
    document.body.classList.remove(classes.block);
    // Игнорируем подписку на classes
    // eslint-disable-next-line
  }, []);

  return {
    blockInterface,
    unblockInterface,
  };
}
