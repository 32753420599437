import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { dialogWithComponentActions } from '../../../store/dialogWithComponent';
import { CloseDialogAction as CloseDialogActionProps } from '../../../services/Main/types.Component';
import TableDialogWithComponentContext from '../../highLevel/Table/components/TableDialogWithComponent/TableDialogWithComponent.context';

interface CloseDialogButtonActionProps {
  action: CloseDialogActionProps;
  onClick: () => void;
}

interface Props {
  action: CloseDialogActionProps;
  renderButton: (props: CloseDialogButtonActionProps) => JSX.Element;
}

const CloseDialogAction = ({ action, renderButton }: Props) => {
  const dispatch = useDispatch();
  const tableDialogWithComponentContext = useContext(
    TableDialogWithComponentContext
  );

  const handleClick = () => {
    dispatch(dialogWithComponentActions.close());
    if (tableDialogWithComponentContext?.dialogWithComponent) {
      tableDialogWithComponentContext.setDialogWithComponent(null);
    }
  };

  return renderButton({
    action,
    onClick: handleClick,
  });
};

export default CloseDialogAction;
