import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import FormBuilderContext from '../FormBuilder/FormBuilderContext';
import {
  ComboBoxOption,
  Field,
  RadioTreeSelectOption,
  TreeSelectOption,
} from '../../../services/Main/types.Field';

// Проверка на след. структуру fieldName: arrayOf[0].shop
export const isTheseFieldsFromArrayOf = (fieldName: string) => {
  const arrayOfDetectRegex = /\[\d+]/g;

  return arrayOfDetectRegex.test(fieldName);
};

// Разделяем arrayOf[0].shop на arrayOf, 0, shop
export const getArrayOfFieldNames = (fieldName: string) => {
  const [arrayOfName, fieldRowIndex, childFieldName] =
    fieldName.split(/\[|\]./);

  return {
    arrayOfName,
    fieldRowIndex: +fieldRowIndex,
    childFieldName,
  };
};

export const useInitialOptions = (
  fieldName: string
): ComboBoxOption[] | TreeSelectOption[] => {
  const { selectOptions } = useContext(FormBuilderContext);

  if (isTheseFieldsFromArrayOf(fieldName)) {
    // Кокретный пулл опшинов для строки, который пришел с бэка.
    if (selectOptions[fieldName]) {
      return selectOptions[fieldName] || [];
    }

    const { arrayOfName, childFieldName } = getArrayOfFieldNames(fieldName);

    return selectOptions[`${arrayOfName}.${childFieldName}`] || [];
  }

  return selectOptions[fieldName] || [];
};

export const useOnlyUnique = (
  fieldName: string,
  options: ComboBoxOption[] | TreeSelectOption[]
) => {
  const { fieldsByName } = useContext(FormBuilderContext);
  const { watch } = useFormContext();

  if (isTheseFieldsFromArrayOf(fieldName)) {
    const { arrayOfName, fieldRowIndex, childFieldName } =
      getArrayOfFieldNames(fieldName);

    // Получаем пропсы по имени поля arrayOf
    const arrayOfProps: Field | undefined = fieldsByName[arrayOfName];

    // Если в пропсах arrayOf есть onlyUnique, то вычисляем опшины
    if (
      arrayOfProps &&
      arrayOfProps.type === 'arrayOf' &&
      arrayOfProps.onlyUnique &&
      Array.isArray(arrayOfProps.onlyUnique) &&
      arrayOfProps.onlyUnique.length > 0
    ) {
      const { onlyUnique, rowDefinition } = arrayOfProps;

      // Если текущие имя поля не содержится в onlyUnique, то возвращаем опшины
      if (!onlyUnique.includes(childFieldName)) return options;

      // Ищем пропсы дочернего поля
      const childFieldProps = rowDefinition.find(
        (field) => field.name === childFieldName
      );

      // Если не удалось найти пропсы дочернего поля или поля имеет тип не comboBox
      if (!childFieldProps || childFieldProps.type !== 'comboBox')
        return options;

      if (
        (childFieldProps.optionsType === 'flat' && childFieldProps.multiple) ||
        childFieldProps.optionsType === 'checkboxTree'
      ) {
        console.warn(
          'Поля MultipleFlatComboBox и CheckboxTreeComboBox не поддерживают onlyUnique',
          {
            onlyUnique,
            fieldName,
            childFieldProps,
          }
        );
        return options;
      }

      // Подписываемся на значения arrayOf
      const arrayOfValues = watch(arrayOfName);

      // Если в значениях формы у arrayOf НЕТ значений, то возвращаем опшины
      if (!Array.isArray(arrayOfValues)) {
        return options;
      }

      // Собираем массив values, которые уже выбраны
      const uniqueValues = arrayOfValues.reduce(
        (acc, rowFieldValues, rowIndex) => {
          // Пропускаем текущее поле, т.к выбранный опшин нужно оставить в
          // текущем поле
          if (rowIndex === fieldRowIndex) {
            return acc;
          }

          // Проверяем есть ли значение в текущем поле строки
          if (rowFieldValues[childFieldName]) {
            // Single поля
            if (typeof rowFieldValues[childFieldName] === 'object') {
              return [...acc, rowFieldValues[childFieldName].value];
            }
          }

          return acc;
        },
        [] as string[]
      );

      // Если массив выбранных value пуст, то возвращаем общий пулл опшинов
      // Исключает лишний пробег по опшинам, в случаи когда у нас в ArrayOf
      // прилетел defaultValue только для одной строки.
      if (uniqueValues.length === 0) return options;

      // Возвращаем отфильтрованные опшины
      // Для RadioTreeComboBox
      if (childFieldProps.optionsType === 'radioTree') {
        // Собираем новый массив опшинов.
        return (options as RadioTreeSelectOption[]).reduce((acc, option) => {
          // Если текущее value опшина содержится в массиве с выбранными значениями
          if (uniqueValues.includes(option.value)) {
            // Вариант 1. Скрываем листья, а у веток просто отключаем selectable
            // Если у него нет родителя (parentValue: null)
            // Или если он является у кого-то родителям, то отключаем возможность выбирать опшин
            if (
              !option.parentValue ||
              (options as RadioTreeSelectOption[]).some(
                (o) => o.parentValue === option.value
              )
            ) {
              return [
                ...acc,
                {
                  ...option,
                  selectable: false,
                },
              ];
            }

            // Иначе не добавляем его в конечный список опшинов
            return acc;

            // // Вариант 2. Если выбран, то отключаем selectable
            // // то отключаем selectable
            // return [
            //   ...acc,
            //   {
            //     ...option,
            //     selectable: false,
            //   },
            // ];
          }

          return [...acc, option];
        }, [] as RadioTreeSelectOption[]);
      }

      // Для FlatComboBox
      return options.filter((option) => !uniqueValues.includes(option.value));
    }
  }

  return options;
};

export const useSelectOptions = (fieldName: string) => {
  // Получаем список опшинов
  const initialOptions = useInitialOptions(fieldName);

  // Хук который инкапсулирует логику onlyUnique
  return useOnlyUnique(fieldName, initialOptions);
};
