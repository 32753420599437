import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    fieldset: {
      border: 'none',
      padding: 0,
      margin: 0,
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
  })
);
