import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ColumnVisibilityConfig,
  TableColumn,
} from '../../../services/Main/types.Component';
import { ComponentContext } from '../../creational/ComponentLoader';
import { useSelector } from '../../../store';

export default (
  columns: TableColumn[],
  columnVisibilityConfig?: ColumnVisibilityConfig
): [string[], Dispatch<SetStateAction<string[]>>, () => void] => {
  const componentContext = useContext(ComponentContext);
  const user = useSelector((state) => state.user);
  const localStorageKey = `${user?.id}_tableHiddenColumnNamesState_${componentContext?.id}`;

  const defaultHiddenColumnNames =
    (columnVisibilityConfig?.enabled &&
      columnVisibilityConfig.defaultHiddenColumnNames) ||
    [];

  const localStorageJSONState =
    columnVisibilityConfig?.enabled && localStorage.getItem(localStorageKey);
  const localStorageState: string[] =
    localStorageJSONState && JSON.parse(localStorageJSONState);

  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(
    localStorageState || defaultHiddenColumnNames
  );

  useEffect(() => {
    columnVisibilityConfig?.enabled &&
      localStorage.setItem(localStorageKey, JSON.stringify(hiddenColumnNames));
  }, [hiddenColumnNames, columnVisibilityConfig, localStorageKey]);

  const resetColumnWidthToDefault = () => {
    setHiddenColumnNames(defaultHiddenColumnNames);
  };

  return [hiddenColumnNames, setHiddenColumnNames, resetColumnWidthToDefault];
};
