import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { arrayToTree, TreeItem } from 'performant-array-to-tree';
import {
  determinateOptionsViewStyleReducer,
  getParentValues,
  useGroupedNodesByRootValue,
  useGroupedTreeLabelsByRootValue,
} from './treeHelpers';
import { TreeSelectOption } from '../../../../../services/Main/types.Field';

/**
 * *1. У каждого рута рекурсивно собрать все его дочерние элементы.
 * 2. На основе собранных данных - собираем массив всех [лейблов.toLowerCase()].join(' ').
 * *3. Создаём стейт isTreeHidden = false для хранения статуса видимости дерева.
 * 4. Создаем стейт для хранения filterText.
 * 5. Пользователь вводит в input, что вызывает handleInputChange и изменяет стейт filterText.
 * 6. Каждое изменение filterText вызывает useEffect(). Если filterText пустой или меньше 3 символов изменяем на стейт
 * node на defaultNode и  isTreeEmpty = false. выходим из useEffect().
 * 7. Если больше 3 символов, то пробегаемся по рутам дерева и убираем из списка рутов те руты, которые  в массиве
 * лейблов не содержат filterText.
 * 8. Если все руты скрыты, то скрываем дерево и выводим надпись "Нет вариантов". Выход из useEffect().
 * 9. Если остались руты, то показываем дерево setIsTreeHidden(true) то раскрываем их дочерние options
 * (...groupedNodesByRootValue[rootValue])) в preparedOptions.
 * 10. Затем preparedOptions закидываем в нашу функцию редусер.
 * ...
 * 11. Получаем newExpanded
 * Устанавливаем setExpanded
 * Устанавливаем setNodes
 * ...
 */
interface UseLikeSearchProps {
  defaultNodes: TreeItem[];
  options: TreeSelectOption[];
  setExpanded: Dispatch<SetStateAction<string[] | undefined>>;
  setNodes: Dispatch<SetStateAction<TreeItem[]>>;
}

const useLikeSearch = ({
  defaultNodes,
  options,
  setExpanded,
  setNodes,
}: UseLikeSearchProps) => {
  const groupedNodesByRootValue = useGroupedNodesByRootValue(defaultNodes);
  const groupedTreeLabelsByRootValue = useGroupedTreeLabelsByRootValue(
    groupedNodesByRootValue
  );

  const [filterText, setFilterText] = useState<string>('');
  const [isTreeHidden, setIsTreeHidden] = useState<boolean>(false);

  useEffect(() => {
    if (!filterText || filterText.length < 3) {
      setNodes(defaultNodes);
      setIsTreeHidden(false);
      return;
    }

    const filteredRootValues = Object.keys(groupedTreeLabelsByRootValue).filter(
      (rootValue) =>
        groupedTreeLabelsByRootValue[rootValue].includes(
          filterText.toLowerCase()
        )
    );

    if (filteredRootValues.length === 0) {
      setIsTreeHidden(true);
      return;
    }

    const preparedOptions = filteredRootValues
      .map((rootValue) => groupedNodesByRootValue[rootValue])
      .flat();

    const {
      newOptions,
      highlightedOptions, // с совпадением
    } = determinateOptionsViewStyleReducer(
      preparedOptions as TreeSelectOption[],
      filterText
    );

    const newExpanded = highlightedOptions
      .map((ho) => getParentValues(options, ho.value, true))
      .flat();

    setIsTreeHidden(false);
    setExpanded(newExpanded);
    setNodes(
      arrayToTree(newOptions, {
        id: 'value',
        parentId: 'parentValue',
        dataField: null,
      })
    );
  }, [
    groupedTreeLabelsByRootValue,
    groupedNodesByRootValue,
    filterText,
    options,
    defaultNodes,
    setExpanded,
    setNodes,
  ]);

  return {
    isTreeHidden,
    filterText,
    setFilterText,
  };
};

export default useLikeSearch;
