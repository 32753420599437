import { TimelineElement } from '../../../../../../services/Main/types.Component';
import { getRandomColor } from '../../../../../lowLevel/Avatar/helpers';
import getAuthorFullName from './getAuthorFullName';
import theme from '../../../../../../theme';

function getAuthorColor(timelineElement?: TimelineElement): string {
  if (!timelineElement) return theme.palette.text.secondary;

  if (timelineElement.type === 'system') {
    return theme.palette.primary.main;
  }

  if (timelineElement.type === 'currentUser') {
    return theme.palette.success.main;
  }

  return getRandomColor(
    `${getAuthorFullName(timelineElement)} ${timelineElement.author.id}`
  );
}

export default getAuthorColor;
