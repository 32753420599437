// Функция isTouchDevice проверяет, поддерживает ли устройство сенсорный экран
function isTouchDevice() {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        // @ts-ignore
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          // @ts-ignore
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  );
}

export default isTouchDevice;
