import React from 'react';
import {
  DatePicker,
  DateRangePicker,
  DateTimePicker,
  DateTimeRangePicker,
  SimpleDatePicker,
  YearPicker,
} from 'services/Main/types.Field';
import { UnexpectedDataError } from 'utils/errors/UnexpectedDataError';
import Date from './pickers/Date';
import DateTime from './pickers/DateTime';
import DateRange from './pickers/DateRange';
import DateTimeRange from './pickers/DateTimeRange';
import Year from './pickers/Year';

export type DatePickerProps = DatePicker;

export default (props: DatePickerProps) => {
  const { pickerType } = props;

  switch (pickerType) {
    case 'date':
      return <Date {...(props as SimpleDatePicker)} />;
    case 'dateTime':
      return <DateTime {...(props as DateTimePicker)} />;
    case 'dateRange':
      return <DateRange {...(props as DateRangePicker)} />;
    case 'dateTimeRange':
      return <DateTimeRange {...(props as DateTimeRangePicker)} />;
    case 'year':
      return <Year {...(props as YearPicker)} />;
    default:
      throw new UnexpectedDataError(
        `Не удалось распознать тип DatePicker'a. Ожидалось 'date'|'dateTime'|'dateRange'|'dateTimeRange'. Пришло ${pickerType}`
      );
  }
};
