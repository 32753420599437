import { DocRenderer } from 'react-doc-viewer';
import ImageRenderer from './ImageRenderer';
import PDFRenderer from './PDFRenderer';
import MicrosoftRenderer from './MicrosoftRenderer';
import TextRenderer from './TextRenderer';
import TiffRenderer from './TiffRenderer';

const DocViewerRenderers: DocRenderer[] = [
  ImageRenderer,
  PDFRenderer,
  MicrosoftRenderer,
  TextRenderer,
  TiffRenderer,
];

export const supportedFileViewType = [
  ...ImageRenderer.fileTypes,
  ...PDFRenderer.fileTypes,
  ...MicrosoftRenderer.fileTypes,
  ...TextRenderer.fileTypes,
  ...TiffRenderer.fileTypes,
];

export default DocViewerRenderers;
