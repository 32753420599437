import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.additionalPalette.border}`,
      borderRadius: theme.spacing(2),
      minWidth: 140,
      height: '100%',
      backgroundColor: fade(theme.palette.primary.main, 0.03),
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    metric: {
      overflowWrap: 'break-word',
      marginBottom: theme.spacing(0.5),
    },
    description: {
      color: theme.palette.secondary.dark,
    },
    alertWrapper: {
      '&&': {
        width: '90%',
        overflow: 'hidden',

        '& .MuiAlert-icon': {
          display: 'none',
        },

        '& .MuiAlertTitle-root': {
          fontSize: '0.75rem',
        },

        '& .MuiAlert-root': {
          fontSize: '0.7rem',
        },

        '& .MuiAlert-message *': {
          display: '-webkit-box',
          lineClamp: 1,
          WebkitLineClamp: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  })
);
