import React from 'react';
import { File } from '../../../../../services/Main/types.Field';
import isTouchDevice from '../../../../../utils/isTouchDevice';
import AttachmentTouch from './Attachment.Touch';
import AttachmentClick from './Attachment.Click';

interface Props {
  attachment: File;
}

function Attachment(props: Props) {
  if (isTouchDevice()) {
    return <AttachmentTouch {...props} />;
  }

  return <AttachmentClick {...props} />;
}

export default Attachment;
