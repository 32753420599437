import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    clickable: {
      cursor: 'pointer',
      textOverflow: 'unset',
      textAlign: 'center',
      '&:hover': {
        backgroundColor: 'rgba(16, 156, 241, 0.03) !important',
        userSelect: 'none',
      },
    },
    dragging: {
      position: 'relative',
    },
    loading: {
      pointerEvents: 'none',
    },
  })
);
