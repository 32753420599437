/**
 * Функционал из библиотеки recharts, поскольку он некорректно экспортируется
 *
 * import { adaptEventsOfChild } from 'recharts/types/util/types'
 */
type RecordString<T> = Record<string, T>;

const getEventHandlerOfChild =
  (originalHandler: Function, data: any, index: number) =>
  (e: Event): null => {
    originalHandler(data, index, e);

    return null;
  };

export const adaptEventsOfChild = (
  props: RecordString<any>,
  data: any,
  index: number
): RecordString<(e?: Event) => any> | null => {
  if (typeof props !== 'object') {
    return null;
  }

  let out: RecordString<(e: Event) => void> | null = null;

  Object.keys(props).forEach((key: string) => {
    const item = (props as any)[key];

    if (key.startsWith('on') && typeof item === 'function') {
      if (!out) out = {};

      out[key] = getEventHandlerOfChild(item, data, index);
    }
  });

  return out;
};
