import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import IconWithTooltip from '../../../../lowLevel/IconWithTooltip';
import { useFormatMessage } from '../../../../../locale';
import { ParsableDate } from '../../../../../services/Main/types.common';
import useStyles from './MessageInfo.styles';
import getFormatDateTime from './helpers/getFormatDateTime';

interface Props {
  createdAt: ParsableDate;
  isAddedManually?: boolean;
  isPrivate?: boolean;
  reverse?: boolean;
}

function MessageInfo({
  createdAt,
  isAddedManually,
  isPrivate,
  reverse,
}: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const formattedCreatedAt = getFormatDateTime(
    createdAt as string,
    formatMessage
  );

  return (
    <div className={clsx(classes.root, { reverse })}>
      <Typography variant="caption" color="secondary">
        {formattedCreatedAt}
      </Typography>
      <div className={clsx(classes.iconContainer, { reverse })}>
        {isAddedManually && (
          <IconWithTooltip
            definition="mui:ChatBubbleOutline"
            tooltipText={formatMessage('freeComment')}
          />
        )}
        {isPrivate && (
          <IconWithTooltip
            definition="mui:LockOutlined"
            tooltipText={formatMessage('messageForEmployees')}
          />
        )}
      </div>
    </div>
  );
}

export default MessageInfo;
