import { FieldGroup } from 'services/Main/types.Component';
import { Field } from 'services/Main/types.Field';

interface ReturnType {
  fieldsWithDisableClearable: Field[];
  fieldsWithoutDisableClearable: Field[];
}

export const separateFields = (fieldGroup: FieldGroup) => {
  return fieldGroup.fields.reduce<ReturnType>(
    (acc, field) => {
      if ('disableClearable' in field && field.disableClearable) {
        acc.fieldsWithDisableClearable.push(field);
      } else {
        acc.fieldsWithoutDisableClearable.push(field);
      }

      return acc;
    },
    {
      fieldsWithDisableClearable: [],
      fieldsWithoutDisableClearable: [],
    }
  );
};
