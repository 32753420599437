import {
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ArrayField, useFormContext } from 'react-hook-form';
import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  DirtyFormValues,
  SortingObject,
} from '../../../../services/Main/types.Component';
import { moveItemsInArray } from '../helpers/moveItemsInArray';
import useFormErrors from './useFormErrors';
import { getArrayOfFieldNames } from '../helpers/getArrayOfFieldNames';

interface Props {
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
  name: string;
  setCurrentSortColumn: Dispatch<SetStateAction<SortingObject | null>>;
}

const useDnd = ({ fields, name, setCurrentSortColumn }: Props) => {
  const { getValues, setValue, trigger, errors } = useFormContext();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const { clearErrors } = useFormErrors();

  const rowIds = useMemo(
    () => (fields || []).map((f) => f.id as string) || [],
    [fields]
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = rowIds.indexOf(`${active.id}`);
      const newIndex = rowIds.indexOf(`${over.id}`);

      if (oldIndex !== newIndex) {
        setCurrentSortColumn(null);
        const arrayOfValues: DirtyFormValues[] = getValues()[name];

        const newArrayOfValues = moveItemsInArray(
          arrayOfValues,
          oldIndex,
          newIndex
        );

        Promise.resolve(setValue(name, newArrayOfValues)).then(() => {
          if (Object.entries(errors).length > 0) {
            const fieldNames = getArrayOfFieldNames(name, fields);

            clearErrors(...fieldNames);

            trigger(fieldNames).then();
          }
        });
      }
    }
  };

  return {
    sensors,
    rowIds,
    handleDragEnd,
  };
};

export default useDnd;
