import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    extraIconButton: {
      padding: theme.spacing(0.75),
      color: theme.palette.primary.main,
      border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
      '&:hover': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    menuItemRoot: {
      padding: 0,
    },
    menuItemButton: {
      ...theme.typography.body2,
      padding: theme.spacing(0.75, 2),
    },
  })
);
