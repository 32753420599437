import { useFormatMessage } from '../../../locale';

export function useRemainingText(remainingTimeInSeconds?: number) {
  const formatMessage = useFormatMessage();

  if (!remainingTimeInSeconds) return null;

  // меньше минуты 00:00:00 ... 00:00:59
  if (remainingTimeInSeconds < 60) {
    return formatMessage('secondsLeft', { remaining: remainingTimeInSeconds });
  }

  // от минуты до часа 00:01:00 ... 00:59:59
  if (remainingTimeInSeconds >= 60 && remainingTimeInSeconds < 60 * 60) {
    return formatMessage('minutesLeft', {
      remaining: Math.round(remainingTimeInSeconds / 60),
    });
  }

  // от часа до суток 01:00:00 ... 23:59:59
  if (
    remainingTimeInSeconds >= 60 * 60 &&
    remainingTimeInSeconds < 60 * 60 * 24
  ) {
    return formatMessage('hoursLeft', {
      remaining: Math.round(remainingTimeInSeconds / (60 * 60)),
    });
  }

  // больше дня 23:59:59+
  return formatMessage('daysLeft', {
    remaining: Math.round(remainingTimeInSeconds / (60 * 60 * 24)),
  });
}
