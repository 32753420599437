export default class PubSub {
  subscribers: any[] = [];

  subscribe = (callback: any) => {
    this.subscribers.push(callback);

    return () => this.subscribers.filter((s) => s === callback);
  };

  notify = () => {
    this.subscribers.forEach((subscriberCallback) => {
      subscriberCallback(this);
    });
  };
}
