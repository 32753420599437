import React from 'react';
import { Grid } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { TimelineComponentProps } from '../../../../../services/Main/types.Component';
import FilesButton from './FilesButton';
import PrivacyToggleButton from './PrivacyToggleButton';
import CommentField from './CommentField';
import SendCommentButton from './SendCommentButton';
import useStyles from './AddTimelineElementForm.styles';
import { File } from '../../../../../services/Main/types.Field';
import MessageQuote from '../MessageQuote';
import useTimelineContext from '../../hooks/useTimelineContext';
import Icon from '../../../../lowLevel/Icon/Icon';
import mainService from '../../../../../services/Main';
import useEnqueueSnackbar from '../../../../../utils/hooks/useEnqueueSnackbar';

export type AddTimelineElementFormProps = Pick<
  TimelineComponentProps,
  'addCommentUrl'
>;

interface AddTimelineElementFormValues {
  comment: string;
  attachments: File[];
  isPrivate: boolean;
}

function AddTimelineElementForm({
  addCommentUrl,
}: AddTimelineElementFormProps) {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const { quote, setQuote } = useTimelineContext();

  const handleQuoteClear = () => {
    setQuote(null);
  };

  const formMethods = useForm<AddTimelineElementFormValues>({
    defaultValues: {
      comment: '',
      attachments: [],
      isPrivate: false,
    },
  });

  const { reset } = formMethods;

  // TODO: Нейминг полей. Влад
  const handleSubmit = (values: AddTimelineElementFormValues) => {
    return mainService
      .makeActionRequest(addCommentUrl, values)
      .then(({ snackbar }) => {
        if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);

        reset();
        handleQuoteClear();
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          if (error.response?.data?.snackbar) {
            enqueueSnackbar(
              error.response?.data?.snackbar.text,
              error.response?.data?.snackbar.options
            );
          }
        }

        console.log('Ошибка', error);
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid container spacing={1} alignItems="flex-end">
          {quote && (
            <Grid item container alignItems="center" spacing={1}>
              <Grid item xs="auto">
                <Icon
                  className={classes.clearQuoteButton}
                  definition="mui:HighlightOff"
                  onClick={handleQuoteClear}
                />
              </Grid>
              <Grid item xs>
                <MessageQuote {...quote} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md>
            <CommentField name="comment" />
          </Grid>
          <Grid item xs={12} md="auto" className={classes.buttons}>
            <FilesButton name="attachments" />
            <PrivacyToggleButton name="isPrivate" />
            <SendCommentButton />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default AddTimelineElementForm;
