import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    anchorOriginTopRightRectangle: {
      // Уменьшаем размер индикации на 15%, поскольку дефолтное слишком большое.
      transform: 'scale(0.85) translate(50%, -50%)',
    },
  })
);
