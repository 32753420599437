import { createStyles, makeStyles } from '@material-ui/core';

export const MIN_TEXT_WIDGET_WIDTH = 270;

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(${MIN_TEXT_WIDGET_WIDTH}px, 1fr))`,
      gap: '8px',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },
  })
);
