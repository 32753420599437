import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import get from 'lodash.get';
import { ChartsTooltipData } from './types';
import { ChartDatumValue } from '../../../services/Main/types.Component';

function transformChartPayloadToTooltipData(
  data: Payload<number, string>[]
): ChartsTooltipData {
  return data.reduce<ChartsTooltipData>(
    (acc, item) => {
      // Получаем ключ бара/линии из dataKey
      // 'bar.value' => 'bar'
      const key = (item?.dataKey as string).split('.')[0];

      const payloadItem: ChartDatumValue = get(item.payload, key);

      if (item.payload.totalPrintValue) {
        acc.total = item.payload.totalPrintValue;
      } else {
        acc.total += item?.value || 0;
      }

      acc.payload.push({
        name: item.name || '',
        color: item?.color,
        printValue: payloadItem?.printValue || `${item.value}`,
        href: payloadItem?.href,
      });

      return acc;
    },
    {
      payload: [],
      total: 0,
      label: data[0]?.payload?.description || data[0]?.payload?.name,
    }
  );
}

export default transformChartPayloadToTooltipData;
